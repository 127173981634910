import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getAllServicios, getServicioById, createServicio, deleteServicio, updateServicio } from '../api';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { FaTrash, FaPlus, FaHome } from 'react-icons/fa';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #1c1c1c;
  color: #ffffff;
  min-height: 100vh;
`;

const Header = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 780px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  margin-bottom: 20px;
  width: 13%;
`;

const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  margin-bottom: 20px;
  width: 100%;
`;

const FileInput = styled.input`
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  width: 120px;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #ffffff;
`;

const CheckBox = styled.input`
  margin-right: 10px;
`;

const Selector = styled.select`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
`;

const InputsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 780px;
  margin-bottom: 30px;
`;

const InputLong = styled.input`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  margin-bottom: 20px;
  width: 100%;
`;

const ButtonDelete = styled.button`
  background-color: #ff0000;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #cc0000;
  }
`;

const ModalHeader = styled.h2`
  margin-bottom: 20px;
`;

const ModalText = styled.p`
  margin-bottom: 20px;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const ModalButton = styled.button`
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const CancelButton = styled(ModalButton)`
  background-color: #ff0000;

  &:hover {
    background-color: #cc0000;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;  // Ajusta este margen según sea necesario
`;

const CreateButton = styled.button`
  background-color: #28a745;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  width: 150px; // Ajusta el ancho según sea necesario
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #218838;
  }
`;

const HomeButton = styled.button`
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  width: 150px; // Ajusta el ancho según sea necesario
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 0; 
    margin-bottom: 20px;
  }
`;

const CrearServicio = () => {
  const [inactivo, setInactivo] = useState(false);
  const [servicio, setServicio] = useState([]);
  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [ruta, setRuta] = useState('');
  const [categoria, setCategoria] = useState('');
  const [imagen, setImagen] = useState(null);
  const [imagenCargada, setImagenCargada] = useState(false);
  const [selectedPlanVenta, setSelectedPlanVenta] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllServicios();
        setServicio(response.data);
      } catch (error) {
        console.error('Error al recuperar datos:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedPlanVenta) {
      const fetchData = async () => {
        try {
          const response = await getServicioById(selectedPlanVenta._id);
          setNombre(response.data.nombre);
          setDescripcion(response.data.descripcion);
          setRuta(response.data.ruta);
          setCategoria(response.data.categoria);
          setInactivo(response.data.inactivo);
        } catch (error) {
          console.error('Error al recuperar datos:', error);
        }
      };
      fetchData();
    }
  }, [selectedPlanVenta]);

  const handleSelectChange = (e) => {
    const planid = e.target.value;
    const plan = servicio.find((inst) => inst._id === planid);
    setSelectedPlanVenta(plan);
  };

  const handleCreateNew = async () => {
    try {
      const responseNew = await createServicio();
      setServicio(prevServicios => [...prevServicios, responseNew.data]);
      alert('Nuevo Servicio creado exitosamente, con nombre Servicio Nuevo');
      window.location.reload(); // Recargar la página
    } catch (error) {
      alert('Error al crear el servicio, intentalo de nuevo');
      console.error('Error al crear nueva base:', error);
    }
  };

  const handleEditSelected = () => {
    if (selectedPlanVenta) {
      setServicio(selectedPlanVenta);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('nombre', nombre);
    formData.append('descripcion', descripcion);
    formData.append('ruta', ruta);
    formData.append('categoria', categoria);
    formData.append('inactivo', inactivo);

    if (imagen) formData.append('imagen', imagen);

    try {
      await updateServicio(selectedPlanVenta._id, formData);
      alert('Servicio actualizado exitosamente');
      window.location.reload(); // Recargar la página
    } catch (error) {
      alert('Error al actualizar el servicio. Inténtalo de nuevo');
      console.error('Error al actualizar el servicio:', error);
    }
  };

  const handleImagenChange = (e) => {
    setImagen(e.target.files[0]);
    setImagenCargada(true);
  };

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      await deleteServicio(selectedPlanVenta._id);
      alert('Servicio eliminado exitosamente');
      setIsDeleteModalOpen(false);
      window.location.reload(); // Recargar la página
    } catch (error) {
      alert('Error al eliminar el servicio. Inténtalo de nuevo');
      console.error('Error al eliminar servicio:', error);
    }
  };

  return (
    <PageContainer>
      <Header>Seleccionar o Crear Servicio</Header>
      <Selector onChange={handleSelectChange} value={selectedPlanVenta ? selectedPlanVenta._id : ''}>
        <option value="">Seleccione un Servicio</option>
        {Array.isArray(servicio) && servicio.length > 0 && servicio.map((plannVenta) => (
          <option key={plannVenta._id} value={plannVenta._id}>
            {plannVenta.nombre}
          </option>
        ))}
      </Selector>
      <ButtonRow>
        <CreateButton onClick={handleCreateNew}>
          <FaPlus style={{ marginRight: '8px' }} />
          Nuevo Servicio
        </CreateButton>
        <ButtonDelete onClick={openDeleteModal}>
          <FaTrash style={{ marginRight: '10px' }} />
          Eliminar
        </ButtonDelete>
        <HomeButton onClick={() => navigate('/admin-home')}>
          <FaHome style={{ marginRight: '8px' }} />
          Inicio
        </HomeButton>
      </ButtonRow>
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        contentLabel="Confirmación de Eliminación"
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          },
          content: {
            padding: '20px',
            backgroundColor: '#2c2c2c',
            borderRadius: '10px',
            width: '400px',
            maxWidth: '90%',
            color: '#ffffff',
            textAlign: 'center',
            margin: 'auto',
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto'
          }
        }}
      >
        <ModalHeader>Confirmación de Eliminación</ModalHeader>
        <ModalText>¿Estás seguro de que deseas eliminar el servicio: {selectedPlanVenta && selectedPlanVenta.nombre}?</ModalText>
        <ModalButtonContainer>
          <ModalButton onClick={handleDelete}>Sí, eliminar</ModalButton>
          <CancelButton onClick={closeDeleteModal}>Cancelar</CancelButton>
        </ModalButtonContainer>
      </Modal>
      <CheckBoxContainer>
        <CheckBox
          type="checkbox"
          checked={inactivo}
          onChange={(e) => setInactivo(e.target.checked)}
        />{' '}
        Inactivo (se muestra en gris)
      </CheckBoxContainer>
      <Header>Editar Servicios</Header>
      <FormContainer onSubmit={handleSubmit}>
        <InputLong
          type="text"
          value={nombre}
          onChange={(e) => setNombre(e.target.value)}
          placeholder="Nombre"
          title="Nombre"
          required
        />
        <TextArea
          value={descripcion}
          onChange={(e) => setDescripcion(e.target.value)}
          placeholder="Descripción"
          title="Descripcion"
          required
        />
        <InputLong
          type="text"
          value={ruta}
          onChange={(e) => setRuta(e.target.value)}
          placeholder="Ruta de enlace al tocar"
          title="Ruta de enlace al tocar"
        />
        <InputLong
          type="text"
          value={categoria}
          onChange={(e) => setCategoria(e.target.value)}
          placeholder="Categoría"
          title="Categoría"
        />
        <FileInput
          type="file"
          onChange={handleImagenChange}
        />
        {imagenCargada && <p>Imagen cargada: {imagen.name}</p>}
        <ButtonContainer>
          <Button type="submit">Guardar</Button>
        </ButtonContainer>
      </FormContainer>
      <ImageContainer>
        {selectedPlanVenta && selectedPlanVenta.imagen && (
          <img src={`/imServ/${selectedPlanVenta.imagen}`} alt="Plan" style={{ maxHeight: '800px', maxWidth: '100%' }} />
        )}
      </ImageContainer>
    </PageContainer>
  );
};

export default CrearServicio;
