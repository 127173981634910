import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import styled from 'styled-components';
import Modal from '../components/Modal';
import { Link } from 'react-router-dom';

const VerifyEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1c1c1c;
  color: #ffffff;
  overflow-x: hidden; /* Añadido para eliminar la franja blanca */
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centrar los elementos */
  width: 100%;
`;

const Input = styled.input`
  background: #444;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  width: 70%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    background: #555;
  }
`;

const FormText = styled.h2`
font-family: Arial, sans-serif;
  font-size: 21px;
  right: 20px;
  margin-bottom: 20px;
  margin-top: -10px;
  text-align: center; /* Centrar el texto */
  word-wrap: break-word; /* Permitir que el texto salte de línea */
  white-space: normal; /* Asegurarse de que el texto se ajuste normalmente */
  max-width: 100%; /* Permitir que el texto ocupe hasta el ancho completo del contenedor */
`;

const Container = styled.div`
  display: flex;
  gap: 10px; /* Espacio entre los selectores */
  margin: 10px 0;
  align-items: center;
  justify-content: center; /* Centrar el selector de fecha */
  width: 70%; /* Mismo ancho que los inputs */
`;

const Select = styled.select`
  background: #444;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  box-sizing: border-box;
  width: auto; /* Ajustar el ancho automáticamente */
  flex: 1; /* Permitir que los selectores se distribuyan uniformemente */
  text-align-last: center; /* Centrar el texto dentro de los selectores */

  &:focus {
    outline: none;
    background: #555;
  }
`;

const Button = styled.button`
  background: #007bff;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  width: 40%;
  margin-top: 10px;

  &:hover {
    background: #0056b3;
  }
`;


const MainText = styled.h1`
  font-family: Arial, sans-serif;
  font-size: 40px;
  right: 30px;
  margin-bottom: 40px;
  margin-top: -10px; /* Subir el texto principal */
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap; /* Añadido para mejorar la responsividad */
`;

const ContentSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const Header = styled.header`
  width: 100%;
  padding: 10px 20px;
  background-color: #1c1c1c;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  height: 40px;
`;

const Title = styled.h1`
  font-family: Arial, sans-serif;
  font-size: 24px;
  color: #ffffff;
  margin-left: 10px;
`;

const LogoSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const LargeLogo = styled.img`
  max-width: 70%;
  height: auto;
`;


const Footer = styled.footer`
  background-color: #1c1c1c;
  padding: 20px;
  text-align: center;
  color: #ffffff;
`;

const SocialIcons = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const SocialIcon = styled.a`
  color: #ffffff;
  font-size: 24px;
  text-decoration: none;
  &:hover {
    color: #007bff;
  }
`;



const LinkText = styled(Link)`
  color: #007bff;
  text-decoration: none;
`;

const VerifyEmailPage = () => {
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    birthDate: { day: '', month: '', year: '' },
    identificationNumber: '',
    cellphone: ''
  });
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');

    // Verificación inicial del token
    const verifyEmailToken = async () => {
      try {
        const res = await axios.get(`/api/auth/verify-email?token=${token}`);
        setMessage(res.data.message);
      } catch (err) {
        setMessage(err.response.data.message);
      }
    };

    verifyEmailToken();
  }, [location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('birthDate')) {
      const [field, subfield] = name.split('.');
      setFormData({
        ...formData,
        [field]: {
          ...formData[field],
          [subfield]: value
        }
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = new URLSearchParams(location.search).get('token');
    //const birthDate = `${formData.birthDate.day}/${formData.birthDate.month}/${formData.birthDate.year}`;
    const { day, month, year } = formData.birthDate;
    const paddedDay = day.toString().padStart(2, '0');
    const paddedMonth = month.toString().padStart(2, '0');
    const birthDate = `${paddedDay}/${paddedMonth}/${year}`;

    try {
      const res = await axios.post(`/api/auth/verify-email?token=${token}`, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        birthDate,
        identificationNumber: formData.identificationNumber,
        cellphone: formData.cellphone
      });
      setMessage(res.data.message);
      setShowModal(true);
    } catch (err) {
      setMessage(err.response.data.message);
      setShowModal(true);
    }
  };

  const closeModal = () => { //es para el modal de notificaciones del usuario
    setShowModal(false);
    setRedirect(true);
  };

  useEffect(() => {
    if (redirect) {
      window.location.href = "/";
    }
  }, [redirect]);

  return (
    <>
    <VerifyEmailContainer>
      <Header>
        <LogoContainer>
          <Logo src="/images/logo.svg" alt="Logo" />
          <Title>estudiochat</Title>
        </LogoContainer>
      </Header>
      <MainContent>
        <LogoSection>
          <LargeLogo src="/images/logo.svg" alt="Logo" />
        </LogoSection>
        <ContentSection>
        <MainText>Ya falta poco...</MainText>
          <Form onSubmit={handleSubmit}>
          <FormText>Ingresa los datos para confirmar tu cuenta</FormText>
            <Input
              type="text"
              name="firstName"
              placeholder="Nombre de pila"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            <Input
              type="text"
              name="lastName"
              placeholder="Apellido"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            <Container>
              <Select
                name="birthDate.day"
                value={formData.birthDate.day}
                onChange={handleChange}
                required
              >
                <option value="">Día</option>
                {[...Array(31)].map((_, i) => (
                  <option key={i + 1} value={i + 1}>{i + 1}</option>
                ))}
              </Select>
              <Select
                name="birthDate.month"
                value={formData.birthDate.month}
                onChange={handleChange}
                required
              >
                <option value="">Mes</option>
                {[...Array(12)].map((_, i) => (
                  <option key={i + 1} value={i + 1}>{i + 1}</option>
                ))}
              </Select>
              <Select
                name="birthDate.year"
                value={formData.birthDate.year}
                onChange={handleChange}
                required
              >
                <option value="">Año</option>
                {Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i).map(year => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </Select>
            </Container>
            <Input
              type="text"
              name="identificationNumber"
              placeholder="Cédula de identidad"
              value={formData.identificationNumber}
              onChange={handleChange}
              required
              pattern="\d{8}"
              title="La cédula debe tener 8 dígitos."
            />
            <Input
              type="text"
              name="cellphone"
              placeholder="Celular"
              value={formData.cellphone}
              onChange={handleChange}
              required
            />
            <Button type="submit">Verificar y Actualizar</Button>
          </Form>
        </ContentSection>
      </MainContent>
      <Footer>
        <p>&copy; 2024 Todos los derechos reservados a <LinkText to="https://www.labordt.com">Labor D&T</LinkText></p>
        <SocialIcons>
          <SocialIcon href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></SocialIcon>
          <SocialIcon href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></SocialIcon>
          <SocialIcon href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></SocialIcon>
        </SocialIcons>
      </Footer>
    </VerifyEmailContainer>
    {showModal && <Modal message={message} onClose={closeModal} />}
  </>
  );
};

export default VerifyEmailPage;
