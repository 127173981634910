import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getProfile, getInstitutosByUser, UserById, cursosPorInstituto, CoursesByProfeAndInstitutoId, removeProfeFromCourse, addProfeToCourse } from '../api';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { FaTrash, FaHome } from 'react-icons/fa';

// Estilos y componentes...

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #1c1c1c;
  color: #ffffff;
  min-height: 100vh;
`;

const Header = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  width: 120px;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const Selector = styled.select`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
`;

const ButtonDelete = styled.button`
  background-color: #ff0000;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  width: 120px;
  justify-content: center;

  &:hover {
    background-color: #cc0000;
  }
`;

const ModalHeader = styled.h2`
  margin-bottom: 20px;
`;

const ModalText = styled.p`
  margin-bottom: 20px;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const ModalButton = styled.button`
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const CancelButton = styled(ModalButton)`
  background-color: #ff0000;

  &:hover {
    background-color: #cc0000;
  }
`;


const HomeButton = styled.button`
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const InstituteSelector = styled.select`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  margin-bottom: 20px;
  width: 30%;
`;

const UserList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const UserItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
  background-color: #333;
  margin-bottom: 10px;
  border-radius: 5px;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    align-items: center;
  }
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 200px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const UserDetail = styled.div`
  margin-bottom: 5px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const ProfeAdmin = () => {
  const [profile, setProfile] = useState({});
  const [selectedInstituto, setSelectedInstituto] = useState(null);
  const [institutos, setInstitutos] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [selectedCurso, setSelectedCurso] = useState({});
  const [selectedPlanVenta, setSelectedPlanVenta] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [administradores, setAdministradores] = useState([]);
  const [solicitudprofe, setSolicitudprofe] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userCourses, setUserCourses] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getProfile();
        setProfile(response.data);
        const userId = response.data._id;
        const institutosResponse = await getInstitutosByUser(userId);
        setInstitutos(institutosResponse.data);

        if (institutosResponse.data.length === 1) {
          const instituto = institutosResponse.data[0];
          setSelectedInstituto(instituto);
        }
      } catch (error) {
        console.log('Error al obtener el perfil:', error);
        navigate('/');
      }
    };

    fetchProfile();
  }, [navigate]);

  const handleSelectChange = (e) => {
    const institutoId = e.target.value;
    const instituto = institutos.find((inst) => inst._id === institutoId);
    setSelectedInstituto(instituto);
    setSelectedUser(null); // Reset selected user when institute changes
    setSelectedCurso({});
    setSelectedPlanVenta({});
    setUserCourses({});
    setAdministradores([]);
    setSolicitudprofe([]);
  };

  useEffect(() => {
    const fetchAdministradores = async () => {
      if (selectedInstituto && Array.isArray(selectedInstituto.administradores) && selectedInstituto.administradores.length > 0) {
        try {
          const admins = await Promise.all(selectedInstituto.administradores.map(async (id) => {
            if (id) {
              const response = await UserById(id);
              return response.data;
            } else {
              console.warn('Admin ID is undefined or null');
              return null;
            }
          }));
          setAdministradores(admins.filter(admin => admin !== null));  // Filtrar nulos
        } catch (error) {
          console.error('Error al recuperar los administradores:', error);
        }
      }
    };

    fetchAdministradores();
  }, [selectedInstituto]);

  useEffect(() => {
    const fetchSolicitudprofe = async () => {
      if (selectedInstituto && Array.isArray(selectedInstituto.solicitudprofe) && selectedInstituto.solicitudprofe.length > 0) {
        try {
          const profes = await Promise.all(selectedInstituto.solicitudprofe.map(async (id) => {
            if (id) {
              const response = await UserById(id);
              return response.data;
            } else {
              console.warn('Profe ID is undefined or null');
              return null;
            }
          }));
          setSolicitudprofe(profes.filter(profe => profe !== null));  // Filtrar nulos
        } catch (error) {
          console.error('Error al recuperar las solicitudes de los profesores:', error);
        }
      }
    };

    fetchSolicitudprofe();
  }, [selectedInstituto]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedInstituto) {
        try {
          const response = await cursosPorInstituto(selectedInstituto._id);
          setCursos(response.data);
        } catch (error) {
          console.error('Error al recuperar datos:', error);
        }
      }
    };

    fetchData();
  }, [selectedInstituto]);

  const fetchCursosByUser = async (userId) => {
    if (selectedInstituto) {
      try {
        const response = await CoursesByProfeAndInstitutoId(userId, selectedInstituto._id);
        setUserCourses((prev) => ({
          ...prev,
          [userId]: response.data
        }));
      } catch (error) {
        console.error('Error al recuperar datos:', error);
      }
    }
  };

  const handleSelectCurso = (e, userId) => {
    const cursoId = e.target.value;
    setSelectedCurso((prev) => ({
      ...prev,
      [userId]: cursoId
    }));
  };

  const handleSelectEliminar = (e, userId) => {
    const planId = e.target.value;
    const plan = userCourses[userId]?.find((inst) => inst._id === planId);
    setSelectedPlanVenta((prev) => ({
      ...prev,
      [userId]: plan
    }));
  };

  const openDeleteModal = (userId) => {
    setSelectedUser(userId);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedUser(null);
  };

  const handleDelete = async () => {
    if (selectedUser && selectedPlanVenta[selectedUser]) {
      try {
        await removeProfeFromCourse(selectedPlanVenta[selectedUser]._id, selectedUser);
        alert('Servicio eliminado exitosamente');
        setIsDeleteModalOpen(false);
        window.location.reload();
      } catch (error) {
        alert('Error al eliminar el servicio. Inténtalo de nuevo');
        console.error('Error al eliminar servicio:', error);
      }
    }
  };

  const handleAdmSave = async (userId) => {
    if (selectedCurso[userId]) {
      try {
        await addProfeToCourse(selectedCurso[userId], userId);
        alert('Asignación exitosa');
        fetchCursosByUser(userId); // Actualizar los cursos asignados para el usuario
      } catch (error) {
        console.error('Error al asignar curso:', error);
        alert('Error al asignar curso');
      }
    }
  };

  const handleUserClick = (userId) => {
    setSelectedUser(userId);
    fetchCursosByUser(userId); // Cargar los cursos asignados para el usuario seleccionado
  };

  return (
    <PageContainer>
      <Header>Configure asignación de Curso-Profesor</Header>
      {institutos.length > 1 && (
        <InstituteSelector onChange={handleSelectChange}>
          <option value="">Seleccione un instituto</option>
          {institutos.map((instituto) => (
            <option key={instituto._id} value={instituto._id}>
              {instituto.nombre}
            </option>
          ))}
        </InstituteSelector>
      )}
      <HomeButton onClick={() => navigate('/admin-home')}>
        <FaHome /> Inicio
      </HomeButton>
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        contentLabel="Confirmación de Eliminación"
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          },
          content: {
            padding: '20px',
            backgroundColor: '#2c2c2c',
            borderRadius: '10px',
            width: '400px',
            maxWidth: '90%',
            color: '#ffffff',
            textAlign: 'center',
            margin: 'auto',
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto'
          }
        }}
      >
        <ModalHeader>Confirmación de Eliminación</ModalHeader>
        <ModalText>¿Estás seguro de que deseas eliminar la asignación del curso: {selectedPlanVenta[selectedUser]?.courseName}?</ModalText>
        <ModalButtonContainer>
          <ModalButton onClick={handleDelete}>Sí, eliminar</ModalButton>
          <CancelButton onClick={closeDeleteModal}>Cancelar</CancelButton>
        </ModalButtonContainer>
      </Modal>
      <UserList>
        {administradores.length > 0 ? (
          administradores.map((user) => (
            <UserItem key={user._id} onClick={() => handleUserClick(user._id)}>
              <UserInfo>
                <div>
                <UserDetail><strong>Administrador del Insituto</strong></UserDetail>
                  <UserDetail><strong>Nombre:</strong> {user.name}</UserDetail>
                  <UserDetail><strong>Email:</strong> {user.email}</UserDetail>
                  <UserDetail><strong>Rol:</strong> {user.role}</UserDetail>
                </div>
              </UserInfo>
              <div>
                <Selector onChange={(e) => handleSelectCurso(e, user._id)} value={selectedCurso[user._id] || ''}>
                  <option value="">Asigne un Curso</option>
                  {Array.isArray(cursos) && cursos.map((curso) => (
                    <option key={curso._id} value={curso._id}>
                      {curso.courseName}
                    </option>
                  ))}
                </Selector>
                <Button onClick={() => handleAdmSave(user._id)}>Guardar</Button>
              </div>
              <div>
                <Selector onChange={(e) => handleSelectEliminar(e, user._id)} value={selectedPlanVenta[user._id]?._id || ''}>
                  <option value="">Cursos asignados</option>
                  {Array.isArray(userCourses[user._id]) && userCourses[user._id].map((asignado) => (
                    <option key={asignado._id} value={asignado._id}>
                      {asignado.courseName}
                    </option>
                  ))}
                </Selector>
                <ButtonDelete onClick={() => openDeleteModal(user._id)}>
                  <FaTrash /> Eliminar
                </ButtonDelete>
              </div>
            </UserItem>
          ))
        ) : (
          <p>No hay administradores</p>
        )}
      </UserList>
      <UserList>
        {solicitudprofe.length > 0 ? (
          solicitudprofe.map((user) => (
            <UserItem key={user._id} onClick={() => handleUserClick(user._id)}>
              <UserInfo>
                <div>
                <UserDetail>{user.role === 'user' ? 'Solicitud para Profesor' : 'Profesor del Instituto'} </UserDetail>
                  <UserDetail><strong>Nombre:</strong> {user.name}</UserDetail>
                  <UserDetail><strong>Email:</strong> {user.email}</UserDetail>
                  <UserDetail><strong>Rol:</strong> {user.role}</UserDetail>
                </div>
              </UserInfo>
              <div>
                <Selector onChange={(e) => handleSelectCurso(e, user._id)} value={selectedCurso[user._id] || ''}>
                  <option value="">Asigne un Curso</option>
                  {Array.isArray(cursos) && cursos.map((curso) => (
                    <option key={curso._id} value={curso._id}>
                      {curso.courseName}
                    </option>
                  ))}
                </Selector>
                <Button onClick={() => handleAdmSave(user._id)}>Guardar</Button>
              </div>
              <div>
                <Selector onChange={(e) => handleSelectEliminar(e, user._id)} value={selectedPlanVenta[user._id]?._id || ''}>
                  <option value="">Cursos asignados</option>
                  {Array.isArray(userCourses[user._id]) && userCourses[user._id].map((asignado) => (
                    <option key={asignado._id} value={asignado._id}>
                      {asignado.courseName}
                    </option>
                  ))}
                </Selector>
                <ButtonDelete onClick={() => openDeleteModal(user._id)}>
                  <FaTrash /> Eliminar
                </ButtonDelete>
              </div>
            </UserItem>
          ))
        ) : (
          <p>No hay solicitudes de profesores</p>
        )}
      </UserList>
    </PageContainer>
  );
};

export default ProfeAdmin;

