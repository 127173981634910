import React from 'react';

const CalendarWithoutIcal = () => {
  return (
    <div style={containerStyle}>
      <iframe
        src="https://calendar.google.com/calendar/embed?src=maximiliano1993%40gmail.com&ctz=America%2FMontevideo"
        style={iframeStyle}
        frameBorder="0"
        scrolling="no"
        title="Google Calendar"
      ></iframe>
    </div>
  );
};

// Estilos en línea
const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: '#f0f0f0',  // Fondo ligero
  padding: '20px',
};

const iframeStyle = {
  border: '1px solid #ccc',
  borderRadius: '10px',   // Bordes redondeados
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',  // Sombra suave
  width: '80%',           // Responsive
  height: '80vh',         // Ajustar a la altura visible
  maxWidth: '1000px',     // Ancho máximo
  maxHeight: '700px',     // Altura máxima
};

export default CalendarWithoutIcal;

