import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Modal = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const ModalContent = styled.div`
  background-color: #333333;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  text-align: center;
`;

const CloseButton = styled.button`
  background: #007bff;
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
`;

const Timer = styled.div`
  margin-top: 10px;
  font-size: 16px;
  color: #ffcc00;
`;

const CouponModal = ({ isOpen, onClose, onSubmit, message }) => {
  const [code, setCode] = useState('');
  const [timeLeft, setTimeLeft] = useState(120); // 2 minutos

  useEffect(() => {
    if (isOpen && message === 'Código de verificación enviado.') {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            onClose();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isOpen, onClose, message]);

  const handleSubmit = () => {
    onSubmit(code);
    setCode('');
  };

 
  return (
    <Modal isOpen={isOpen}>
      <ModalContent>
        <h2>Verificación de Cupón</h2>
        <p>{message}</p>
        {message === 'Código de verificación enviado.' && (
          <>
            <input
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Código de verificación"
            />
            <Timer>{`Tiempo restante: ${Math.floor(timeLeft / 60)}:${timeLeft % 60 < 10 ? '0' : ''}${timeLeft % 60}`}</Timer>
            <CloseButton onClick={handleSubmit}>Verificar</CloseButton>
          </>
        )}
        <CloseButton onClick={onClose}>Cerrar</CloseButton>
      </ModalContent>
    </Modal>
  );
};

export default CouponModal;


