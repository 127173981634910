/*import React, { useState } from 'react';
import styled from 'styled-components';
import { searchAndRespond } from '../api';

// Estilos
const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0;
  padding: 20px;
`;

const Input = styled.input`
  padding: 10px;
  width: 80%;
  max-width: 400px;
  margin: 20px 0;
  border-radius: 10px;
  border: 1px solid #cccccc;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  background-color: #007bff;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const ResponseContainer = styled.div`
  padding: 20px;
  width: 80%;
  max-width: 600px;
  margin: 20px 0;
  border-radius: 10px;
  border: 1px solid #cccccc;
  background-color: #f9f9f9;
  color: #333333;
`;

// Componente Principal
const BuscarGPT = () => {
  const [query, setQuery] = useState('');
  const [response, setResponse] = useState('');

  // Función para manejar el evento de búsqueda
  const handleSearch = async () => {
    setResponse(''); // Limpiar la respuesta antes de una nueva búsqueda

    try {
      searchAndRespond(query, (data) => {
        setResponse((prevResponse) => prevResponse + data);
      });
    } catch (error) {
      setResponse('Error al realizar la consulta.');
    }
  };

  return (
    <AppContainer>
      <h1>Asistente de Búsqueda</h1>
      <Input
        type="text"
        placeholder="Ingresa tu consulta..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <Button onClick={handleSearch}>Buscar</Button>
      <ResponseContainer>
        {response ? response : 'No hay respuesta aún.'}
      </ResponseContainer>
    </AppContainer>
  );
};

export default BuscarGPT;*/


import React, { useState } from 'react';
import styled from 'styled-components';
import { searchAndRespond } from '../api';

// Estilos
const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0;
  padding: 20px;
`;

const Input = styled.input`
  padding: 10px;
  width: 80%;
  max-width: 400px;
  margin: 20px 0;
  border-radius: 10px;
  border: 1px solid #cccccc;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  background-color: #007bff;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const ResponseContainer = styled.div`
  padding: 20px;
  width: 80%;
  max-width: 600px;
  margin: 20px 0;
  border-radius: 10px;
  border: 1px solid #cccccc;
  background-color: #f9f9f9;
  color: #333333;
`;

// Componente Principal
const BuscarGPT = () => {
  const [query, setQuery] = useState('');
  const [response, setResponse] = useState('');

  const handleSearch = async () => {
    try {
      const data = await searchAndRespond(query);
      setResponse(data.data.response);
    } catch (error) {
      setResponse('Error al realizar la consulta.');
    }
  };

  return (
    <AppContainer>
      <h1>Asistente de Búsqueda</h1>
      <Input
        type="text"
        placeholder="Ingresa tu consulta..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <Button onClick={handleSearch}>Buscar</Button>
      <ResponseContainer>
        {response ? response : 'No hay respuesta aún.'}
      </ResponseContainer>
    </AppContainer>
  );
};

export default BuscarGPT;




