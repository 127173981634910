import React, { useEffect, useState } from 'react';
import { getUsers, updateUser, deleteUser, getInstitutos, assignAdminToInstituto, getInstitutosByUser } from '../api';
import styled from 'styled-components';
import { FaTrashAlt, FaSave, FaHome, FaUniversity } from 'react-icons/fa';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #1c1c1c;
  color: #ffffff;
  min-height: 100vh;
`;

const Header = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const SearchBar = styled.input`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  margin-bottom: 20px;
  width: 100%;
  max-width: 780px;
  margin: 0 auto 20px;
`;

const UserList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const UserItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
  background-color: #333;
  margin-bottom: 10px;
  border-radius: 5px;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    align-items: center;
  }
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 200px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const UserDetail = styled.div`
  margin-bottom: 5px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const EditForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
`;

const Select = styled.select`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
`;

const Button = styled.button`
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 5px;
  width: 120px;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const DeleteButton = styled(Button)`
  background-color: #dc3545;

  &:hover {
    background-color: #c82333;
  }
`;

const CancelButton = styled(Button)`
  background-color: #6c757d;

  &:hover {
    background-color: #5a6268;
  }
`;

const HomeButton = styled(Button)`
  margin: 20px auto;
`;

Modal.setAppElement('#root');

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const ModalHeader = styled.h2`
  margin-bottom: 10px;
`;

const ModalContent = styled.p`
  margin-bottom: 20px;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [institutos, setInstitutos] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    role: 'user',
    instituto: '',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getUsers();
        const usersWithInstitutos = await Promise.all(response.data.map(async (user) => {
          const institutos = await getInstitutosByUser(user._id);
          return { ...user, institutos: institutos.data.length > 0 ? institutos.data : null };
        }));
        setUsers(usersWithInstitutos);
        setFilteredUsers(usersWithInstitutos);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    const fetchInstitutos = async () => {
      try {
        const response = await getInstitutos();
        setInstitutos(response.data);
      } catch (error) {
        console.error('Error fetching institutos:', error);
      }
    };

    fetchUsers();
    fetchInstitutos();
  }, []);

  useEffect(() => {
    setFilteredUsers(
      users.filter((user) =>
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, users]);

  const handleSelectUser = (user) => {
    setSelectedUser(user);
    setFormData({
      name: user.name,
      email: user.email,
      role: user.role,
      instituto: user.institutos && user.institutos.length > 0 ? user.institutos[0]._id : '', // Selecciona el primer instituto si existe
    });
    setIsEditModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      await updateUser(selectedUser._id, formData);
      setSelectedUser(null);
      setFormData({ name: '', email: '', role: 'user', instituto: '' });
      const response = await getUsers();
      const usersWithInstitutos = await Promise.all(response.data.map(async (user) => {
        const institutos = await getInstitutosByUser(user._id);
        return { ...user, institutos: institutos.data.length > 0 ? institutos.data : null };
      }));
      setUsers(usersWithInstitutos);
      setFilteredUsers(usersWithInstitutos);
      setIsEditModalOpen(false);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleAssignInstituto = async () => {
    if (formData.instituto && (formData.role === 'admin' || formData.role === 'supadm')) {
      try {
        await assignAdminToInstituto(formData.instituto, selectedUser._id);
        const response = await getInstitutos();
        setInstitutos(response.data);
        alert('Usuario asignado al instituto exitosamente.');
      } catch (error) {
        console.error('Error assigning user to instituto:', error);
      }
    }
  };

  const openDeleteModal = (user) => {
    setUserToDelete(user);
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsModalOpen(false);
    setUserToDelete(null);
  };

  const handleDelete = async () => {
    if (!userToDelete) return;
    try {
      await deleteUser(userToDelete._id);
      const response = await getUsers();
      const usersWithInstitutos = await Promise.all(response.data.map(async (user) => {
       const institutos = await getInstitutosByUser(user._id);
        return { ...user, institutos: institutos.data.length > 0 ? institutos.data : null };
      }));
      setUsers(usersWithInstitutos);
      setFilteredUsers(usersWithInstitutos);
      if (selectedUser && selectedUser._id === userToDelete._id) {
        setSelectedUser(null);
        setFormData({ name: '', email: '', role: 'user', instituto: '' });
      }
      closeDeleteModal();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedUser(null);
  };

  return (
    <Container>
      <Header>Gestión de Usuarios</Header>
      <HomeButton onClick={() => navigate('/admin-home')}>
        <FaHome /> Inicio
      </HomeButton>
      <SearchBar
        type="text"
        placeholder="Buscar por correo electrónico"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <UserList>
        {filteredUsers.map((user) => (
          <UserItem key={user._id}>
            <UserInfo>
              <UserDetail><strong>Nombre:</strong> {user.name}</UserDetail>
              <UserDetail><strong>Email:</strong> {user.email}</UserDetail>
              <UserDetail><strong>CI:</strong> {user.identificationNumber}</UserDetail>
              {user.institutos && user.institutos.length > 0 && (
                <UserDetail><strong>Instituto:</strong> {user.institutos.map(inst => inst.nombre).join(', ')}</UserDetail>
              )}
            </UserInfo>
            <div>
              <Button onClick={() => handleSelectUser(user)}>Seleccionar</Button>
              <DeleteButton onClick={() => openDeleteModal(user)}>
                <FaTrashAlt /> Eliminar
              </DeleteButton>
            </div>
          </UserItem>
        ))}
      </UserList>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeDeleteModal}
        contentLabel="Confirmar Eliminación"
        style={{
          content: {
            background: '#1c1c1c',
            color: '#ffffff',
            borderRadius: '10px',
            padding: '10px',
            width: '450px',
            maxHeight: '200px',
            margin: '0 auto',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            textAlign: 'center',
            overflow: 'hidden'
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }
        }}
      >
        <ModalContainer>
          <ModalHeader>Confirmar Eliminación</ModalHeader>
          <ModalContent>¿Estás seguro de que deseas eliminar al usuario con correo <strong>{userToDelete && userToDelete.email}</strong>?</ModalContent>
          <ModalActions>
            <DeleteButton onClick={handleDelete}>
              <FaTrashAlt /> Eliminar
            </DeleteButton>
            <CancelButton onClick={closeDeleteModal}>
              Cancelar
            </CancelButton>
          </ModalActions>
        </ModalContainer>
      </Modal>
      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={closeEditModal}
        contentLabel="Editar Usuario"
        style={{
          content: {
            background: '#1c1c1c',
            color: '#ffffff',
            borderRadius: '10px',
            padding: '20px',
            width: '400px',
            maxHeight: '400px',
            margin: '0 auto',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'fixed',
            textAlign: 'center',
            overflow: 'hidden'
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }
        }}
      >
        <ModalContainer>
          <ModalHeader>Editar Usuario</ModalHeader>
          <EditForm onSubmit={handleSave}>
            <Input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Nombre"
            />
            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Correo Electrónico"
            />
            <Select name="role" value={formData.role} onChange={handleChange}>
              <option value="user">Usuario</option>
              <option value="profe">Profesor</option>
              <option value="admin">Administrador</option>
              <option value="supadm">SuperAdministrador</option>
            </Select>
            {(formData.role === 'admin' || formData.role === 'supadm') && (
              <>
                <Select
                  name="instituto"
                  value={formData.instituto}
                  onChange={handleChange}
                >
                  <option value="">Seleccionar Instituto</option>
                  {institutos.map((instituto) => (
                    <option key={instituto._id} value={instituto._id}>
                      {instituto.nombre}
                    </option>
                  ))}
                </Select>
                <Button type="button" onClick={handleAssignInstituto}>
                  <FaUniversity /> Asignar Instituto
                </Button>
              </>
            )}
            <ModalActions>
              <Button type="submit">
                <FaSave /> Guardar
              </Button>
              <CancelButton onClick={closeEditModal}>
                Cancelar
              </CancelButton>
            </ModalActions>
          </EditForm>
        </ModalContainer>
      </Modal>
    </Container>
  );
};

export default UserManagement;
