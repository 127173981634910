import { useNavigate, useParams, Link } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { FaTwitter, FaInstagram, FaLinkedin, FaArrowLeft } from 'react-icons/fa';
import { publicPlanVentaById } from '../api';
import { handleRequestError } from './errorHandler';

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1c1c1c;
  color: #ffffff;
  overflow-x: hidden;
`;

const Header = styled.header`
  width: 100%;
  padding: 10px 20px;
  background-color: #1c1c1c;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  height: 40px;
`;

const Title = styled.h1`
  font-family: Arial, sans-serif;
  font-size: 24px;
  color: #ffffff;
  margin-left: 10px;
  margin-right: 40px;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
`;

const LogoSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ContentSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const MainText = styled.h1`
  font-family: Arial, sans-serif;
  font-size: 40px;
  margin-bottom: 10px; /* Reducimos el margen inferior */
  margin-top: 0; /* Ajustamos el margen superior */
  text-align: center;
`;

const PromotionText = styled.p`
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: #ff6b6b; /* Color rojo para la promoción */
`;

const DescriptionText = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
  text-align: justify;
  color: #ffffff;
  line-height: 1.5; /* Aumentamos el interlineado */
  margin-top: 20px; /* Añadimos margen superior */
  p {
    margin-bottom: 15px; /* Espaciado entre párrafos */
  }
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const TermsText = styled.p`
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
  max-width: 50%;
  line-height: 1.2;
`;

const LinkText = styled(Link)`
  color: #007bff;
  text-decoration: none;
`;

const Footer = styled.footer`
  background-color: #1c1c1c;
  padding: 20px;
  text-align: center;
  color: #ffffff;
`;

const SocialIcons = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const SocialIcon = styled.a`
  color: #ffffff;
  font-size: 24px;
  text-decoration: none;
  &:hover {
    color: #007bff;
  }
`;

const DescripcionComercial = () => {
  const timeoutRef = useRef(null);
  const [plan, setPlan] = useState({});
  const navigate = useNavigate();
  const { id } = useParams(); // Obtén el ID desde los parámetros de la URL

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await publicPlanVentaById(id);
        setPlan(result.data);
      } catch (error) {
        console.error('Error al recuperar datos:', error);
        handleRequestError(error, navigate);
      }
    };
    if (id) {
      fetchData();
    }
  }, [id, navigate]);

  return (
    <HomeContainer>
      <Header>
        <BackButton onClick={() => navigate(-1)}>
          <FaArrowLeft />
        </BackButton>
        <LogoContainer>
          <Logo src="/images/logo.svg" alt="Logo" />
          <Title>estudiochat</Title>
        </LogoContainer>
      </Header>
      <MainContent>
        <LogoSection>
          {plan.imagen && (
            <img src={`/arPV/${plan.imagen}`} alt="Plan" style={{ maxHeight: '400px', maxWidth: '100%' }} />
          )}
        </LogoSection>
        <ContentSection>
          {plan.nombre && <MainText>{plan.nombre}</MainText>}
          {plan.promocion && <PromotionText>{plan.promocion}</PromotionText>}
          {plan.descripcion && (
            <DescriptionText>
              {plan.descripcion.split('\n').map((para, index) => (
                <p key={index}>{para}</p>
              ))}
            </DescriptionText>
          )}
          <ButtonContainer>
            {/* Aquí puedes añadir botones si es necesario */}
          </ButtonContainer>
        </ContentSection>
      </MainContent>
      <Footer>
        <p>&copy; 2024 Todos los derechos reservados a <LinkText to="https://www.labordt.com">Labor D&T</LinkText></p>
        <SocialIcons>
          <SocialIcon href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></SocialIcon>
          <SocialIcon href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></SocialIcon>
          <SocialIcon href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></SocialIcon>
        </SocialIcons>
      </Footer>
    </HomeContainer>
  );
};

export default DescripcionComercial;

