import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getProfile, getInstitutosByUser, cursoPorId, cursosPorInstituto, createNewCourse, deleteCursoPorId, updateCourse } from '../api';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { FaTrash, FaPlus, FaHome } from 'react-icons/fa';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #1c1c1c;
  color: #ffffff;
  min-height: 100vh;
`;

const Header = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 780px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  width: 120px;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const CheckBox = styled.input`
  margin-right: 10px;
`;

const Selector = styled.select`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
`;


const ButtonDelete = styled.button`
  background-color: #ff0000;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #cc0000;
  }
`;

const ModalHeader = styled.h2`
  margin-bottom: 20px;
`;

const ModalText = styled.p`
  margin-bottom: 20px;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const ModalButton = styled.button`
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const CancelButton = styled(ModalButton)`
  background-color: #ff0000;

  &:hover {
    background-color: #cc0000;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;  // Ajusta este margen según sea necesario
`;

const CreateButton = styled.button`
  background-color: #28a745;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  width: 150px; // Ajusta el ancho según sea necesario
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #218838;
  }
`;

const HomeButton = styled.button`
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  width: 150px; // Ajusta el ancho según sea necesario
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #444;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
`;

const ProgressBar = styled.div`
  width: ${props => props.progress}%;
  background-color: #007bff;
  height: 20px;
  transition: width 0.5s;
`;

const InputField = styled.input`
  background: #444;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  width: calc(100% - 20px);
  margin-right: 10px;

  &:focus {
    outline: none;
    background: #555;
  }
`;

const SmallInputField = styled.input`
  background: #444;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  width: calc(90% - 10px);
  margin-right: 10px;

  &:focus {
    outline: none;
    background: #555;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const TextAreaField = styled.textarea`
  background: #444;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  width: calc(100% - 20px);
  height: 200px;
  resize: none;
  box-sizing: border-box;
  overflow: auto;

  &:focus {
    outline: none;
    background: #555;
  }
`;

const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const CheckBoxLabel = styled.label`
  color: #fff;
  width: calc(25% - 10px);
  margin-bottom: 10px;
`;

const CheckBoxInput = styled.input`
  margin-right: 5px;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #ffffff;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(25% - 10px);
`;

const InputTitle = styled.h4`
  margin-bottom: 5px;
  text-align: center;
`;



const ImageVideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;


  img {
    max-width: 80%;
    height: auto;
    border-radius: 10px;
  }
`;

const VideoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;

  video {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
`;

const EditorCursos = () => {
    const [inactivo, setInactivo] = useState(false);
    const [servicio, setServicio] = useState([]);
    const [selectedPlanVenta, setSelectedPlanVenta] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [institutos, setInstitutos] = useState([]);
    const [selectedInstituto, setSelectedInstituto] = useState(null);
    const [formData, setFormData] = useState({
      courseName: '',
      introduction: '',
      information: '',
      prices: {
        mensual: 0,
        bimensual: 0,
        cuatrimestral: 0,
      },
      promotion: '',
      checkpoints: {
        chat: false,
        video: false,
        quiz: false,
        pdf: false,
        links: false,
        mercado: false,
        cupon: false,
        autoRenewal: false,
        mostrar: false,
        precio: false,
      },
      creatorEmail: '',
      institutoId: '',
    });
    const [imagen, setImagen] = useState(null);
    const [video, setVideo] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
  
    const [courseName, setCourseName] = useState('');
    const [introduction, setIntroduction] = useState('');
    const [information, setInformation] = useState('');
    const [mensual, setMensual] = useState(0);
    const [bimensual, setBimensual] = useState(0);
    const [cuatrimestral, setCuatrimestral] = useState(0);
    const [descuento, setDescuento] = useState(0);
    const [promotion, setPromotion] = useState('');
    const [planprograma, setPlanprograma] = useState('');
  
    // Hooks useState para cada campo booleano en checkpoints
    const [chat, setChat] = useState(false);
    const [videoCheckpoint, setVideoCheckpoint] = useState(false);
    const [quiz, setQuiz] = useState(false);
    const [pdf, setPdf] = useState(false);
    const [links, setLinks] = useState(false);
    const [mercado, setMercado] = useState(false);
    const [cupon, setCupon] = useState(false);
    const [autoRenewal, setAutoRenewal] = useState(false);
    const [mostrar, setMostrar] = useState(false);
    const [precio, setPrecio] = useState(false);
    
    const { id } = useParams();
    const navigate = useNavigate();
  
    useEffect(() => {
      const fetchProfile = async () => {
        try {
          const response = await getProfile();
  
          const userId = response.data._id;
          const institutosResponse = await getInstitutosByUser(userId);
          setInstitutos(institutosResponse.data);
  
          if (institutosResponse.data.length === 1) {
            const instituto = institutosResponse.data[0];
            setSelectedInstituto(instituto);
            setFormData((prevData) => ({
              ...prevData,
              institutoId: instituto._id,
            }));
          }
  
          setFormData((prevData) => ({
            ...prevData,
            creatorEmail: response.data.email,
          }));
        } catch (error) {
          console.error('Error al obtener el perfil:', error);
        }
      };
  
      fetchProfile();
    }, []);
  
    useEffect(() => {
      const fetchData = async () => {
        if (selectedInstituto) {
          try {
            const response = await cursosPorInstituto(selectedInstituto._id);
            setServicio(response.data);
          } catch (error) {
            console.error('Error al recuperar datos:', error);
          }
        }
      };
  
      fetchData();
    }, [selectedInstituto]);
  
    useEffect(() => {
      if (selectedPlanVenta) {
        const fetchData = async () => {
          try {
            const response = await cursoPorId(selectedPlanVenta._id);
            setCourseName(response.data.courseName);
            setIntroduction(response.data.introduction);
            setInformation(response.data.information);
            setMensual(response.data.prices.mensual);
            setBimensual(response.data.prices.bimensual);
            setCuatrimestral(response.data.prices.cuatrimestral);
            setDescuento(response.data.descuento);
            setPromotion(response.data.promotion);
            setPlanprograma(response.data.planprograma);
  
            // Campos booleanos en checkpoints
            setChat(response.data.checkpoints.chat);
            setVideoCheckpoint(response.data.checkpoints.video);
            setQuiz(response.data.checkpoints.quiz);
            setPdf(response.data.checkpoints.pdf);
            setLinks(response.data.checkpoints.links);
            setMercado(response.data.checkpoints.mercado);
            setCupon(response.data.checkpoints.cupon);
            setAutoRenewal(response.data.checkpoints.autoRenewal);
            setMostrar(response.data.checkpoints.mostrar);
            setPrecio(response.data.checkpoints.precio);
          } catch (error) {
            console.error('Error al recuperar datos:', error);
          }
        };
  
        fetchData();
      }
    }, [selectedPlanVenta]);
  
    const handleSelectChange = (e) => {
      const planid = e.target.value;
      const plan = servicio.find((inst) => inst._id === planid);
      setSelectedPlanVenta(plan);
    };
  
    const handleCreateNew = async () => {
        try {
          const dataToSend = {
            ...formData,
            institutoId: selectedInstituto ? selectedInstituto._id : formData.institutoId,
            creatorEmail: formData.creatorEmail,
          };
      
          console.log('Datos a enviar:', dataToSend);
      
          const responseNew = await createNewCourse(dataToSend);
          setServicio(prevServicios => [...prevServicios, responseNew.data]);
          alert('Nuevo Curso creado exitosamente, con nombre CursoNuevo');
          window.location.reload(); // Recargar la página
        } catch (error) {
          if (error.response) {
            console.error('Error al crear nueva base:', error.response.data);
          } else {
            console.error('Error al crear nueva base:', error.message);
          }
          alert('Error al crear el curso, intentalo de nuevo');
        }
      };
      
  
    const handleSelectChangee = (e) => {
      const institutoId = e.target.value;
      const instituto = institutos.find((inst) => inst._id === institutoId);
      setSelectedInstituto(instituto);
      setFormData((prevData) => ({
        ...prevData,
        institutoId: institutoId,
      }));
    };
  
    useEffect(() => {
      if (uploading) {
        const totalSize = (imagen ? imagen.size : 0) + (video ? video.size : 0);
        let uploaded = 0;
  
        const interval = setInterval(() => {
          uploaded += 2 * 1024 * 1024; // 2MB por segundo
          const progressPercentage = Math.min((uploaded / totalSize) * 100, 99);
          setUploadProgress(Math.floor(progressPercentage));
  
          if (progressPercentage >= 99) {
            clearInterval(interval);
          }
        }, 1000);
  
        return () => clearInterval(interval);
      }
    }, [uploading, imagen, video]);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const formDataToSend = new FormData();
      formDataToSend.append('courseName', courseName);
      formDataToSend.append('introduction', introduction);
      formDataToSend.append('information', information);
      formDataToSend.append('prices[mensual]', mensual);
      formDataToSend.append('prices[bimensual]', bimensual);
      formDataToSend.append('prices[cuatrimestral]', cuatrimestral);
      formDataToSend.append('descuento', descuento);
      formDataToSend.append('promotion', promotion);
      formDataToSend.append('planprograma', planprograma);
      formDataToSend.append('creatorEmail', formData.creatorEmail);
      formDataToSend.append('checkpoints[chat]', chat);
      formDataToSend.append('checkpoints[video]', videoCheckpoint);
      formDataToSend.append('checkpoints[quiz]', quiz);
      formDataToSend.append('checkpoints[pdf]', pdf);
      formDataToSend.append('checkpoints[links]', links);
      formDataToSend.append('checkpoints[mercado]', mercado);
      formDataToSend.append('checkpoints[cupon]', cupon);
      formDataToSend.append('checkpoints[autoRenewal]', autoRenewal);
      formDataToSend.append('checkpoints[mostrar]', mostrar);
      formDataToSend.append('checkpoints[precio]', precio);
  
      if (imagen) formDataToSend.append('imagen', imagen);
      if (video) formDataToSend.append('video', video);
  
      setUploading(true);
  
      try {
        await updateCourse(selectedPlanVenta._id, formDataToSend);
        setUploadProgress(100);
        alert('Curso actualizado exitosamente');
        window.location.reload(); // Recargar la página
      } catch (error) {
        alert('Error al actualizar el curso. Inténtalo de nuevo mas tarde');
        console.error('Error al actualizar el curso:', error);
      }
    };
  
    const openDeleteModal = () => {
      setIsDeleteModalOpen(true);
    };
  
    const closeDeleteModal = () => {
      setIsDeleteModalOpen(false);
    };
  
    const handleDelete = async () => {
      try {
        await deleteCursoPorId(selectedPlanVenta._id);
        alert('Curso eliminado exitosamente');
        setIsDeleteModalOpen(false);
        window.location.reload(); // Recargar la página
      } catch (error) {
        alert('Error al eliminar el curso. Inténtalo de nuevo mas tarde');
        console.error('Error al eliminar curso:', error);
      }
    };
  
    const handleImagenChange = (e) => {
      setImagen(e.target.files[0]);
    };
  
    const handleVideoChange = (e) => {
      setVideo(e.target.files[0]);
    };


      // Estado para las flags condicionales cuando tengamos asignado el plan adquirido, aca se pueden deshabilitar 
      //servicios que no esten autorizados segun el plan contratado
  const [flags, setFlags] = useState({
    chat: true,
    video: true,
    quiz: true,
    pdf: true,
    links: true,
    mercado: true,
    cupon: true,
    autoRenewal: true,
    mostrar: true,
    precio: true,
  });
  
    return (
      <PageContainer>
        <Header>Seleccionar o Crear un Curso</Header>
        {institutos.length > 1 && (
          <Selector onChange={handleSelectChangee}>
            <option value="">Seleccione un instituto</option>
            {institutos.map((instituto) => (
              <option key={instituto._id} value={instituto._id}>
                {instituto.nombre}
              </option>
            ))}
          </Selector>
        )}
        <Selector onChange={handleSelectChange} value={selectedPlanVenta ? selectedPlanVenta._id : ''}>
          <option value="">Seleccione un Curso</option>
          {Array.isArray(servicio) && servicio.length > 0 && servicio.map((plannVenta) => (
            <option key={plannVenta._id} value={plannVenta._id}>
              {plannVenta.courseName}
            </option>
          ))}
        </Selector>
        <ButtonRow>
          <CreateButton onClick={handleCreateNew}>
            <FaPlus style={{ marginRight: '8px' }} />
            Nuevo Curso
          </CreateButton>
          <ButtonDelete onClick={openDeleteModal}>
            <FaTrash style={{ marginRight: '10px' }} />
            Eliminar
          </ButtonDelete>
          <HomeButton onClick={() => navigate('/admin-home')}>
            <FaHome style={{ marginRight: '8px' }} />
            Inicio
          </HomeButton>
        </ButtonRow>
        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={closeDeleteModal}
          contentLabel="Confirmación de Eliminación"
          ariaHideApp={false}
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            },
            content: {
              padding: '20px',
              backgroundColor: '#2c2c2c',
              borderRadius: '10px',
              width: '400px',
              maxWidth: '90%',
              color: '#ffffff',
              textAlign: 'center',
              margin: 'auto',
              position: 'relative',
              top: 'auto',
              left: 'auto',
              right: 'auto',
              bottom: 'auto'
            }
          }}
        >
          <ModalHeader>Confirmación de Eliminación</ModalHeader>
          <ModalText>¿Estás seguro de que deseas eliminar el Curso: {selectedPlanVenta && selectedPlanVenta.courseName}?</ModalText>
          <ModalButtonContainer>
            <ModalButton onClick={handleDelete}>Sí, eliminar</ModalButton>
            <CancelButton onClick={closeDeleteModal}>Cancelar</CancelButton>
          </ModalButtonContainer>
        </Modal>
        <CheckBoxContainer>
          <CheckBox
            type="checkbox"
            checked={inactivo}
            onChange={(e) => setInactivo(e.target.checked)}
          />{' '}
          Inactivo (se muestra en gris)
        </CheckBoxContainer>
        <Header>Editar Curso</Header>
        <FormContainer onSubmit={handleSubmit}>
        <InputTitle>Nombre del curso</InputTitle>
          <InputField
            type="text"
            name="courseName"
            placeholder="Nombre del Curso"
            value={courseName}
            onChange={(e) => setCourseName(e.target.value)}
            required
          />
          <InputTitle>Una introdución del curso (se muestra en +infoo)</InputTitle>
          <InputField
            type="text"
            name="introduction"
            placeholder="Introducción"
            value={introduction}
            onChange={(e) => setIntroduction(e.target.value)}
            required
          />
          <InputTitle>Información adicional (se muestra en la pagina +info)</InputTitle>
          <TextAreaField
            name="information"
            placeholder="Información"
            value={information}
            onChange={(e) => setInformation(e.target.value)}
            required
          />
          <InputTitle>Programa del Curso (es una guia para el seguimiento del curso, ese muestra en +info)</InputTitle>
          <TextAreaField
            name="planprograma"
            placeholder="Programa del Curso"
            value={planprograma}
            onChange={(e) => setPlanprograma(e.target.value)}
          />
          <InputTitle>Promoción (se muestra en etiqueta comercial)</InputTitle>
          <TextAreaField
            name="promotion"
            placeholder="Promoción"
            value={promotion}
            onChange={(e) => setPromotion(e.target.value)}
          />
          <SectionTitle>Precios del tipo de plan</SectionTitle>
          <CheckBoxContainer>
        <InputContainer>
          <InputTitle>Mensual</InputTitle>
          <SmallInputField
            type="number"
            name="prices.mensual"
            placeholder="Mensual"
            value={mensual}
            onChange={(e) => setMensual(e.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <InputTitle>Bimensual</InputTitle>
          <SmallInputField
            type="number"
            name="prices.bimensual"
            placeholder="Bimensual"
            value={bimensual}
            onChange={(e) => setBimensual(e.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <InputTitle>Cuatrimestral</InputTitle>
          <SmallInputField
            type="number"
            name="prices.cuatrimestral"
            placeholder="Cuatrimestral"
            value={cuatrimestral}
            onChange={(e) => setCuatrimestral(e.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <InputTitle>Dto %</InputTitle>
          <SmallInputField
            type="number"
            name="descuento"
            placeholder="Descuento a aplicar"
            value={descuento}
            onChange={(e) => setDescuento(e.target.value)}
          />
        </InputContainer>
      </CheckBoxContainer>
      <SectionTitle>Contenido</SectionTitle>
      <CheckBoxContainer>
        <CheckBoxLabel>
          <CheckBoxInput
            type="checkbox"
            name="chat"
            checked={chat}
            onChange={(e) => setChat(e.target.checked)}
            disabled={!flags.chat}
          />
          chat
        </CheckBoxLabel>
        <CheckBoxLabel>
          <CheckBoxInput
            type="checkbox"
            name="video"
            checked={videoCheckpoint}
            onChange={(e) => setVideoCheckpoint(e.target.checked)}
            disabled={!flags.video}
          />
          Video
        </CheckBoxLabel>
        <CheckBoxLabel>
          <CheckBoxInput
            type="checkbox"
            name="quiz"
            checked={quiz}
            onChange={(e) => setQuiz(e.target.checked)}
            disabled={!flags.quiz}
          />
          Cuestionario
        </CheckBoxLabel>
        <CheckBoxLabel>
          <CheckBoxInput
            type="checkbox"
            name="pdf"
            checked={pdf}
            onChange={(e) => setPdf(e.target.checked)}
            disabled={!flags.pdf}
          />
          PDF
        </CheckBoxLabel>
        <CheckBoxLabel>
          <CheckBoxInput
            type="checkbox"
            name="links"
            checked={links}
            onChange={(e) => setLinks(e.target.checked)}
            disabled={!flags.links}
          />
          Links
        </CheckBoxLabel>
      </CheckBoxContainer>
      <SectionTitle>Configuración de Pago</SectionTitle>
      <CheckBoxContainer>
        <CheckBoxLabel>
          <CheckBoxInput
            type="checkbox"
            name="mercado"
            checked={mercado}
            onChange={(e) => setMercado(e.target.checked)}
            disabled={!flags.mercado}
          />
          Mercado Pago
        </CheckBoxLabel>
        <CheckBoxLabel>
          <CheckBoxInput
            type="checkbox"
            name="cupon"
            checked={cupon}
            onChange={(e) => setCupon(e.target.checked)}
            disabled={!flags.cupon}
          />
          Cupón
        </CheckBoxLabel>
        <CheckBoxLabel>
          <CheckBoxInput
            type="checkbox"
            name="autoRenewal"
            checked={autoRenewal}
            onChange={(e) => setAutoRenewal(e.target.checked)}
            disabled={!flags.autoRenewal}
          />
          Renovación Mensual
        </CheckBoxLabel>
        <CheckBoxLabel>
          <CheckBoxInput
            type="checkbox"
            name="mostrar"
            checked={mostrar}
            onChange={(e) => setMostrar(e.target.checked)}
            disabled={!flags.mostrar}
          />
          Mostrar
        </CheckBoxLabel>
        <CheckBoxLabel style={{ opacity: flags.mostrar ? 1 : 0.5 }}>
          <CheckBoxInput
            type="checkbox"
            name="precio"
            checked={precio}
            onChange={(e) => setPrecio(e.target.checked)}
            disabled={!flags.precio || !mostrar}
          />
          Precio
        </CheckBoxLabel>
      </CheckBoxContainer>
          <Button type="button" onClick={() => document.getElementById('file-input-imagen').click()}>
            Imagen Portada
          </Button>
          <HiddenFileInput
            id="file-input-imagen"
            type="file"
            onChange={handleImagenChange}
          />
          {imagen && <p>Imagen cargada: {imagen.name}</p>}
          <Button type="button" onClick={() => document.getElementById('file-input-video').click()}>
            Video +info
          </Button>
          <HiddenFileInput
            id="file-input-video"
            type="file"
            onChange={handleVideoChange}
          />
          {video && <p>Video cargado: {video.name}</p>}
          <ProgressBarContainer show={uploading}>
                <ProgressBar progress={uploadProgress} />
            </ProgressBarContainer>
          <ButtonContainer>
            <Button type="submit">Guardar Cambios</Button>
          </ButtonContainer>
        </FormContainer>
        <ImageVideoContainer>
        {selectedPlanVenta && selectedPlanVenta.imagen && (
          <ImageContainer>
            <img src={`/imCurso/${selectedPlanVenta.imagen}`} alt="Curso" />
          </ImageContainer>
        )}
        {selectedPlanVenta && selectedPlanVenta.video && (
          <VideoContainer>
            <video controls>
              <source src={`/videoCurso/${selectedPlanVenta.video}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </VideoContainer>
        )}
      </ImageVideoContainer>
      </PageContainer>
    );
  };
  
  export default EditorCursos;