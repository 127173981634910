import React, { useState, useEffect } from 'react';
import { createInstituto } from '../api';
import styled from 'styled-components';
import { FaHome } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #1c1c1c;
  color: #ffffff;
  min-height: 100vh;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 780px;
`;

const Header = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  margin-bottom: 20px;
  width: 100%;
`;

const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  margin-bottom: 20px;
  width: 100%;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #ffffff;
`;

const CheckBox = styled.input`
  margin-right: 10px;
`;

const FileInputButton = styled.label`
  background-color: #007bff;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 20px;
  margin-left: 20px;

  &:hover {
    background-color: #0056b3;
  }
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 5px;
  width: 120px;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const HomeButton = styled(Button)`
  background-color: #6c757d;

  &:hover {
    background-color: #5a6268;
  }
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #444;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  visibility: ${props => props.show ? 'visible' : 'hidden'};
`;

const ProgressBar = styled.div`
  width: ${props => props.progress}%;
  background-color: #007bff;
  height: 20px;
  transition: width 0.5s;
`;

const NewInstituto = () => {
  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [materias, setMaterias] = useState('');
  const [contacto, setContacto] = useState('');
  const [publico, setPublico] = useState(false);
  const [imagen, setImagen] = useState(null);
  const [video, setVideo] = useState(null);
  const [imagenNombre, setImagenNombre] = useState('');
  const [videoNombre, setVideoNombre] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (uploading) {
      const totalSize = (imagen ? imagen.size : 0) + (video ? video.size : 0);
      let uploaded = 0;

      const interval = setInterval(() => {
        uploaded += 2 * 1024 * 1024; // 2MB por segundo
        const progressPercentage = Math.min((uploaded / totalSize) * 100, 99);
        setUploadProgress(Math.floor(progressPercentage));

        if (progressPercentage >= 99) {
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [uploading, imagen, video]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!imagen || !video) {
      alert('Seleccione una imagen y un video.');
      return;
    }

    const formData = new FormData();
    formData.append('nombre', nombre);
    formData.append('descripcion', descripcion);
    formData.append('materias', materias);
    formData.append('contacto', contacto);
    formData.append('publico', publico);
    formData.append('imagen', imagen);
    formData.append('video', video);

    setUploading(true);

    try {
      await createInstituto(formData);
      setUploadProgress(100);
      alert('Instituto creado exitosamente');
      navigate('/admin-home');
    } catch (error) {
      console.error('Error al crear instituto:', error);
      setUploading(false);
    }
  };

  const handleImagenChange = (e) => {
    setImagen(e.target.files[0]);
    setImagenNombre(e.target.files[0].name);
  };

  const handleVideoChange = (e) => {
    setVideo(e.target.files[0]);
    setVideoNombre(e.target.files[0].name);
  };

  return (
    <PageContainer>
      <Header>Crear Instituto</Header>
      <FormContainer>
        <form onSubmit={handleSubmit}>
          <Input 
            type="text" 
            value={nombre} 
            onChange={(e) => setNombre(e.target.value)} 
            placeholder="Nombre" 
            required 
          />
          <TextArea 
            value={descripcion} 
            onChange={(e) => setDescripcion(e.target.value)} 
            placeholder="Descripción" 
            required 
          />
          <TextArea 
            value={materias} 
            onChange={(e) => setMaterias(e.target.value)} 
            placeholder="Materias" 
            required 
          />
         <Input 
            type="text" 
            value={contacto} 
            onChange={(e) => setContacto(e.target.value)} 
            placeholder="Numero de contacto" 
          />
          <CheckBoxContainer>
            <CheckBox 
              type="checkbox" 
              checked={publico} 
              onChange={(e) => setPublico(e.target.checked)} 
            /> Público (no exclusivo)
          </CheckBoxContainer>
          <FileInputButton htmlFor="file-input-imagen">
            Cargar Imagen
          </FileInputButton>
          <HiddenFileInput 
            id="file-input-imagen" 
            type="file" 
            onChange={handleImagenChange} 
            required 
          />
          {imagenNombre && <p>Imagen cargada: {imagenNombre}</p>}
          <FileInputButton htmlFor="file-input-video">
            Cargar Video
          </FileInputButton>
          <HiddenFileInput 
            id="file-input-video" 
            type="file" 
            onChange={handleVideoChange} 
            required 
          />
          {videoNombre && <p>Video cargado: {videoNombre}</p>}
          <ProgressBarContainer show={uploading}>
            <ProgressBar progress={uploadProgress} />
          </ProgressBarContainer>
          <ButtonContainer>
            <Button type="submit">Crear</Button>
            <HomeButton type="button" onClick={() => navigate('/admin-home')}>
              <FaHome /> Inicio
            </HomeButton>
          </ButtonContainer>
        </form>
      </FormContainer>
    </PageContainer>
  );
};

export default NewInstituto;
