import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { FaBars, FaTwitter, FaInstagram, FaLinkedin, FaWhatsapp, FaGraduationCap, FaRegListAlt, FaServicestack, FaInfoCircle, FaEnvelope, FaBook, FaEdit, FaCreditCard, FaCog, FaSignOutAlt, FaComments, FaVideo, FaQuestionCircle, FaFilePdf, FaLink, FaShoppingCart, FaUserPlus, FaTicketAlt } from 'react-icons/fa';
import { getProfile, InstitutosByUser, CoursesByInstitutoId, generateCouponCode, verifyCouponCode, solicitudProfe } from '../api'; // Asegurémonos de importar correctamente la API
import CouponModal from '../components/cuponModal'; // Ajustar ruta
import { handleRequestError } from '../components/errorHandler'; // Ajustar ruta

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1c1c1c;
  color: #ffffff;
  overflow-x: hidden;
`;

const Header = styled.header`
  width: 100%;
  padding: 10px 20px;
  background-color: #1c1c1c;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  height: 40px;
`;

const Title = styled.h1`
  font-family: Arial, sans-serif;
  font-size: 24px;
  color: #ffffff;
  margin-left: 10px;
`;

const NavMenu = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const MenuButton = styled(FaBars)`
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 20px;
`;

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ffffff;
  background-color: #1c1c1c;
`;

const DropdownMenu = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  top: 0;
  right: 0;
  background-color: #1c1c1c;
  border: 1px solid #ffffff;
  border-radius: 5px;
  z-index: 1;
`;

const MenuItem = styled(Link)`
  display: block;
  padding: 10px 20px;
  color: #ffffff;
  text-decoration: none;

  &:hover {
    background-color: #333333;
    color: #007bff;

    svg {
      color: #007bff;
    }
  }
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;

  @media (min-width: 768px) {
    padding: 0;
  }
`;

const Footer = styled.footer`
  background-color: #1c1c1c;
  padding: 20px;
  text-align: center;
  color: #ffffff;
  width: 100%;
`;

const SocialIcons = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const SocialIcon = styled.a`
  color: #ffffff;
  font-size: 24px;
  text-decoration: none;
  &:hover {
    color: #007bff;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-left: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Button = styled(Link)`
  background: ${({ isPrice }) => (isPrice ? '#28a745' : '#007bff')};
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  min-width: 100px;
`;

const CourseGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  padding: 20px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const TabContent = styled.div`
  padding: 20px;
  background-color: ${({ tabColor }) => tabColor};
  border-radius: 0 0 10px 10px;
  margin-top: -1px; // Ajustado para eliminar el espacio entre tabs y contenido

`;

const Disclaimer = styled.p`
  font-size: 12px;
  color: #ffffff;
  margin-top: 10px;
  text-align: center;
`;

const CourseContainer = styled.div`
 display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 20px;
  
`;

const CourseCard = styled.div`
  background-color: #333333;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 80%; // Cambiado de 30% a 40% para mayor ancho
  max-width: 600px; // Aumentado el max-width para más flexibilidad
  background-color: #f9f9f9;
  margin-right: 2px; //ajustar para que se vea bien en la pantalla delc elular
  margin-left: 0px;

  @media (max-width: 1200px) {
    width: 45%;
  }

  @media (max-width: 768px) {
    width: 87%;
  }
`;

const CourseTitle = styled.h2`
  font-size: 28px;
  color: #333333;
  margin-bottom: 10px;
`;

const Promotion = styled.p`
  font-size: 16px;
  color: #333333;
  margin-bottom: 10px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const Icon = styled.div`
  color: ${({ isActive }) => (isActive ? '#007bff' : '#555555')};
  font-size: 24px;
  position: relative;

  &:hover::after {
    content: ${({ legend, isActive }) => (isActive ? `'${legend}'` : 'none')};
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333333;
    color: #ffffff;
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    font-size: 12px;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  justify-content: space-between; // Distribuye las pestañas uniformemente center;
  gap: 10px;
  margin-top: 20px;
  border-bottom: 1px solid #ffffff;
  margin-bottom: 0; // Ajustado para que no haya margen inferior
   width: 100%; // Asegura que el contenedor de las pestañas ocupe todo el ancho del CourseCard
   box-sizing: border-box; // Incluye el padding y el border en el ancho total
`;

const Tab = styled.div`
  background: ${({ selected }) => (selected ? '#ff8c00' : '#f4a460')};
  color: #fff;
  padding: 10px 15px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  transition: background-color 0.3s;
  flex: 1; // Hace que cada tab ocupe un espacio igual
  font-size: 14px; // Ajusta el tamaño de la letra aquí

  &.cuota1 {
    background: ${({ selected }) => (selected ? '#29312F' : '#777b75')};
  }

  &.cuota2 {
    background: ${({ selected }) => (selected ? '#5817C2' : '#777b75')};
  }

  &.cuota4 {
    background: ${({ selected }) => (selected ? '#C0162E' : '#777b75')};
  }

  &.cupon {
    background: ${({ selected }) => (selected ? '#0F947A' : '#777b75')};
  }
`;

const Spinner = styled.div`
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-top: 8px solid #007bff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
  margin: auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const InstitutoDescription = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
  text-align: center;
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    width: 50%;
    margin-bottom: 0;
    padding: 0 20px;
  }
`;

const VideoContainer = styled.video`
  width: 100%;
  max-height: 800px;
  border-radius: 10px;
`;

const ImagenContainer = styled.div`
  width: 100%;
  max-height: 800px;

  img {
    width: 75%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 5px;
    margin-left: 45px;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  @media (min-width: 768px) {
    width: 50%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const MateriasTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #ffffff;
`;

const DescriptionText = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
  text-align: justify;
  color: #ffffff;
  line-height: 1.5;

  p {
    margin-bottom: 15px;
  }
`;

const MateriasList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  column-count: 1;
  column-gap: 20px;

  @media (min-width: 768px) {
    column-count: 2;
  }

  li {
    font-weight: bold;
    color: #ffbf00;
    margin-bottom: 10px;

    &::before {
      content: '• ';
      color: #ffbf00;
      font-weight: bold;
    }
  }
`;

const ContactoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const ContactoLink = styled.a`
  display: flex;
  align-items: center;
  color: #25d366;
  text-decoration: none;
  font-size: 16px;
  margin-left: 5px;
`;

const ImageCurso = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-left: 5px;
`;

const PrecioTachado = styled.span`
  text-decoration: line-through;
  color: #ff6b6b; /* Color rojo para el precio tachado */
  font-size: 1.2em;
  margin-right: 10px;
`;

const Precio = styled.span`
  color: #333333; /* Color blanco para el precio regular */
  font-size: 1.5em;
  font-weight: bold;
`;

const Descuento = styled.div`
  color: #4caf50; /* Color verde para el ahorro */
  font-size: 1.2em;
  margin-top: 10px;

  @media (min-width: 768px) {
    margin-top: 0;
    margin-left: 10px;
  }
`;

const Modal = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const ModalContent = styled.div`
  background-color: #333333;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  text-align: center;
`;

const CloseButton = styled.button`
  background: #007bff;
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
`;

const SearchBar = styled.input`
  padding: 10px;
  width: 80%;
  max-width: 400px;
  margin: 20px 0;
  border-radius: 10px;
  border: 1px solid #cccccc;
  font-size: 16px;

    @media (max-width: 768px) {
    display: none; // Ocultar la barra de búsqueda en pantallas pequeñas
  }
`;

const PerfilInstituto = () => {
  const [instituto, setInstituto] = useState(null);
  const [courses, setCourses] = useState([]);
  const [profile, setProfile] = useState({});
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTab, setSelectedTab] = useState('cuota1');
  const [isCuponModalOpen, setIsCuponModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [solicitudEnviada, setSolicitudEnviada] = useState(false);
  const [isSolicitudVisible, setIsSolicitudVisible] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { institutoId } = useParams();
  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setMenuOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setMenuOpen(false);
    }, 200);
  };

  const filteredCourses = courses.filter(course =>
    course.courseName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const fetchInstituto = async () => {
      try {
        const InstitutoResponse = await InstitutosByUser(institutoId);
        setInstituto(InstitutoResponse.data);
      } catch (error) {
        handleRequestError(error, navigate);
      }
    };

    fetchInstituto();
  }, [institutoId, navigate]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await CoursesByInstitutoId(institutoId);
        setCourses(response.data);
      } catch (error) {
        //handleRequestError(error, navigate);
      }
    };

    fetchCourses();
  }, [institutoId, navigate]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getProfile();
        setProfile(response.data);
      } catch (error) {
        console.log('Error al obtener el perfil:', error);
      }
    };

    fetchProfile();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setTimeout(() => {
      navigate('/');
    }, 100);
  };

  const openModal = (course) => {
    setSelectedCourse(course);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCourse(null);
  };

  const handleTabChange = (tab, e) => {
    if (e && e.preventDefault) {
      e.preventDefault(); // Evita la acción predeterminada del enlace
    }
    if (e && e.stopPropagation) {
      e.stopPropagation(); // Evita que el clic en el enlace de contacto active el enlace principal
    }
    setSelectedTab(tab);
  };

  const getPrice = (course, tab) => {
    switch (tab) {
      case 'cuota1':
        const mes = (course.prices.mensual * (1 - (course.descuento / 100))).toFixed(0);
        return mes;
      case 'cuota2':
        const bimensual = (course.prices.bimensual * (1 - (course.descuento / 100))).toFixed(0);
        return bimensual;
      case 'cuota4':
        const cuatrimestre = (course.prices.cuatrimestral * (1 - (course.descuento / 100))).toFixed(0);
        return cuatrimestre;
      case 'cupon':
            return '';
      default:
        return 0;
    }
  };


  const getPriceSD = (course, tab) => {
    switch (tab) {
      case 'cuota1':
        return course.prices.mensual;
      case 'cuota2':
        return course.prices.bimensual;
      case 'cuota4':
        return course.prices.cuatrimestral;
      case 'cupon':
            return '';
      default:
        return 0;
    }
  };


  const getCuota = (tab) => { //usar para asignar los nombres de las etiquetas tab por si se quieren cambiar
    switch (tab) {
      case 'cuota1':
        return 'Cuotas de:';
      case 'cuota2':
        return 'Cuotas de:';
      case 'cuota4':
        return 'Todo el Curso:';
      case 'cupon':
            return '';
      default:
        return '';
    }
  };


  const getDto = (course, tab) => {
    switch (tab) {
      case 'cuota1':
        if(course.descuento && course.descuento > 0) {
            const mes = (course.prices.mensual * (course.descuento / 100)).toFixed(0);
            return mes;
        } else {
           const mes = 0;
           return mes;
        }
      case 'cuota2':
        if(course.descuento && course.descuento > 0) {
        const bimensual = (course.prices.bimensual * (course.descuento / 100)).toFixed(0);
        return bimensual;
        } else {
           const bimensual = 0;
           return bimensual;
        }
        
      case 'cuota4':
        if(course.descuento && course.descuento > 0) {
        const cuatrimestre = (course.prices.cuatrimestral * (course.descuento / 100)).toFixed(0);
        return cuatrimestre;
        } else {
           const cuatrimestre = 0;
           return cuatrimestre;
        }
      case 'cupon':
            return '';
      default:
        return 0;
    }
  };


  const getTabColor = (tab) => {
    switch (tab) {
      case 'cuota1':
        return '#29312F'; //#6f856b
      case 'cuota2':
        return '#5817C2'; //#6b7f85
      case 'cuota4':
        return '#C0162E'; //#6d6b85
      case 'cupon':
        return '#0F947A'; //#856b7c
      default:
        return '#333333';
    }
  };


  const handleGenerateCoupon = async (course) => {
    try {
      const response = await generateCouponCode({ userId: profile._id, courseId: course._id });
      setModalMessage(response.data.message);
      setSelectedCourse(course);
      setIsCuponModalOpen(true);
    } catch (error) {
      console.log('Error al generar el código de cupón:', error);
      setModalMessage('No hay cupones disponibles para esta cuenta.');
      setIsCuponModalOpen(true);
    }
  };

  const handleVerifyCoupon = async (code) => {
    try {
      const response = await verifyCouponCode({ userId: profile._id, code, courseId: selectedCourse._id });
      alert(response.data.message);
      closeCuponModal();
    } catch (error) {
      console.log('Error al verificar el código de cupón:', error);
      alert('Código de verificación inválido o caducado.');
    }
  };

  const closeCuponModal = () => {
    setIsCuponModalOpen(false);
    setSelectedCourse(null);
  };

  const handleContactoClick = (e) => {
    e.stopPropagation(); // Evita que el clic en el enlace de contacto active el enlace principal
  };


  useEffect(() => {
    if (instituto && instituto.solicitudprofe && profile) {
        // Verifica si el profile._id ya está en la matriz solicitudprofe
        if (instituto.solicitudprofe.includes(profile._id)) {
            setSolicitudEnviada(true);
            setIsSolicitudVisible(false);
        }
        if (instituto.administradores.includes(profile._id)) {
          setSolicitudEnviada(true);
          setIsSolicitudVisible(false);
      }
    }
}, [instituto, profile]);


const handleSolicitud = async () => {
  try {
      const response = await solicitudProfe(instituto._id, profile._id);
      alert(response.data.message);
      setSolicitudEnviada(true);
      setIsSolicitudVisible(false);
  } catch (error) {
      console.log('Error al enviar la solicitud de profesor:', error);
      alert('Error al enviar la solicitud de profesor. Inténtalo de nuevo más tarde.');
  }
};

const precioPrivado = (coursePrecio) => {
  return !profile ? !coursePrecio : coursePrecio;
};




  if (!instituto) {
    return ( <HomeContainer>
      <Header>
        <LogoContainer>
          {instituto && instituto.imagen ? (
            <img src={`/imInst/${instituto.imagen}`} alt="Perfil" style={{ maxHeight: '70px', maxWidth: '100%' }} onError={(e) => { e.target.onerror = null; e.target.src="/images/logo.svg"; }} />
          ) : (
            <Logo src="/images/logo.svg" alt="Logo" height="70px" />
          )}
          {instituto && instituto.nombre ? (
            <Title style={{ fontSize: '26px', margin: '0 0 0 10px' }}>{instituto.nombre}</Title>
          ) : (
            <Title>estudiochat</Title>
          )}
        </LogoContainer>
        <NavMenu
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <DropdownMenu isOpen={menuOpen}>
            <DropdownHeader>
              <ImageContainer>
                {profile && profile.imagen ? (
                  <img src={`/imPerf/${profile.imagen}`} alt="Perfil" style={{ maxHeight: '70px', maxWidth: '100%' }} onError={(e) => { e.target.onerror = null; e.target.src="/images/logo.svg"; }} />
                ) : (
                  <Logo src="/images/logo.svg" alt="Logo" height="20px" />
                )}
              </ImageContainer>
              <Title style={{ fontSize: '16px', margin: '0 0 0 10px' }}>{profile.name}</Title>
            </DropdownHeader>
            <MenuItem to="/institutos"><FaGraduationCap style={{ marginRight: '8px' }} />Institutos</MenuItem>
            <MenuItem to="/planes-comerciales"><FaRegListAlt style={{ marginRight: '8px' }} />Nuestros Planes</MenuItem>
            <MenuItem to="/servicio"><FaServicestack style={{ marginRight: '8px' }} />Servicios</MenuItem>
            <MenuItem to="/quienes-somos"><FaInfoCircle style={{ marginRight: '8px' }} />Quienes Somos</MenuItem>
            <MenuItem to="/contacto"><FaEnvelope style={{ marginRight: '8px' }} />Contacto</MenuItem>
            {(profile.courses && profile.courses.length > 0) && <MenuItem to="/mis-cursos"><FaBook style={{ marginRight: '8px' }} />Mis Cursos</MenuItem>}
            {(profile && profile.isVerified === true) && <MenuItem to="/editar-perfil"><FaEdit style={{ marginRight: '8px' }} />Editar Perfil</MenuItem>}
            {(profile.role === 'supadm' || profile.role === 'admin') && <MenuItem to="/panel-pago"><FaCreditCard style={{ marginRight: '8px' }} />Panel Pago</MenuItem>}
            {(profile.role === 'supadm' || profile.role === 'admin') && <MenuItem to="/admin-home"><FaCog style={{ marginRight: '8px' }} />Configuracion</MenuItem>}
            {(profile && profile.sessionToken !== null) && <MenuItem onClick={handleLogout}><FaSignOutAlt style={{ marginRight: '8px' }} />Salir</MenuItem>}
            <MenuItem to="/info-plan">Nuestros Planes</MenuItem>
          </DropdownMenu>
          <MenuButton onClick={() => setMenuOpen(!menuOpen)} />
        </NavMenu>
      </Header>
      <InstitutoDescription>
                <Spinner /> {/* Spinner de carga */}
      </InstitutoDescription>
      <Footer>
        <p>&copy; 2024 Todos los derechos reservados a <a href="https://www.labordt.com">Labor D&T</a></p>
        <SocialIcons>
          <SocialIcon href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></SocialIcon>
          <SocialIcon href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></SocialIcon>
          <SocialIcon href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></SocialIcon>
        </SocialIcons>
      </Footer>
    </HomeContainer>

    )
  }

  return (
    <HomeContainer>
      <Header>
        <LogoContainer>
          {instituto && instituto.imagen ? (
            <img src={`/imInst/${instituto.imagen}`} alt="Perfil" style={{ maxHeight: '70px', maxWidth: '100%' }} onError={(e) => { e.target.onerror = null; e.target.src="/images/logo.svg"; }} />
          ) : (
            <Logo src="/images/logo.svg" alt="Logo" height="70px" />
          )}
          {instituto && instituto.nombre ? (
            <Title style={{ fontSize: '26px', margin: '0 0 0 10px' }}>{instituto.nombre}</Title>
          ) : (
            <Title>estudiochat</Title>
          )}
        </LogoContainer>
        <SearchBar
          type="text"
          placeholder="Buscar curso..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
        <NavMenu
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <DropdownMenu isOpen={menuOpen}>
            <DropdownHeader>
              <ImageContainer>
                {profile && profile.imagen ? (
                  <img src={`/imPerf/${profile.imagen}`} alt="Perfil" style={{ maxHeight: '70px', maxWidth: '100%' }} onError={(e) => { e.target.onerror = null; e.target.src="/images/logo.svg"; }} />
                ) : (
                  <Logo src="/images/logo.svg" alt="Logo" height="20px" />
                )}
              </ImageContainer>
              <Title style={{ fontSize: '16px', margin: '0 0 0 10px' }}>{profile.name}</Title>
            </DropdownHeader>
            <MenuItem to="/institutos"><FaGraduationCap style={{ marginRight: '8px' }} />Institutos</MenuItem>
            <MenuItem to="/planes-comerciales"><FaRegListAlt style={{ marginRight: '8px' }} />Nuestros Planes</MenuItem>
            <MenuItem to="/servicio"><FaServicestack style={{ marginRight: '8px' }} />Servicios</MenuItem>
            <MenuItem to="/quienes-somos"><FaInfoCircle style={{ marginRight: '8px' }} />Quienes Somos</MenuItem>
            <MenuItem to="/contacto"><FaEnvelope style={{ marginRight: '8px' }} />Contacto</MenuItem>
            {(profile.courses && profile.courses.length > 0) && <MenuItem to="/mis-cursos"><FaBook style={{ marginRight: '8px' }} />Mis Cursos</MenuItem>}
            {(profile && profile.isVerified === true) && <MenuItem to="/editar-perfil"><FaEdit style={{ marginRight: '8px' }} />Editar Perfil</MenuItem>}
            {(profile && profile.isVerified === true) && <MenuItem to="/panel-pago"><FaCreditCard style={{ marginRight: '8px' }} />Panel Pago</MenuItem>}
            {(profile && profile.isVerified && isSolicitudVisible) && (
                <MenuItem onClick={handleSolicitud}>
                    <FaUserPlus style={{ marginRight: '8px' }} />
                    {solicitudEnviada ? 'Solicitud Enviada' : 'Solicitud Profe'}
                </MenuItem>
            )}
            {(profile.role === 'supadm' || profile.role === 'admin') && <MenuItem to="/admin-home"><FaCog style={{ marginRight: '8px' }} />Configuracion</MenuItem>}
            {(profile && profile.sessionToken !== null) && <MenuItem onClick={handleLogout}><FaSignOutAlt style={{ marginRight: '8px' }} />Salir</MenuItem>}
            <MenuItem to="/info-plan">Nuestros Planes</MenuItem>
          </DropdownMenu>
          <MenuButton onClick={() => setMenuOpen(!menuOpen)} />
        </NavMenu>
      </Header>
      <MainContent>
      <>
        <ContentSection>
          <VideoWrapper>
            {instituto.video ? (
              <VideoContainer controls autoPlay controlsList="nodownload">
                <source src={`/videosPromo/${instituto.video}`} type="video/mp4" />
                Su navegador no soporta este tipo de videos.
              </VideoContainer>
            ) : (
              <ImagenContainer>
                <img src={`/imInst/${instituto.imagen}`} alt="Instituto" />
              </ImagenContainer>
            )}
          </VideoWrapper>
          <TextWrapper>
            {instituto.materias && (
              <>
                <MateriasTitle>Materias</MateriasTitle>
                <MateriasList>
                  {instituto.materias.split('\n').map((materia, index) => (
                    <li key={index}>{materia}</li>
                  ))}
                </MateriasList>
              </>
            )}
            {instituto.descripcion && (
              <DescriptionText>
                {instituto.descripcion.split('\n').map((para, index) => (
                  <p key={index}>{para}</p>
                ))}
              </DescriptionText>
            )}
            {instituto.contacto && (
              <ContactoContainer>
                <FaWhatsapp size={24} color="#25D366" />
                <ContactoLink href={`https://wa.me/${instituto.contacto}`} target="_blank" rel="noopener noreferrer">
                  {instituto.contacto}
                </ContactoLink>
              </ContactoContainer>
            )}
          </TextWrapper>
        </ContentSection>
      </>
        <CourseContainer>
        <CourseGrid>
          {filteredCourses.length > 0 ? (
            filteredCourses.map(course => (
            <Link to={`/info-curso/${course._id}`} key={course.id} style={{ textDecoration: 'none', color: 'inherit' }}>
              <CourseCard key={course._id}>
                <CourseTitle>{course.courseName}</CourseTitle>
                  <ImageCurso>
                    <img src={`/imCurso/${course.imagen}`} alt={course.nombre} style={{ maxHeight: '150px', maxWidth: '100%' }} />
                  </ImageCurso>
                <IconContainer>
                  <Icon isActive={course.checkpoints.chat} legend="Tu mejor amigo a la hora de estudiar"><FaComments /></Icon>
                  <Icon isActive={course.checkpoints.video} legend="Clases grabadas con todo lo que tienes que saber"><FaVideo /></Icon>
                  <Icon isActive={course.checkpoints.quiz} legend="Las mejores pruebas para aprender practicando"><FaQuestionCircle /></Icon>
                  <Icon isActive={course.checkpoints.pdf} legend="Todos los materiales que necesitas"><FaFilePdf /></Icon>
                  <Icon isActive={course.checkpoints.links} legend="Enlaces útiles que puedes usar para seguir aprendiendo"><FaLink /></Icon>
                </IconContainer>
                {course.promotion && <Promotion>{course.promotion}</Promotion>}
                {course.prices && selectedTab !== 'cupon' && course.checkpoints.mostrar === true && !precioPrivado(course.precio) && <Precio>{getCuota(selectedTab)}${getPrice(course, selectedTab)}</Precio>}
                    {course.descuento > 0 && course.checkpoints.mostrar === true && !precioPrivado(course.precio) && selectedTab !== 'cupon' && (
                        <>
                            <div style={{  display: 'flex', alignItems: 'baseline', justifyContent: 'center'}}>
                                <h3 style={{ color: '#333333', margin: '0 10px 0 0' }}>Antes: </h3>
                                <PrecioTachado>${getPriceSD(course, selectedTab)}</PrecioTachado>
                                <Descuento>Ahorras: ${getDto(course, selectedTab)}</Descuento>
                            </div>
                            
                        </>
                    )}
                    {selectedTab === 'cupon' && (
                      <h3 style={{ color: '#333333', margin: '0 10px 0 0' }}>El cupon no presenta cargo</h3>
                    )}
                <TabsContainer>
                  {course.prices.mensual > 0 && course.checkpoints.mostrar === true && !precioPrivado(course.precio) && (
                    <Tab
                      className="cuota1"
                      selected={selectedTab === 'cuota1'}
                      onClick={(e) => handleTabChange('cuota1', e)}
                      title='Selecciona la forma de pago en cuotas mensual, bimensual, total'
                    >
                      Cuota
                    </Tab>
                  )}
                  {course.prices.bimensual > 0 && course.checkpoints.mostrar === true && !precioPrivado(course.precio) && (
                    <Tab
                      className="cuota2"
                      selected={selectedTab === 'cuota2'}
                      onClick={(e) => handleTabChange('cuota2', e)}
                      title='Selecciona la forma de pago en cuotas, mensual, bimensual, total'
                    >
                      Medio
                    </Tab>
                  )}
                  {course.prices.cuatrimestral > 0 && course.checkpoints.mostrar === true && !precioPrivado(course.precio) && (
                    <Tab
                      className="cuota4"
                      selected={selectedTab === 'cuota4'}
                      onClick={(e) => handleTabChange('cuota4', e)}
                      title='Selecciona la forma de pago en cuotas, mensual, bimensual, total'
                    >
                      Todo
                    </Tab>
                  )}
                  {course.checkpoints.cupon && (
                    <Tab
                      className="cupon"
                      selected={selectedTab === 'cupon'}
                      onClick={(e) => handleTabChange('cupon', e)}
                      title='Selecciona para canjear tu cupón'
                    >
                      <FaTicketAlt style={{ color: '#ffff', fontSize: '24px' }} />
                    </Tab>
                  )}
                </TabsContainer>
                <TabContent tabColor={getTabColor(selectedTab)}>
                  <ButtonContainer onClick={handleContactoClick}>
                    <Button to={`/curso-info/${course._id}`} >Más Info</Button>
                    {selectedTab === 'cupon' ? (
                      <Button to="#" isPrice onClick={() => handleGenerateCoupon(course)}>
                        <FaShoppingCart /> Obtener
                      </Button>
                    ) : (
                      course.checkpoints?.mostrar === true && !precioPrivado(course.precio) && (
                      <Button to={`/comprar/${course._id}`} isPrice>
                        <FaShoppingCart /> ${getPrice(course, selectedTab)}
                      </Button>
                        )
                    )}
                  </ButtonContainer>
                  {selectedTab === 'cuota1' && course.checkpoints.mostrar === true && !precioPrivado(course.precio) && course.prices.bimensual > 0 && course.prices.cuatrimestral > 0 && (
                    <Disclaimer>
                      El costo incluye solo una cuota del curso, este curso completo consta de 4 cuotas. Consulte <Link to="/bases-y-condiciones" style={{ color: '#ffff' }}>bases y condiciones</Link>.
                    </Disclaimer>
                  )}
                  {selectedTab === 'cuota1' && course.checkpoints.mostrar === true && !precioPrivado(course.precio) && course.prices.bimensual > 0 && course.prices.cuatrimestral === 0 && (
                    <Disclaimer>
                      El costo incluye solo una cuota del curso, este curso completo consta de 2 cuotas. Consulte <Link to="/bases-y-condiciones" style={{ color: '#ffff' }}>bases y condiciones</Link>.
                    </Disclaimer>
                  )}
                  {selectedTab === 'cuota1' && course.checkpoints.mostrar === true && !precioPrivado(course.precio) && course.prices.bimensual === 0 && (
                    <Disclaimer>
                      El costo es por todo el curso completo. Consulte <Link to="/bases-y-condiciones" style={{ color: '#ffff' }}>bases y condiciones</Link>.
                    </Disclaimer>
                  )}
                  {selectedTab === 'cuota2' && course.checkpoints.mostrar === true && !precioPrivado(course.precio) && course.prices.cuatrimestral > 0 && (
                    <Disclaimer>
                      El costo incluye el primer módulo del curso, se deberan abonar dos cuotas como esta para completar el curso. Consulte <Link to="/bases-y-condiciones" style={{ color: '#ffff' }}>bases y condiciones</Link>.
                    </Disclaimer>
                  )}
                  {selectedTab === 'cuota2' && course.checkpoints.mostrar === true && !precioPrivado(course.precio) && course.prices.cuatrimestral === 0 && (
                    <Disclaimer>
                      El costo es por todo el curso completo. Consulte <Link to="/bases-y-condiciones" style={{ color: '#ffff' }}>bases y condiciones</Link>.
                    </Disclaimer>
                  )}
                  {selectedTab === 'cuota4' && course.checkpoints.mostrar === true && !precioPrivado(course.precio) && (
                    <Disclaimer>
                      El costo es por todo el curso completo. Consulte <Link to="/bases-y-condiciones" style={{ color: '#ffff' }}>bases y condiciones</Link>.
                    </Disclaimer>
                  )}
                  {selectedTab === 'cupon' && (
                    <Disclaimer>
                      Promoción válida para algunos usuarios, consulte <Link to="/bases-y-condiciones" style={{ color: '#ffff' }}>bases y condiciones</Link>.
                    </Disclaimer>
                  )}
                </TabContent>
              </CourseCard>
              </Link>
            ))
          ) : (
            <p>No hay cursos disponibles.</p>
          )}
          </CourseGrid>
        </CourseContainer>
      </MainContent>
      <Modal isOpen={isModalOpen}>
        <ModalContent>
          <h2>{selectedCourse?.courseName}</h2>
          <p>{selectedCourse?.information}</p>
          <CloseButton onClick={closeModal}>Cerrar</CloseButton>
        </ModalContent>
      </Modal>
      <CouponModal isOpen={isCuponModalOpen} onClose={closeCuponModal} onSubmit={handleVerifyCoupon} message={modalMessage} />
      <Footer>
        <p>&copy; 2024 Todos los derechos reservados a <a href="https://www.labordt.com">Labor D&T</a></p>
        <SocialIcons>
          <SocialIcon href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></SocialIcon>
          <SocialIcon href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></SocialIcon>
          <SocialIcon href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></SocialIcon>
        </SocialIcons>
      </Footer>
    </HomeContainer>
  );
};

export default PerfilInstituto;
