import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getProfile, getInstitutosByUser, updatePagoCurso, createPagoCurso, PagoCursoPorId, cursosPorInstituto } from '../api';
import { useNavigate } from 'react-router-dom';
import { FaSave, FaHome } from 'react-icons/fa';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #1c1c1c;
  color: #ffffff;
  min-height: 100vh;
`;

const Header = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 780px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  width: 120px;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const CheckBox = styled.input`
  margin-right: 10px;
`;

const Selector = styled.select`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
   width: 350px; /* Ajusta el ancho según tus necesidades */
   justify-content: center;
`;


const InputField = styled.input`
  background: #444;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  width: 50%; /* Ajusta el ancho al 50% */
  margin-right: 10px;

  &:focus {
    outline: none;
    background: #555;
  }
`;

const SmallInputField = styled.input`
  background: #444;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  width: 50%; /* Ajusta el ancho al 50% */
  margin-right: 10px;

  &:focus {
    outline: none;
    background: #555;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #0D0F0F;
  cursor: pointer;
`;


const SectionContainer = styled.div`
  background-color: #f2f2f2;
  color: #000;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
`;

const CheckBoxLabel = styled.label`
  color: #0D0F0F;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

const CheckBoxLabell = styled.label`
  color: #ffff;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

const CheckBoxGroup = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 10px; /* Espacio inferior para cada grupo */
`;


const CollapsibleSection = ({ title, children, isOpen, onClick }) => (
  <SectionContainer>
    <SectionTitle onClick={onClick}>{title}</SectionTitle>
    {isOpen && children}
  </SectionContainer>
);

const PagoCursoEditor = () => {
  const [institutos, setInstitutos] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [pagoCurso, setPagoCurso] = useState([]);
  const [selectedInstituto, setSelectedInstituto] = useState('');
  const [selectedCurso, setSelectedCurso] = useState('');
  const [formData, setFormData] = useState({
    institutoId: '',
    cursoId: '',
    bajaAuto: [{}],
    gincobrable: [{}],
    notificacion: [{}],
    vtaModo: [{}],
    forma: [{}],
    dto: [{}],
    createdAt: new Date(),
  });
  const [applyToAll, setApplyToAll] = useState(false);
  //aca a posteriori se llama a la api que indica cuales funciones tiene disponible el plan que contrato el administrado para su instituto
  const [flags, setFlags] = useState({
    bajaAuto: true,
    gincobrable: true,
    notificacion: true,
    vtaModo: true,
    forma: true,
    dto: true,
  });

  const [openSections, setOpenSections] = useState({
    bajaAuto: false,
    gincobrable: false,
    notificacion: false,
    vtaModo: false,
    forma: false,
    dto: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getProfile();
        const userId = response.data._id;
        const institutosResponse = await getInstitutosByUser(userId);
        setInstitutos(institutosResponse.data);

        if (institutosResponse.data.length === 1) {
          const instituto = institutosResponse.data[0];
          setSelectedInstituto(instituto);
          setFormData((prevData) => ({
            ...prevData,
            institutoId: instituto._id,
          }));
        }

      } catch (error) {
        console.error('Error al obtener el perfil:', error);
      }
    };

    fetchProfile();
  }, []);

  useEffect(() => {
    if (selectedInstituto) {
      const fetchData = async () => {
        try {
          const pagoCursoData = await PagoCursoPorId(selectedInstituto._id);
          setFormData((prevData) => ({
            ...prevData,
            ...pagoCursoData.data
          }));
          setPagoCurso(pagoCursoData.data);
        } catch (error) {
          console.error('Error al obtener los datos de pago curso:', error);
        }
      };
      fetchData();
    }
  }, [selectedInstituto]);

  const formatToISODate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate() + 1).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  

  useEffect(() => {
    if (selectedCurso && !applyToAll) {
      const fetchCursoData = async () => {
        try {
            const id = selectedInstituto._id;
            //console.log('id del instituto:', id);
            const idcurso = selectedCurso._id;
            //console.log('id del curso elegido:', idcurso);
          const response = await PagoCursoPorId(id);
          //console.log('Datos de PagoCurso recibidos:', response.data); // Log para ver los datos recibidos

          const pagoCurso = response.data[0]; // Asegúrate de acceder correctamente al primer objeto
          
          /*if (pagoCurso) {
            const { bajaAuto, gincobrable, notificacion, vtaModo, forma, dto } = pagoCurso;
            
            // Verificar la estructura de los datos recibidos
            console.log('Estructura de bajaAuto:', bajaAuto);
            console.log('Estructura de gincobrable:', gincobrable);
            console.log('Estructura de notificacion:', notificacion);
            console.log('Estructura de vtaModo:', vtaModo);
            console.log('Estructura de forma:', forma);
            console.log('Estructura de dto:', dto);
          }*/


            // Valores por defecto
          const defaultBajaAuto = [{
            fechabaja: '',
            mes: false,
            quincena: false,
            diasHab: 0,
            manual: false
          }];

          const defaultGincobrable = [{
            activar: false,
            plazo: 0,
            suspencion: 0,
            baja: 0
          }];

          const defaultNotificacion = [{
            pago: false,
            vto: false,
            suspencion: false,
            bajaimpago: false
          }];

          const defaultVtaModo = [{
            cuota: false,
            modulo: false,
            forzar: false,
            allcursos: false
          }];

          const defaultForma = [{
            autogestion: false,
            manual: false,
            gratis: false
          }];

          const defaultDto = [{
            automatico: false,
            acumulable: false,
            especifico: false,
            margina: false,
            n1: 0,
            n2: 0,
            n3: 0,
            n4: 0,
            n5: 0
          }];

          const { bajaAuto, gincobrable, notificacion, vtaModo, forma, dto } = pagoCurso;

          const filteredData = {
            //bajaAuto: (bajaAuto && bajaAuto.length > 0) ? bajaAuto.filter(item => item.cursoId === selectedCurso._id) : defaultBajaAuto,
            bajaAuto: (bajaAuto && bajaAuto.length > 0) ? bajaAuto.filter(item => item.cursoId === selectedCurso._id).map(item => ({
                ...item,
                fechabaja: item.fechabaja ? formatToISODate(item.fechabaja) : '',
              })) : defaultBajaAuto,
            gincobrable: (gincobrable && gincobrable.length > 0) ? gincobrable : defaultGincobrable,
            notificacion: (notificacion && notificacion.length > 0) ? notificacion : defaultNotificacion,
            vtaModo: (vtaModo && vtaModo.length > 0) ? vtaModo.filter(item => item.cursoId === selectedCurso._id) : defaultVtaModo,
            forma: (forma && forma.length > 0) ? forma.filter(item => item.cursoId === selectedCurso._id) : defaultForma,
            dto: (dto && dto.length > 0) ? dto.filter(item => item.cursoId === selectedCurso._id) : defaultDto,
          };

          //console.log('filtro aplicado:', filteredData);

          setFormData((prevData) => ({
            ...prevData,
            bajaAuto: filteredData.bajaAuto.length ? filteredData.bajaAuto : defaultBajaAuto,
            gincobrable: filteredData.gincobrable.length ? filteredData.gincobrable : defaultGincobrable,
            notificacion: filteredData.notificacion.length ? filteredData.notificacion : defaultNotificacion,
            vtaModo: filteredData.vtaModo.length ? filteredData.vtaModo : defaultVtaModo,
            forma: filteredData.forma.length ? filteredData.forma : defaultForma,
            dto: filteredData.dto.length ? filteredData.dto : defaultDto,
          }));

        // Establecer todas las secciones abiertas para ver los datos
        setOpenSections({
            bajaAuto: true,
            gincobrable: true,
            notificacion: true,
            vtaModo: true,
            forma: true,
            dto: true,
          });
        } catch (error) {
          console.error('Error al obtener los datos del curso11:', error);
        }
        
      };
      fetchCursoData();
    }
  }, [selectedCurso, applyToAll]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === 'institutoId') {
      const instituto = institutos.find((inst) => inst._id === value);
      setSelectedInstituto(instituto || null);
    }
  };

  useEffect(() => {
    if (selectedInstituto) {
      const fetchCursos = async () => {
        try {
            const institutoId = selectedInstituto._id;
            //console.log('id del instituto11:', institutoId);
          const cursosData = await cursosPorInstituto(institutoId);
          setCursos(Array.isArray(cursosData.data) ? cursosData.data : []); // Asegurarse de que cursosData.data es un array
          //console.log('Cursos recibidos:', cursosData.data); // Log para ver los datos recibidos
          setSelectedCurso(''); // Reiniciar la selección del curso cuando cambie el instituto
          setApplyToAll(false);
        } catch (error) {
          console.error('Error al obtener los cursos:', error);
          setCursos([]); // Manejo de error estableciendo cursos como array vacío
        }
      };
      fetchCursos();
    } else {
      setCursos([]);
      setSelectedCurso('');
      setApplyToAll(false);
    }
  }, [selectedInstituto]);

  
  const handleArrayChange = (index, field, value, arrayName) => {
    const updatedArray = [...formData[arrayName]];
    updatedArray[index] = { ...updatedArray[index], [field]: value };
    setFormData({ ...formData, [arrayName]: updatedArray });
  };

  const formatToMongoDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        // Crear un nuevo objeto formDataToSubmit basado en formData
        const createFormDataToSubmit = (cursoId) => {
          const formDataToSubmit = {
            institutoId: selectedInstituto._id,
            cursoId: cursoId,
            bajaAuto: formData.bajaAuto.map(item => ({
              cursoId: cursoId, // Asignar cursoId a cada ítem
              fechabaja: item.fechabaja ? formatToMongoDate(item.fechabaja) : '', // Convertir la fecha al formato ISO 8601
              mes: item.mes,
              quincena: item.quincena,
              diasHab: item.diasHab,
              manual: item.manual
            })),
            gincobrable: formData.gincobrable.map(item => ({
              activar: item.activar,
              plazo: item.plazo,
              suspencion: item.suspencion,
              baja: item.baja
            })),
            notificacion: formData.notificacion.map(item => ({
              pago: item.pago,
              vto: item.vto,
              suspencion: item.suspencion,
              bajaimpago: item.bajaimpago
            })),
            vtaModo: formData.vtaModo.map(item => ({
              cursoId: cursoId, // Asignar cursoId a cada ítem
              cuota: item.cuota,
              modulo: item.modulo,
              forzar: item.forzar
            })),
            forma: formData.forma.map(item => ({
              cursoId: cursoId, // Asignar cursoId a cada ítem
              autogestion: item.autogestion,
              manual: item.manual,
              gratis: item.gratis
            })),
            dto: formData.dto.map(item => ({
              cursoId: cursoId, // Asignar cursoId a cada ítem
              n1: item.n1,
              n2: item.n2,
              n3: item.n3,
              n4: item.n4,
              n5: item.n5,
              automatico: item.automatico,
              acumulable: item.acumulable,
              especifico: item.especifico,
              margina: item.margina
            }))
          };
    
          // Limpiar arrays vacíos
          Object.keys(formDataToSubmit).forEach(key => {
            if (Array.isArray(formDataToSubmit[key]) && formDataToSubmit[key].length === 0) {
              delete formDataToSubmit[key];
            }
          });
    
          return formDataToSubmit;
        };

    


      //console.log('flag todos los cursos', applyToAll);
      //console.log('data de cursos:', cursos);
      if (applyToAll) {
        const allCourses = cursos.map(course => course._id);

        console.log('matriz de curso id:', allCourses);
    
        const updatePromises = allCourses.map(courseId => 
            updatePagoCurso(selectedInstituto._id, createFormDataToSubmit(courseId))
        ); 
    
        const results = await Promise.allSettled(updatePromises);
    
        results.forEach(result => {
            if (result.status === 'fulfilled') {
                console.log('Update successful:', result.value);
            } else {
                console.error('Update failed:', result.reason);
            }
        });
    }
     else {
        const formDataToSubmit = createFormDataToSubmit(selectedCurso._id);
        //console.log('Datos enviados limpios:', formDataToSubmit); // Log para ver los datos enviados
        await updatePagoCurso(selectedInstituto._id, formDataToSubmit);
      }
      alert("configuracion guardada con exito!");
      navigate('/editar-pagos-cursos');
      window.location.reload();
    } catch (error) {
        alert("Error al realizar operacion de guardado. Intentelo de nuevo mas tarde.");
      //console.error('Error al guardar la configuración de pago:', error);
    }
  };


  const toggleSection = (section) => {
    //console.log('Toggling section:', section);
    setOpenSections((prevSections) => {
      const newSections = {
        ...prevSections,
        [section]: !prevSections[section],
      };
      //console.log('Nuevo estado de openSections:', newSections);
      return newSections;
    });
  };
  



  const handleSelectChangee = (e) => {
    const cursoid = e.target.value;
    const curso = cursos.find((curso) => curso._id === cursoid);
    setSelectedCurso(curso);
  };

  return (
    <PageContainer>
      <Header>Configuracón de Pago</Header>
      <FormContainer onSubmit={handleSubmit}>
      {institutos.length > 1 && (
        <Selector name="institutoId" value={formData.institutoId} onChange={handleInputChange}>
          <option value="">Seleccione un Instituto</option>
          {Array.isArray(institutos) && institutos.map((instituto) => (
            <option key={instituto._id} value={instituto._id}>
              {instituto.nombre}
            </option>
          ))}
        </Selector>
        )}
        {Array.isArray(cursos) && cursos.length > 0 && (
          <>
            <Selector onChange={handleSelectChangee} value={selectedCurso ? selectedCurso._id : ''} disabled={applyToAll}>
          <option value="">Seleccione un Curso</option>
          {Array.isArray(cursos) && cursos.length > 0 && cursos.map((curso) => (
            <option key={curso._id} value={curso._id}>
              {curso.courseName}
            </option>
          ))}
            </Selector>

            <CheckBoxContainer>
              <CheckBox
                type="checkbox"
                checked={applyToAll}
                onChange={() => { setApplyToAll(!applyToAll); setSelectedCurso(''); }}
              />
              <CheckBoxLabell>Aplicar a todos los Cursos</CheckBoxLabell>
            </CheckBoxContainer>
          </>
        )}

        {flags.bajaAuto && (
          <CollapsibleSection title="Baja Automática" isOpen={openSections.bajaAuto} onClick={() => toggleSection('bajaAuto')}>
            {Array.isArray(formData.bajaAuto) && formData.bajaAuto.map((item, index) => (
            <div key={index}>
                <label>Fecha de Baja</label>
                <InputField
                type="date"
                name={`bajaAuto[${index}].fechabaja`}
                value={item.fechabaja || ''}
                onChange={(e) => handleArrayChange(index, 'fechabaja', e.target.value, 'bajaAuto')}
                />
                <CheckBoxContainer>
                <CheckBoxGroup>
                    <CheckBox
                    type="checkbox"
                    name={`bajaAuto[${index}].mes`}
                    checked={item.mes || false}
                    onChange={(e) => handleArrayChange(index, 'mes', e.target.checked, 'bajaAuto')}
                    />
                    <CheckBoxLabel>Mes</CheckBoxLabel>
                </CheckBoxGroup>
                <CheckBoxGroup>
                    <CheckBox
                    type="checkbox"
                    name={`bajaAuto[${index}].quincena`}
                    checked={item.quincena || false}
                    onChange={(e) => handleArrayChange(index, 'quincena', e.target.checked, 'bajaAuto')}
                    />
                    <CheckBoxLabel>Quincena</CheckBoxLabel>
                </CheckBoxGroup>
                <CheckBoxGroup>
                    <SmallInputField
                    type="number"
                    name={`bajaAuto[${index}].diasHab`}
                    value={item.diasHab || ''}
                    onChange={(e) => handleArrayChange(index, 'diasHab', e.target.value, 'bajaAuto')}
                    />
                    <CheckBoxLabel>Días Hábiles</CheckBoxLabel>
                </CheckBoxGroup>
                <CheckBoxGroup>
                    <CheckBox
                    type="checkbox"
                    name={`bajaAuto[${index}].manual`}
                    checked={item.manual || false}
                    onChange={(e) => handleArrayChange(index, 'manual', e.target.checked, 'bajaAuto')}
                    />
                    <CheckBoxLabel>Manual</CheckBoxLabel>
                </CheckBoxGroup>
                </CheckBoxContainer>
            </div>
            ))}
          </CollapsibleSection>
        )}

        {flags.gincobrable && (
          <CollapsibleSection title="Gestión Incobrables" isOpen={openSections.gincobrable} onClick={() => toggleSection('gincobrable')}>
            {Array.isArray(formData.gincobrable) && formData.gincobrable.map((item, index) => (
            <div key={index}>
                <CheckBoxContainer>
                <CheckBoxGroup>
                    <CheckBox
                    type="checkbox"
                    name={`gincobrable[${index}].activar`}
                    checked={item.activar || false}
                    onChange={(e) => handleArrayChange(index, 'activar', e.target.checked, 'gincobrable')}
                    />
                    <CheckBoxLabel>Activar</CheckBoxLabel>
                </CheckBoxGroup>
                <CheckBoxGroup>
                    <SmallInputField
                    type="number"
                    name={`gincobrable[${index}].plazo`}
                    value={item.plazo || ''}
                    onChange={(e) => handleArrayChange(index, 'plazo', e.target.value, 'gincobrable')}
                    />
                    <CheckBoxLabel>Plazo (días)</CheckBoxLabel>
                </CheckBoxGroup>
                <CheckBoxGroup>
                    <SmallInputField
                    type="number"
                    name={`gincobrable[${index}].suspencion`}
                    value={item.suspencion || ''}
                    onChange={(e) => handleArrayChange(index, 'suspencion', e.target.value, 'gincobrable')}
                    />
                    <CheckBoxLabel>Suspensión (días)</CheckBoxLabel>
                </CheckBoxGroup>
                <CheckBoxGroup>
                    <SmallInputField
                    type="number"
                    name={`gincobrable[${index}].baja`}
                    value={item.baja || ''}
                    onChange={(e) => handleArrayChange(index, 'baja', e.target.value, 'gincobrable')}
                    />
                    <CheckBoxLabel>Baja (días)</CheckBoxLabel>
                </CheckBoxGroup>
                </CheckBoxContainer>
            </div>
            ))}
          </CollapsibleSection>
        )}

        {flags.notificacion && (
          <CollapsibleSection title="Notificación" isOpen={openSections.notificacion} onClick={() => toggleSection('notificacion')}>
            {Array.isArray(formData.notificacion) && formData.notificacion.map((item, index) => (
              <div key={index}>
                <CheckBox
                  type="checkbox"
                  name={`notificacion[${index}].pago`}
                  checked={item.pago || false}
                  onChange={(e) => handleArrayChange(index, 'pago', e.target.checked, 'notificacion')}
                />
                <label>Pago</label>
                <CheckBox
                  type="checkbox"
                  name={`notificacion[${index}].vto`}
                  checked={item.vto || false}
                  onChange={(e) => handleArrayChange(index, 'vto', e.target.checked, 'notificacion')}
                />
                <label>Vto</label>
                <CheckBox
                  type="checkbox"
                  name={`notificacion[${index}].suspencion`}
                  checked={item.suspencion || false}
                  onChange={(e) => handleArrayChange(index, 'suspencion', e.target.checked, 'notificacion')}
                />
                <label>Suspensión</label>
                <CheckBox
                  type="checkbox"
                  name={`notificacion[${index}].bajaimpago`}
                  checked={item.bajaimpago || false}
                  onChange={(e) => handleArrayChange(index, 'bajaimpago', e.target.checked, 'notificacion')}
                />
                <label>Baja por Impago</label>
              </div>
            ))}
          </CollapsibleSection>
        )}

        {flags.vtaModo && (
          <CollapsibleSection title="Venta Modo" isOpen={openSections.vtaModo} onClick={() => toggleSection('vtaModo')}>
            {Array.isArray(formData.vtaModo) && formData.vtaModo.map((item, index) => (
              <div key={index}>
                <CheckBox
                  type="checkbox"
                  name={`vtaModo[${index}].cuota`}
                  checked={item.cuota || false}
                  onChange={(e) => handleArrayChange(index, 'cuota', e.target.checked, 'vtaModo')}
                />
                <label>Cuota</label>
                <CheckBox
                  type="checkbox"
                  name={`vtaModo[${index}].modulo`}
                  checked={item.modulo || false}
                  onChange={(e) => handleArrayChange(index, 'modulo', e.target.checked, 'vtaModo')}
                />
                <label>Módulo</label>
                <CheckBox
                  type="checkbox"
                  name={`vtaModo[${index}].forzar`}
                  checked={item.forzar || false}
                  onChange={(e) => handleArrayChange(index, 'forzar', e.target.checked, 'vtaModo')}
                />
                <label>Forzar</label>
              </div>
            ))}
          </CollapsibleSection>
        )}

        {flags.forma && (
          <CollapsibleSection title="Forma" isOpen={openSections.forma} onClick={() => toggleSection('forma')}>
            {Array.isArray(formData.forma) && formData.forma.map((item, index) => (
              <div key={index}>
                <CheckBox
                  type="checkbox"
                  name={`forma[${index}].autogestion`}
                  checked={item.autogestion || false}
                  onChange={(e) => handleArrayChange(index, 'autogestion', e.target.checked, 'forma')}
                />
                <label>Autogestión</label>
                <CheckBox
                  type="checkbox"
                  name={`forma[${index}].manual`}
                  checked={item.manual || false}
                  onChange={(e) => handleArrayChange(index, 'manual', e.target.checked, 'forma')}
                />
                <label>Manual</label>
                <CheckBox
                  type="checkbox"
                  name={`forma[${index}].gratis`}
                  checked={item.gratis || false}
                  onChange={(e) => handleArrayChange(index, 'gratis', e.target.checked, 'forma')}
                />
                <label>Gratis</label>
              </div>
            ))}
          </CollapsibleSection>
        )}

        {flags.dto && (
          <CollapsibleSection title="Descuento" isOpen={openSections.dto} onClick={() => toggleSection('dto')}>
            {Array.isArray(formData.dto) && formData.dto.map((item, index) => (
            <div key={index}>
                <CheckBoxContainer>
                <CheckBoxGroup>
                    <SmallInputField
                    type="number"
                    name={`dto[${index}].n1`}
                    value={item.n1 || ''}
                    onChange={(e) => handleArrayChange(index, 'n1', e.target.value, 'dto')}
                    />
                    <CheckBoxLabel>Descuento Nivel 1</CheckBoxLabel>
                </CheckBoxGroup>
                <CheckBoxGroup>
                    <SmallInputField
                    type="number"
                    name={`dto[${index}].n2`}
                    value={item.n2 || ''}
                    onChange={(e) => handleArrayChange(index, 'n2', e.target.value, 'dto')}
                    />
                    <CheckBoxLabel>Descuento Nivel 2</CheckBoxLabel>
                </CheckBoxGroup>
                <CheckBoxGroup>
                    <SmallInputField
                    type="number"
                    name={`dto[${index}].n3`}
                    value={item.n3 || ''}
                    onChange={(e) => handleArrayChange(index, 'n3', e.target.value, 'dto')}
                    />
                    <CheckBoxLabel>Descuento Nivel 3</CheckBoxLabel>
                </CheckBoxGroup>
                <CheckBoxGroup>
                    <SmallInputField
                    type="number"
                    name={`dto[${index}].n4`}
                    value={item.n4 || ''}
                    onChange={(e) => handleArrayChange(index, 'n4', e.target.value, 'dto')}
                    />
                    <CheckBoxLabel>Descuento Nivel 4</CheckBoxLabel>
                </CheckBoxGroup>
                <CheckBoxGroup>
                    <SmallInputField
                    type="number"
                    name={`dto[${index}].n5`}
                    value={item.n5 || ''}
                    onChange={(e) => handleArrayChange(index, 'n5', e.target.value, 'dto')}
                    />
                    <CheckBoxLabel>Descuento Nivel 5</CheckBoxLabel>
                </CheckBoxGroup>
                <CheckBoxGroup>
                    <CheckBox
                    type="checkbox"
                    name={`dto[${index}].automatico`}
                    checked={item.automatico || false}
                    onChange={(e) => handleArrayChange(index, 'automatico', e.target.checked, 'dto')}
                    />
                    <CheckBoxLabel>Automático</CheckBoxLabel>
                </CheckBoxGroup>
                <CheckBoxGroup>
                    <CheckBox
                    type="checkbox"
                    name={`dto[${index}].acumulable`}
                    checked={item.acumulable || false}
                    onChange={(e) => handleArrayChange(index, 'acumulable', e.target.checked, 'dto')}
                    />
                    <CheckBoxLabel>Acumulable</CheckBoxLabel>
                </CheckBoxGroup>
                <CheckBoxGroup>
                    <CheckBox
                    type="checkbox"
                    name={`dto[${index}].especifico`}
                    checked={item.especifico || false}
                    onChange={(e) => handleArrayChange(index, 'especifico', e.target.checked, 'dto')}
                    />
                    <CheckBoxLabel>Específico</CheckBoxLabel>
                </CheckBoxGroup>
                <CheckBoxGroup>
                    <CheckBox
                    type="checkbox"
                    name={`dto[${index}].margina`}
                    checked={item.margina || false}
                    onChange={(e) => handleArrayChange(index, 'margina', e.target.checked, 'dto')}
                    />
                    <CheckBoxLabel>Marginal</CheckBoxLabel>
                </CheckBoxGroup>
                </CheckBoxContainer>
            </div>
            ))}
          </CollapsibleSection>
        )}

        <ButtonContainer>
          <Button type="submit">
            <FaSave /> Guardar
          </Button>
          <Button type="button" onClick={() => navigate('/admin-home')}>
            <FaHome /> Volver
          </Button>
        </ButtonContainer>
      </FormContainer>
    </PageContainer>
  );
};

export default PagoCursoEditor;
