import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaBars, FaTwitter, FaInstagram, FaLinkedin, FaShoppingCart, FaComments, FaVideo, FaQuestionCircle, FaFilePdf, FaLink } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; 
import Modal from 'react-modal';// Importamos axios directamente


const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1c1c1c;
  color: #ffffff;
  overflow-x: hidden;
`;

const Header = styled.header`
  width: 100%;
  padding: 10px 20px;
  background-color: #1c1c1c;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  height: 40px;
`;

const Title = styled.h1`
  font-family: Arial, sans-serif;
  font-size: 24px;
  color: #ffffff;
  margin-left: 10px;
`;

const NavMenu = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const MenuButton = styled(FaBars)`
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 20px;
`;

const DropdownMenu = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: #1c1c1c;
  border: 1px solid #ffffff;
  border-radius: 5px;
  z-index: 1;
`;

const MenuItem = styled(Link)`
  display: block;
  padding: 10px 20px;
  color: #ffffff;
  text-decoration: none;
  &:hover {
    background-color: #333333;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

const SearchBar = styled.input`
  padding: 10px;
  width: 80%;
  max-width: 400px;
  margin: 20px 0;
  border-radius: 10px;
  border: 1px solid #cccccc;
  font-size: 16px;
`;

const CourseContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 20px;
`;

const CourseCard = styled.div`
  background-color: #333333;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  width: 30%;
  max-width: 450px;
  text-align: center;

  @media (max-width: 1200px) {
    width: 45%;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const CourseTitle = styled.h2`
  font-size: 28px;
  color: #ffffff;
  margin-bottom: 10px;
`;

const CourseDescription = styled.p`
  font-size: 18px;
  color: #cccccc;
  margin-bottom: 20px;
`;

const Promotion = styled.p`
  font-size: 16px;
  color: #ffcc00;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
`;

const Button = styled(Link)`
  background: ${({ isPrice }) => (isPrice ? '#28a745' : '#007bff')};
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  min-width: 100px;
`;

const ButtonVta = styled(Link)`
  background: #28a745;
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  min-width: 100px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const Icon = styled.div`
  color: ${({ isActive }) => (isActive ? '#007bff' : '#555555')};
  font-size: 24px;
  position: relative;

  &:hover::after {
    content: ${({ legend, isActive }) => (isActive ? `'${legend}'` : 'none')};
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333333;
    color: #ffffff;
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    font-size: 12px;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  border-bottom: 1px solid #ffffff;
`;

const Tab = styled.div`
  background: ${({ selected }) => (selected ? '#ff8c00' : '#f4a460')}; /* Naranja para 1 Cuota */
  color: #fff;
  padding: 10px 15px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  transition: background-color 0.3s;

  &.cuota1 {
    background: ${({ selected }) => (selected ? '#6f856b' : '#777b75')};
  }

  &.cuota2 {
    background: ${({ selected }) => (selected ? '#6b7f85' : '#777b75')}; /* Amarillo para 2 Cuotas */
  }

  &.cuota4 {
    background: ${({ selected }) => (selected ? '#6b6b85' : '#777b75')}; /* Violeta para 4 Cuotas */
  }

  &.cupon {
    background: ${({ selected }) => (selected ? '#856b7c' : '#777b75')}; /* Verde oscuro para Cupón */
  }
`;

const TabContent = styled.div`
  padding: 20px;
  background-color: ${({ tabColor }) => tabColor};
  border-radius: 0 0 10px 10px;
`;

const Disclaimer = styled.p`
  font-size: 12px;
  color: #ffffff; /* Letra de la leyenda */
  margin-top: 10px;
`;

const Footer = styled.footer`
  background-color: #1c1c1c;
  padding: 20px;
  text-align: center;
  color: #ffffff;
  width: 100%;
`;

const SocialIcons = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const SocialIcon = styled.a`
  color: #ffffff;
  font-size: 24px;
  text-decoration: none;
  &:hover {
    color: #007bff;
  }
`;

const ModalInfo = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const ModalContentInfo = styled.div`
  background-color: #333333;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  text-align: center;
`;

const CloseButton = styled.button`
  background: #007bff;
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
`;


// Configuración de React Modal
Modal.setAppElement('#root');

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const ModalHeader = styled.h2`
  margin-bottom: 10px;
`;

const ModalContent = styled.p`
  margin-bottom: 20px;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const CancelButton = styled(Button)`
  background-color: #6c757d;

  &:hover {
    background-color: #5a6268;
  }
`;
// Estilos (igual que antes)

const CursosPublic = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [courses, setCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState('cuota1');
  const [isModalInfoOpen, setIsInfoOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        // Llamada a la API para obtener los cursos públicos
        const response = await axios.get('/api/auth/courses-public');
        setCourses(response.data);
      } catch (error) {
        console.log('Error al obtener los cursos:', error);
      }
    };

    fetchCourses();
  }, []);

  const filteredCourses = courses.filter(course =>
    course.courseName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const openModal = (course) => {
    setSelectedCourse(course);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCourse(null);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const getPrice = (course, tab) => {
    switch (tab) {
      case 'cuota1':
        return course.prices.mensual;
      case 'cuota2':
        return course.prices.bimensual;
      case 'cuota4':
        return course.prices.cuatrimestral;
      default:
        return 0;
    }
  };

  const getTabColor = (tab) => {
    switch (tab) {
      case 'cuota1':
        return '#6f856b';
      case 'cuota2':
        return '#6b7f85';
      case 'cuota4':
        return '#6d6b85';
      case 'cupon':
        return '#856b7c';
      default:
        return '#333333';
    }
  };

  const OnInicio = () => {
    setTimeout(() => { 
      navigate('/'); // Redirigir a la página de cursos
    }, 100); 
  };
 
  

  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setMenuOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setMenuOpen(false);
    }, 200); // Mantener el menú visible durante 200 ms después de alejar el puntero
  };

  const openInfoModal = () => {
    setIsInfoOpen(true);
  };

  const closeInfoModal = () => {
    setIsInfoOpen(false);
  };



  return (
    <HomeContainer>
      <Header>
        <LogoContainer>
          <Logo src="/images/logo.svg" alt="Logo" />
          <Title>estudiochat</Title>
        </LogoContainer>
        <NavMenu
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
          <DropdownMenu isOpen={menuOpen}>
            <MenuItem onClick={OnInicio}>Inicio</MenuItem>
            <MenuItem to="/servicio">Servicio</MenuItem>
            <MenuItem to="/cursos">Cursos</MenuItem>
            <MenuItem to="/quienes-somos">Quienes Somos</MenuItem>
            <MenuItem to="/contacto">Contacto</MenuItem>
          </DropdownMenu>
          <MenuButton onClick={() => setMenuOpen(!menuOpen)} />
        </NavMenu>
      </Header>
      <MainContent>
        <SearchBar
          type="text"
          placeholder="Buscar curso..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
        <CourseContainer>
          {filteredCourses.length > 0 ? (
            filteredCourses.map(course => (
              <CourseCard key={course._id}>
                <CourseTitle>{course.courseName}</CourseTitle>
                <IconContainer>
                  <Icon isActive={course.checkpoints.chat} legend="Tu mejor amigo a la hora de estudiar"><FaComments /></Icon>
                  <Icon isActive={course.checkpoints.video} legend="Clases grabadas con todo lo que tienes que saber"><FaVideo /></Icon>
                  <Icon isActive={course.checkpoints.quiz} legend="Las mejores pruebas para aprender practicando"><FaQuestionCircle /></Icon>
                  <Icon isActive={course.checkpoints.pdf} legend="Todos los materiales que necesitas"><FaFilePdf /></Icon>
                  <Icon isActive={course.checkpoints.links} legend="Enlaces útiles que puedes usar para seguir aprendiendo"><FaLink /></Icon>
                </IconContainer>
                <CourseDescription>{course.introduction}</CourseDescription>
                {course.promotion && <Promotion>{course.promotion}</Promotion>}
                {course.checkpoints.precio ? (
                  <>
                    <TabsContainer>
                      {course.prices.mensual > 0 && (
                        <Tab
                          className="cuota1"
                          selected={selectedTab === 'cuota1'}
                          onClick={() => handleTabChange('cuota1')}
                        >
                          1 Cuota
                        </Tab>
                      )}
                      {course.prices.bimensual > 0 && (
                        <Tab
                          className="cuota2"
                          selected={selectedTab === 'cuota2'}
                          onClick={() => handleTabChange('cuota2')}
                        >
                          2 Cuotas
                        </Tab>
                      )}
                      {course.prices.cuatrimestral > 0 && (
                        <Tab
                          className="cuota4"
                          selected={selectedTab === 'cuota4'}
                          onClick={() => handleTabChange('cuota4')}
                        >
                          4 Cuotas
                        </Tab>
                      )}
                      {course.checkpoints.cupon && (
                        <Tab
                          className="cupon"
                          selected={selectedTab === 'cupon'}
                          onClick={() => handleTabChange('cupon')}
                        >
                          Cupón
                        </Tab>
                      )}
                    </TabsContainer>
                    <TabContent tabColor={getTabColor(selectedTab)}>
                      <ButtonContainer>
                        <Button to="#" onClick={() => openModal(course)}>Más Info</Button>
                        <ButtonVta onClick={openInfoModal}>
                          <FaShoppingCart /> {selectedTab === 'cupon' ? 'Obtener' : `$${getPrice(course, selectedTab)}`}
                        </ButtonVta>
                      </ButtonContainer>
                      {selectedTab === 'cuota1' && course.prices.bimensual > 0 && (
                        <Disclaimer>
                          El costo incluye solo una cuota del curso, este curso completo consta de 2 cuotas. Consulte <Link to="/bases-y-condiciones" style={{ color: '#007bff' }}>bases y condiciones</Link>.
                        </Disclaimer>
                      )}
                      {selectedTab === 'cuota1' && course.prices.bimensual === 0 && (
                        <Disclaimer>
                          El costo es por todo el curso completo. Consulte <Link to="/bases-y-condiciones" style={{ color: '#007bff' }}>bases y condiciones</Link>.
                        </Disclaimer>
                      )}
                      {selectedTab === 'cuota2' && course.prices.cuatrimestral > 0 && (
                        <Disclaimer>
                          El costo incluye solo dos cuotas del curso, este curso completo consta de 4 cuotas. Consulte <Link to="/bases-y-condiciones" style={{ color: '#007bff' }}>bases y condiciones</Link>.
                        </Disclaimer>
                      )}
                      {selectedTab === 'cuota2' && course.prices.cuatrimestral === 0 && (
                        <Disclaimer>
                          El costo es por todo el curso completo. Consulte <Link to="/bases-y-condiciones" style={{ color: '#007bff' }}>bases y condiciones</Link>.
                        </Disclaimer>
                      )}
                      {selectedTab === 'cuota4' && (
                        <Disclaimer>
                          El costo es por todo el curso completo. Consulte <Link to="/bases-y-condiciones" style={{ color: '#007bff' }}>bases y condiciones</Link>.
                        </Disclaimer>
                      )}
                      {selectedTab === 'cupon' && (
                        <Disclaimer>
                          Promoción válida para algunos usuarios, consulte <Link to="/bases-y-condiciones" style={{ color: '#007bff' }}>bases y condiciones</Link>.
                        </Disclaimer>
                      )}
                    </TabContent>
                  </>
                ) : (
                  <ButtonContainer>
                    <Button to="#" onClick={() => openModal(course)}>Más Info</Button>
                    <ButtonVta onClick={openInfoModal}>Consultar</ButtonVta>
                  </ButtonContainer>
                )}
              </CourseCard>
            ))
          ) : (
            <p>No hay cursos disponibles.</p>
          )}
        </CourseContainer>
      </MainContent>
      <ModalInfo isOpen={isModalOpen}>
        <ModalContentInfo>
          <h2>{selectedCourse?.courseName}</h2>
          <p>{selectedCourse?.information}</p>
          <CloseButton onClick={closeModal}>Cerrar</CloseButton>
        </ModalContentInfo>
      </ModalInfo>
      <Footer>
        <p>&copy; 2024 Todos los derechos reservados a <Link to="https://www.labordt.com">Labor D&T</Link></p>
        <SocialIcons>
          <SocialIcon href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></SocialIcon>
          <SocialIcon href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></SocialIcon>
          <SocialIcon href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></SocialIcon>
        </SocialIcons>
      </Footer>
      <Modal
        isOpen={isModalInfoOpen}
        onRequestClose={closeInfoModal}
        contentLabel="Confirmar Eliminación"
        style={{
          content: {
            background: '#1c1c1c',
            color: '#ffffff',
            borderRadius: '10px',
            padding: '10px',
            width: '450px',
            maxHeight: '200px',
            margin: '0 auto',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute', // Cambiar a posición absoluta
            textAlign: 'center',
            overflow: 'hidden'
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }
        }}
      >
        <ModalContainer>
          <ModalHeader>Es necesario estar logueado</ModalHeader>
          <ModalContent>Para mas informacion de precios o realizar compras debes loguearte. Si no tienes una cuenta puedes crearte una nueva. Te esperamos</ModalContent>
          <ModalActions>
            <Button onClick={OnInicio}>
               Inicio
            </Button>
            <CancelButton onClick={closeInfoModal}>
              Volver
            </CancelButton>
          </ModalActions>
        </ModalContainer>
      </Modal>
    </HomeContainer>
  );
};

export default CursosPublic;


