import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FaBars, FaTwitter, FaInstagram, FaLinkedin, FaGraduationCap, FaRegListAlt, FaServicestack, FaInfoCircle, FaEnvelope, FaSignInAlt } from 'react-icons/fa';
import Login from '../components/Auth/Login';
import Register from '../components/Auth/Register';
import ResetPassword from '../components/Auth/ResetPassword';

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1c1c1c;
  color: #ffffff;
  overflow-x: hidden; /* Añadido para eliminar la franja blanca */
`;

const Header = styled.header`
  width: 100%;
  padding: 10px 20px;
  background-color: #1c1c1c;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  height: 40px;
`;

const Title = styled.h1`
  font-family: Arial, sans-serif;
  font-size: 24px;
  color: #ffffff;
  margin-left: 10px;
`;

const NavMenu = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const MenuButton = styled(FaBars)`
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 20px; /* Añadido para mover el ícono del menú hacia la izquierda */
`;

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ffffff;
  background-color: #1c1c1c;
`;

const DropdownMenu = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  top: 0; /* Ajustar para mover el menú hacia arriba */
  right: 0; /* Alinear a la derecha */
  background-color: #1c1c1c;
  border: 1px solid #ffffff;
  border-radius: 5px;
  z-index: 1;
`;

const MenuItem = styled(Link)`
  display: block;
  padding: 10px 20px;
  color: #ffffff;
  text-decoration: none;

  &:hover {
    background-color: #333333;
        color: #007bff; /* Cambia el color del texto y del icono */

    svg { /* Aplica el color azul también a los iconos */
      color: #007bff;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap; /* Añadido para mejorar la responsividad */
`;

const LogoSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const LargeLogo = styled.img`
  max-width: 70%;
  height: auto;
`;

const ContentSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const MainText = styled.h1`
  font-family: Arial, sans-serif;
  font-size: 40px;
  right: 40px;
  margin-bottom: 80px;
  margin-top: -10px; /* Subir el texto principal */
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Button = styled(Link)`
  background: #007bff;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  width: 50%;
  margin-bottom: 10px;
`;

const TermsText = styled.p`
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
  max-width: 50%;
  line-height: 1.2;
`;

const LinkText = styled(Link)`
  color: #007bff;
  text-decoration: none;
`;

const QuestionText = styled.p`
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
`;

const Footer = styled.footer`
  background-color: #1c1c1c;
  padding: 20px;
  text-align: center;
  color: #ffffff;
`;

const SocialIcons = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const SocialIcon = styled.a`
  color: #ffffff;
  font-size: 24px;
  text-decoration: none;
  &:hover {
    color: #007bff;
  }
`;

const ForgotPasswordText = styled.p`
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
`;




const HomePage = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const timeoutRef = useRef(null);
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setMenuOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setMenuOpen(false);
    }, 200); // Mantener el menú visible durante 200 ms después de alejar el puntero
  };

  const [isModalOpenn, setModalOpenn] = useState(false); // Inicialización del estado del modal de registro

  // Función para abrir el modal
  const openModall = () => setModalOpenn(true);

  // Función para cerrar el modal
  const closeModall = () => setModalOpenn(false);

  const [isModalOpen, setModalOpen] = useState(false); // Inicialización del estado del modal de login

  // Función para abrir el modal
  const openModal = () => setModalOpen(true);

  // Función para cerrar el modal
  const closeModal = () => setModalOpen(false);

  const [isResetPasswordModalOpen, setResetPasswordModalOpen] = useState(false); // Inicialización del estado del modal de restablecimiento de contraseña

  // Función para abrir el modal de restablecimiento de contraseña
  const openResetPasswordModal = () => setResetPasswordModalOpen(true);

  // Función para cerrar el modal de restablecimiento de contraseña
  const closeResetPasswordModal = () => setResetPasswordModalOpen(false);

  const OnCourses = () => {
    setTimeout(() => { 
      navigate('/cursos'); // Redirigir a la página de cursos
    }, 100); 
  };



  return (
    <HomeContainer>
      <Header>
        <LogoContainer>
          <Logo src="/images/logo.svg" alt="Logo" />
          <Title>estudiochat</Title>
        </LogoContainer>
        <NavMenu
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <MenuButton />
          <DropdownMenu isOpen={menuOpen}>
          <DropdownHeader>
              <Logo src="/images/logo.svg" alt="Logo" height="20px" />
              <Title style={{ fontSize: '16px', margin: '0 0 0 10px' }}>Menu</Title>
          </DropdownHeader>
          <MenuItem to="/institutos"><FaGraduationCap style={{ marginRight: '8px' }} />Institutos</MenuItem>
            <MenuItem to="/planes-comerciales"><FaRegListAlt style={{ marginRight: '8px' }} />Nuestros Planes</MenuItem>
            <MenuItem to="/servicio"><FaServicestack style={{ marginRight: '8px' }} />Servicios</MenuItem>
            <MenuItem to="/quienes-somos"><FaInfoCircle style={{ marginRight: '8px' }} />Quienes Somos</MenuItem>
            <MenuItem to="/contacto"><FaEnvelope style={{ marginRight: '8px' }} />Contacto</MenuItem>
            <MenuItem to="/"><FaSignInAlt style={{ marginRight: '8px' }} />Inicio</MenuItem>
          </DropdownMenu>
        </NavMenu>
      </Header>
      <MainContent>
        <LogoSection>
          <LargeLogo src="/images/logo.svg" alt="Logo" />
        </LogoSection>
        <ContentSection>
          <MainText>La mejor forma de aprender chateando</MainText>
          <ButtonContainer>
            <Button onClick={openModall}>Registrarse</Button>
            <Register isOpenn={isModalOpenn} handleClosee={closeModall} />
            <TermsText>
              Al registrarse, acepta los <LinkText to="/terminos-del-servicio">términos del servicio</LinkText>, la <LinkText to="/politica-de-privacidad">política de privacidad</LinkText>, incluida la <LinkText to="/cookies">política de uso de cookies</LinkText>.
            </TermsText>
            <QuestionText>¿Ya tienes cuenta?</QuestionText>
            <Button onClick={openModal}>Iniciar Sesión</Button>
            <Login isOpen={isModalOpen} handleClose={closeModal} />
            <ForgotPasswordText>
              ¿Has olvidado tu contraseña?{' '}
              <LinkText as="span" onClick={openResetPasswordModal}>
                Restáurala
              </LinkText>
            </ForgotPasswordText>
            <ResetPassword isOpen={isResetPasswordModalOpen} handleClose={closeResetPasswordModal} />
          </ButtonContainer>
        </ContentSection>
      </MainContent>
      <Footer>
        <p>&copy; 2024 Todos los derechos reservados a <LinkText to="https://www.labordt.com">Labor D&T</LinkText></p>
        <SocialIcons>
          <SocialIcon href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></SocialIcon>
          <SocialIcon href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></SocialIcon>
          <SocialIcon href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></SocialIcon>
        </SocialIcons>
      </Footer>
    </HomeContainer>
  );
};

export default HomePage;




