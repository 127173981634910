import React from 'react';
import { useNavigate } from 'react-router-dom';

const AdminPage2 = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    const goToAdminPage1 = () => {
        navigate('/admin-page1');
    };

    return (
        <div>
            <h1>Welcome to Admin Page 2</h1>
            <button onClick={goToAdminPage1}>Go to Admin Page 1</button>
            <button onClick={handleLogout}>Logout</button>
        </div>
    );
};

export default AdminPage2;
