import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import Modal from '../Modal';
import { useNavigate } from 'react-router-dom';

// Estilos
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: rgba(0, 0, 0, 0.9);
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  position: relative;
  text-align: center;
`;

const CloseIcon = styled(FaTimes)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
`;

const ModalButton = styled.button`
  background: #007bff;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  width: 60%;
  margin-top: 10px;

  &:hover {
    background: #0056b3;
  }
`;

const InputField = styled.input`
  background: #444;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    background: #555;
  }
`;

const Form = styled.form``;

// Componente de modal para verificación de código
const VerificationModal = ({ isOpen, handleClose, handleSubmit, handleResend }) => {
  const [code, setCode] = useState('');

  if (!isOpen) return null;

  return (
    <ModalBackground>
      <ModalContent>
        <CloseIcon onClick={handleClose} />
        <Form onSubmit={(e) => handleSubmit(e, code)}>
          <h2>Verificación de Código</h2>
          <InputField
            type="text"
            name="code"
            placeholder="Ingrese su código de desbloqueo"
            onChange={(e) => setCode(e.target.value)}
            required
          />
          <ModalButton type="submit">Verificar</ModalButton>
          <ModalButton type="button" onClick={handleResend}>
            Reenviar Código
          </ModalButton>
        </Form>
      </ModalContent>
    </ModalBackground>
  );
};

// Componente de login actualizado
const Login = ({ isOpen, handleClose }) => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('/api/auth/login', formData);
      localStorage.setItem('token', res.data.token);
      setMessage('Inicio de sesión exitoso');
      setShowModal(true);
      navigate('/bienvenida'); //te lleva a la pagina de bienvenida si sos nuevo sino te redirige a userhome
    } catch (err) {
      if (err.response.data.message.includes('Cuenta BLOQUEAD')) {
        setIsBlocked(true);
        setVerificationModalOpen(true);
      } else {
        setMessage(err.response.data.message);
        setShowModal(true);
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleVerificationSubmit = async (e, code) => {
    e.preventDefault();
    try {
      const res = await axios.post('/api/auth/verifyDesbloqueoCode', { email: formData.email, code });
      setMessage(res.data.message);
      setVerificationModalOpen(false);
      setShowModal(true);
    } catch (err) {
      setMessage(err.response.data.message);
      setShowModal(true);
    }
  };

  const handleResendCode = async () => {
    try {
      const res = await axios.post('/api/auth/newDesbloqueoCode', { email: formData.email });
      setMessage(res.data.message);
      setShowModal(true);
    } catch (err) {
      setMessage(err.response.data.message);
      setShowModal(true);
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <ModalBackground>
        <ModalContent>
          <CloseIcon onClick={handleClose} />
          <Form onSubmit={handleSubmit}>
            <h2>Iniciar Sesión</h2>
            <InputField
              type="email"
              name="email"
              placeholder="Correo Electrónico"
              onChange={handleChange}
              required
            />
            <InputField
              type="password"
              name="password"
              placeholder="Contraseña"
              onChange={handleChange}
              required
            />
            <ModalButton type="submit">Iniciar</ModalButton>
          </Form>
        </ModalContent>
      </ModalBackground>

      {showModal && <Modal message={message} onClose={closeModal} />}
      <VerificationModal
        isOpen={verificationModalOpen}
        handleClose={() => setVerificationModalOpen(false)}
        handleSubmit={handleVerificationSubmit}
        handleResend={handleResendCode}
      />
    </>
  );
};

export default Login;

