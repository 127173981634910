import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';
import { FaBars, FaTwitter, FaInstagram, FaLinkedin, FaShoppingCart, FaGraduationCap, FaRegListAlt, FaServicestack, FaInfoCircle, FaEnvelope,FaBook, FaEdit, FaCreditCard, FaCog, FaSignOutAlt } from 'react-icons/fa';
import { getProfile, postPlanVentas } from '../api';
import { handleRequestError } from './errorHandler';

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1c1c1c;
  color: #ffffff;
  overflow-x: hidden;
`;

const Header = styled.header`
  width: 100%;
  padding: 10px 20px;
  background-color: #1c1c1c;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ffffff;
  background-color: #1c1c1c;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  height: 40px;
`;

const Title = styled.h1`
  font-family: Arial, sans-serif;
  font-size: 24px;
  color: #ffffff;
  margin-left: 10px;
`;

const NavMenu = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const MenuButton = styled(FaBars)`
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 20px;
`;

const DropdownMenu = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  top: 0;
  right: 0;
  background-color: #1c1c1c;
  border: 1px solid #ffffff;
  border-radius: 5px;
  z-index: 1;
`;

const MenuItem = styled(Link)`
  display: block;
  padding: 10px 20px;
  color: #ffffff;
  text-decoration: none;

  &:hover {
    background-color: #333333;
        color: #007bff; /* Cambia el color del texto y del icono */

    svg { /* Aplica el color azul también a los iconos */
      color: #007bff;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;

  @media (min-width: 768px) {
    padding: 0;
  }
`;

const Footer = styled.footer`
  background-color: #1c1c1c;
  padding: 20px;
  text-align: center;
  color: #ffffff;
  width: 100%;
`;

const SocialIcons = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const SocialIcon = styled.a`
  color: #ffffff;
  font-size: 24px;
  text-decoration: none;
  &:hover {
    color: #007bff;
  }
`;

const InstitutoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin: 20px;
  margin-bottom: 20px;
`;

const InstitutoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  padding: 20px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const InstitutoTitle = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
`;

const InstitutoImage = styled.img`
  max-width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 10px;
`;

const InstitutoDescription = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
  text-align: center;
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-left: 5px;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: column;
  }
`;

const PriceInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: column;
  }
`;

const StrikethroughText = styled.span`
  text-decoration: line-through;
  color: #ff6b6b;
  font-size: 1.5em;
  margin-right: 10px;
`;


const RegularText = styled.span`
  color: #ffffff;
  font-size: 2em;
  font-weight: bold;
`;

const SavingsText = styled.div`
  color: #4caf50;
  font-size: 1.2em;
  margin-top: 10px;

  @media (min-width: 768px) {
    margin-top: 0;
    margin-left: 10px;
  }
`;

const SavingsTextMonthly = styled.div`
  color: #D4A017;
  font-size: 1.2em;
  margin-top: 10px;

  @media (min-width: 768px) {
    margin-top: 0;
    margin-left: 10px;
  }
`;

const LimitedTimeText = styled.span`
  color: #D4A017;
  font-size: 0.9em;
  margin-left: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Button = styled(Link)`
  background: ${({ isPrice }) => (isPrice ? '#28a745' : '#007bff')};
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  min-width: 100px;
`;

const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  border-bottom: 1px solid #ffffff;
`;

const Tab = styled.div`
  background: ${({ selected }) => (selected ? '#ff8c00' : '#f4a460')};
  color: #fff;
  padding: 10px 15px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  transition: background-color 0.3s;

  &.mensual {
    background: ${({ selected }) => (selected ? '#3B3B3B' : '#3B3B3B')};
  }

  &.anual {
    background: ${({ selected }) => (selected ? '#211F1F' : '#211F1F')};
  }
    &.personalizado {
    background: ${({ selected }) => (selected ? '#1c1c1c' : '#1c1c1c')};
  }
`;

const TabContent = styled.div`
  padding: 20px;
  background-color: ${({ tabColor }) => tabColor};
  border-radius: 0 0 10px 10px;
  margin-top: -1px; // Ajustado para eliminar el espacio entre tabs y contenido
`;

const Disclaimer = styled.p`
  font-size: 12px;
  color: #ffffff;
  margin-top: 10px;
  text-align: center;
`;

const PlanesComerciales = ({ instituto }) => {
  const [institutos, setInstitutos] = useState([]);
  const [profile, setProfile] = useState({});
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState('mensual');
  const navigate = useNavigate();

  const getTabColor = (tab) => {
    switch (tab) {
      case 'mensual':
        return '#3B3B3B'; //#545080
      case 'anual':
        return '#211F1F'; //#374639
      case 'personalizado':
        return '#1c1c1c';
      case 'cupon':
        return '#856b7c';
      default:
        return '#333333';
    }
  };

  useEffect(() => {
    const fetchInstitutos = async () => {
      try {
        const InstitutoResponse = await postPlanVentas();
        setInstitutos(InstitutoResponse.data);
      } catch (error) {
        handleRequestError(error, navigate);
        console.error('Error al obtener los datos del instituto:', error);
      }
    };

    fetchInstitutos();
  }, []);

  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setMenuOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setMenuOpen(false);
    }, 200);
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getProfile();
        setProfile(response.data);
      } catch (error) {
        console.log('Error al obtener el perfil:', error);
      }
    };

    fetchProfile();
}, []);

  if (!institutos) {
    return <p>Cargando...</p>;
  }



  const handleLogout = () => {
    localStorage.removeItem('token');
    setTimeout(() => {
      navigate('/');
    }, 100);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <HomeContainer>
      <Header>
        <LogoContainer>
          <Logo src="/images/logo.svg" alt="Logo" />
          <Title>estudiochat</Title>
        </LogoContainer>
        <NavMenu
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        >
          <DropdownMenu isOpen={menuOpen}>
          <DropdownHeader>
          <ImageContainer>
          {profile && profile.imagen ? (
            <img src={`/imPerf/${profile.imagen}`} alt="Perfil" style={{ maxHeight: '70px', maxWidth: '100%' }} onError={(e) => { e.target.onerror = null; e.target.src="/images/logo.svg"; }} />
            ) : (
            <Logo src="/images/logo.svg" alt="Logo" height="20px" />
            )}
        </ImageContainer>
              <Title style={{ fontSize: '16px', margin: '0 0 0 10px' }}>{profile.name}</Title>
            </DropdownHeader>
            <MenuItem to="/institutos"><FaGraduationCap style={{ marginRight: '8px' }} />Institutos</MenuItem>
            <MenuItem to="/planes-comerciales"><FaRegListAlt style={{ marginRight: '8px' }} />Nuestros Planes</MenuItem>
            <MenuItem to="/servicio"><FaServicestack style={{ marginRight: '8px' }} />Servicios</MenuItem>
            <MenuItem to="/quienes-somos"><FaInfoCircle style={{ marginRight: '8px' }} />Quienes Somos</MenuItem>
            <MenuItem to="/contacto"><FaEnvelope style={{ marginRight: '8px' }} />Contacto</MenuItem>
            {(profile.courses && profile.courses.length > 0) && <MenuItem to="/mis-cursos"><FaBook style={{ marginRight: '8px' }} />Mis Cursos</MenuItem>}
            {(profile && profile.isVerified === true) && <MenuItem to="/editar-perfil"><FaEdit style={{ marginRight: '8px' }} />Editar Perfil</MenuItem>}
            {(profile.role === 'supadm' || profile.role === 'admin') && <MenuItem to="/panel-pago"><FaCreditCard style={{ marginRight: '8px' }} />Panel Pago</MenuItem>}
            {(profile.role === 'supadm' || profile.role === 'admin') && <MenuItem to="/admin-home"><FaCog style={{ marginRight: '8px' }} />Configuracion</MenuItem>}
            {(profile && profile.sessionToken !== null) && <MenuItem onClick={handleLogout}><FaSignOutAlt style={{ marginRight: '8px' }} />Salir</MenuItem>}
            <MenuItem to="/info-plan">Nuestros Planes</MenuItem>
          </DropdownMenu>
          <MenuButton onClick={() => setMenuOpen(!menuOpen)} />
        </NavMenu>
      </Header>
      <MainContent>
      <InstitutoGrid>
      {institutos.map(instituto => (
        <InstitutoContainer>
          <InstitutoTitle>{instituto.nombre}</InstitutoTitle>
          <Link to={`/descripcion-comercial/${instituto._id}`}>
          <InstitutoImage
            src={`/arPV/${instituto.imagen}`}
            alt={instituto.nombre}
          /></Link>
          <InstitutoDescription>{instituto.promocion}</InstitutoDescription>
          <TabsContainer>
                  {instituto.mensual > 0 && (
                    <Tab
                      className="mensual"
                      selected={selectedTab === 'mensual'}
                      onClick={() => handleTabChange('mensual')}
                    >
                      Mensualidad
                    </Tab>
                  )}
                  {instituto.anual > 0 && (
                    <Tab
                      className="anual"
                      selected={selectedTab === 'anual'}
                      onClick={() => handleTabChange('anual')}
                    >
                      Anualidad
                    </Tab>
                  )}
                    {instituto.anual === 0 && (
                    <Tab
                        className="personalizado"
                        selected
                        disabled
                    >
                        Plan Personalizado
                    </Tab>
                    )}
                </TabsContainer>
                <TabContent tabColor={getTabColor(selectedTab)}>
                  <PriceContainer>
                    {selectedTab === 'mensual' && instituto.mensual > 0 && (
                      <>
                        <PriceInfo>
                          <RegularText>${instituto.mensual.toFixed(0)}</RegularText>
                          {instituto.bonifcacionAD > 0 && (
                            <>
                              <StrikethroughText>${instituto.mensualSD.toFixed(0)}</StrikethroughText>
                              <SavingsTextMonthly>Ahorras ${instituto.ahorroMensual.toFixed(0)}</SavingsTextMonthly>
                              <LimitedTimeText>por tiempo limitado</LimitedTimeText>
                            </>
                          )}
                        </PriceInfo>
                        <ButtonContainer>
                          <Button to= {`/info-plan/${instituto._id}`}>Más Info</Button>
                          <Button to={`/purchase/${instituto._id}`} isPrice>
                            <FaShoppingCart /> Comprar
                          </Button>
                        </ButtonContainer>
                        <Disclaimer>
                          El precio es en pesos uruguayos, incluye solo los componentes fijos mensuales, el consumo variable es a demanda vea <Link to={`/info-plan/${instituto._id}`} style={{ color: '#ffffff' }}>mas información</Link>. Revise nuestras <Link to="/bases-y-condiciones" style={{ color: '#ffffff' }}>bases y condiciones</Link>.
                        </Disclaimer>
                      </>
                    )}
                    {selectedTab === 'anual' && instituto.anual > 0 && (
                      <>
                        <PriceInfo>
                          <RegularText>${instituto.anual.toFixed(0)}</RegularText>
                          {instituto.ahorroAnual > 0 && (
                            <>
                              <StrikethroughText>${instituto.anualSD.toFixed(0)}</StrikethroughText>
                              <SavingsText>Ahorras ${instituto.ahorroAnual.toFixed(0)}</SavingsText>
                            </>
                          )}
                        </PriceInfo>
                        <ButtonContainer>
                          <Button to= {`/info-plan/${instituto._id}`}>Más Info</Button>
                          <Button to={`/purchase/${instituto._id}`} isPrice>
                            <FaShoppingCart /> Comprar
                          </Button>
                        </ButtonContainer>
                        <Disclaimer>
                          El precio es en pesos uruguayos, incluye solo los componentes fijos de los 12 meses del año, el consumo variable vea <Link to={`/info-plan/${instituto._id}`} style={{ color: '#ffffff' }}>mas información</Link>. Revise nuestras <Link to="/bases-y-condiciones" style={{ color: '#ffffff' }}>bases y condiciones</Link>.
                        </Disclaimer>
                      </>
                    )}
                    {instituto.anual === 0 && (
                        <>
                        <ButtonContainer>
                            <Button to={`/info-plan/${instituto._id}`}>Más Info</Button>
                            <Button to={`/purchase/${instituto._id}`} isPrice>
                            <FaShoppingCart /> Comprar
                            </Button>
                        </ButtonContainer>
                        <Disclaimer>
                            Revise nuestras <Link to="/bases-y-condiciones" style={{ color: '#ffffff' }}>bases y condiciones</Link>.
                        </Disclaimer>
                        </>
                    )}
                  </PriceContainer>
                </TabContent>
        </InstitutoContainer>
        ))}
    </InstitutoGrid>
      </MainContent>
      <Footer>
        <p>&copy; 2024 Todos los derechos reservados a <a href="https://www.labordt.com">Labor D&T</a></p>
        <SocialIcons>
          <SocialIcon href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></SocialIcon>
          <SocialIcon href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></SocialIcon>
          <SocialIcon href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></SocialIcon>
        </SocialIcons>
      </Footer>
    </HomeContainer>
  );
};

export default PlanesComerciales;
