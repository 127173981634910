import { useNavigate, useParams, Link } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { FaBars, FaTwitter, FaInstagram, FaLinkedin, FaWhatsapp, FaGraduationCap, FaRegListAlt, FaServicestack, FaInfoCircle, FaEnvelope, FaBook, FaEdit, FaCreditCard, FaCog, FaSignOutAlt, FaComments, FaVideo, FaQuestionCircle, FaFilePdf, FaLink, FaShoppingCart, FaUserPlus, FaTicketAlt} from 'react-icons/fa';
import { CoursePorId, InstitutosByUser, UserDetailsById, generateCouponCode, verifyCouponCode, solicitudProfe, getProfile } from '../api';
import CouponModal from '../components/cuponModal';

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1c1c1c;
  color: #ffffff;
  overflow-x: hidden;
`;

const Header = styled.header`
  width: 100%;
  padding: 10px 20px;
  background-color: #1c1c1c;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  height: 40px;
`;

const Title = styled.h1`
  font-family: Arial, sans-serif;
  font-size: 24px;
  color: #ffffff;
  margin-left: 10px;
  margin-right: 40px;
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
`;

const ContentSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const MainText = styled.h1`
  font-family: Arial, sans-serif;
  font-size: 32px;
  margin-bottom: 10px; /* Reducimos el margen inferior */
  margin-top: 0; /* Ajustamos el margen superior */
  text-align: center; /* Centramos el texto */
  
  // Media query para pantallas grandes
  @media (min-width: 768px) {
    margin-bottom: 20px; /* Ajustamos el margen inferior en pantallas grandes */
  }


`;

const PromotionText = styled.p`
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: #ffff; /* Color rojo para la promoción */
`;

const DescriptionText = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
  text-align: justify;
  color: #ffffff;
  line-height: 1.5; /* Aumentamos el interlineado */
  margin-top: 20px; /* Añadimos margen superior */
  p {
    margin-bottom: 15px; /* Espaciado entre párrafos */
  }
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const LinkText = styled(Link)`
  color: #007bff;
  text-decoration: none;
`;

const Footer = styled.footer`
  background-color: #1c1c1c;
  padding: 20px;
  text-align: center;
  color: #ffffff;
`;

const SocialIcons = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const SocialIcon = styled.a`
  color: #ffffff;
  font-size: 24px;
  text-decoration: none;
  &:hover {
    color: #007bff;
  }
`;

const MateriasTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #ffffff;
`;

const VideoWrapper = styled.div`
  width: 90%;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    width: 75%;
    margin-bottom: 0;
    padding: 0 20px;
  }
`;

const VideoContainer = styled.video`
  width: 100%;
  max-height: 1200px;
  border-radius: 10px;
`;

const ImagenContainer = styled.div`
  width: 100%;
  max-height: 800px;

  img {
    width: 75%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 5px;
    margin-left: 45px;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  @media (min-width: 768px) {
    width: 50%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-left: 5px;
`;

const ImageContainerr = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  margin-left: 3px;
`;

const NavMenu = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const MenuButton = styled(FaBars)`
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 20px;
`;

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ffffff;
  background-color: #1c1c1c;
`;

const DropdownMenu = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  top: 0;
  right: 0;
  background-color: #1c1c1c;
  border: 1px solid #ffffff;
  border-radius: 5px;
  z-index: 1;
`;

const MenuItem = styled(Link)`
  display: block;
  padding: 10px 20px;
  color: #ffffff;
  text-decoration: none;

  &:hover {
    background-color: #333333;
    color: #007bff;

    svg {
      color: #007bff;
    }
  }
`;


const Button = styled(Link)`
  background: ${({ isPrice }) => (isPrice ? '#28a745' : '#007bff')};
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  min-width: 100px;
`;

const TabContent = styled.div`
  padding: 20px;
  background-color: ${({ tabColor }) => tabColor};
  border-radius: 0 0 10px 10px;
  margin-top: -1px; // Ajustado para eliminar el espacio entre tabs y contenido


`;

const TabsContainer = styled.div`
  display: flex;
  justify-content: space-between; // Distribuye las pestañas uniformemente center;
  gap: 10px;
  margin-top: 20px;
  border-bottom: 1px solid #ffffff;
  margin-bottom: 0; // Ajustado para que no haya margen inferior
    // Asegura que el contenedor de las pestañas ocupe todo el ancho del CourseCard
   box-sizing: border-box; // Incluye el padding y el border en el ancho total


  }
`;

const Tab = styled.div`
  background: ${({ selected }) => (selected ? '#ff8c00' : '#f4a460')};
  color: #fff;
  padding: 10px 15px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  transition: background-color 0.3s;
  flex: 1; // Hace que cada tab ocupe un espacio igual
  font-size: 14px; // Ajusta el tamaño de la letra aquí

  &.cuota1 {
    background: ${({ selected }) => (selected ? '#29312F' : '#777b75')};
  }

  &.cuota2 {
    background: ${({ selected }) => (selected ? '#5817C2' : '#777b75')};
  }

  &.cuota4 {
    background: ${({ selected }) => (selected ? '#C0162E' : '#777b75')};
  }

  &.cupon {
    background: ${({ selected }) => (selected ? '#0F947A' : '#777b75')};
  }
`;

const CourseCard = styled.div`
  background-color: #333333;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 80%; // Cambiado de 30% a 40% para mayor ancho
  max-width: 600px; // Aumentado el max-width para más flexibilidad
  background-color: #f9f9f9;
  margin-right: 2px; //ajustar para que se vea bien en la pantalla delc elular
  margin-left: 0px;

  @media (max-width: 1200px) {
    width: 45%;
  }

  @media (max-width: 768px) {
    width: 87%;
  }
`;

const ContentSectionn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
  }
`;

const Descripcion = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
  text-align: center;
`;

const PrecioTachado = styled.span`
  text-decoration: line-through;
  color: #ff6b6b; /* Color rojo para el precio tachado */
  font-size: 1.2em;
  margin-right: 10px;
`;

const Precio = styled.span`
  color: #333333; /* Color blanco para el precio regular */
  font-size: 1.5em;
  font-weight: bold;
`;

const Descuento = styled.div`
  color: #4caf50; /* Color verde para el ahorro */
  font-size: 1.2em;
  margin-top: 10px;

  @media (min-width: 768px) {
    margin-top: 0;
    margin-left: 10px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const MateriasList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  column-count: 1;
  column-gap: 20px;

  @media (min-width: 768px) {
    column-count: 2;
  }

  li {
    font-weight: bold;
    color: #ffbf00;
    margin-bottom: 10px;

    &::before {
      content: '• ';
      color: #ffbf00;
      font-weight: bold;
    }
  }
`;

// Contenedor para centrar los botones
const ButtonnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;  // Para ocupar todo el ancho disponible
  padding: 20px;  // Opcional, para añadir espacio alrededor del contenedor
`;

const StyledButton = styled.button`
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f0f0f0;
  cursor: pointer;
  &:hover {
    background-color: #e0e0e0;
  }

  // Media query para pantallas grandes
  @media (min-width: 768px) {
    width: ${({ size }) => `${size * 1.5}px`};  // Tamaño 50% más grande en pantallas grandes
    height: ${({ size }) => `${size * 1.5}px`};  // Tamaño 50% más grande en pantallas grandes
  }
`;

const Modall = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const ModallContent = styled.div`
  background-color: #333333;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  text-align: center;
`;

const CloseButtonn = styled.button`
  background: #007bff;
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
`;

const Modal = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const ModalContent = styled.div`
  background-color: #333333;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  text-align: center;
`;

const CloseButton = styled.button`
  background: #007bff;
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
`;

const ContactoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const ContactoLink = styled.a`
  display: flex;
  align-items: center;
  color: #25d366;
  text-decoration: none;
  font-size: 16px;
  margin-left: 5px;
`;

const Disclaimer = styled.p`
  font-size: 12px;
  color: #ffffff;
  margin-top: 10px;
  text-align: center;
`;

const UserList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const UserItem = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
padding: 10px;
background-color: #333;
margin-bottom: 10px;
border-radius: 5px;
flex-wrap: nowrap; /* Asegura que los elementos no se envuelvan */

@media (min-width: 768px) {
  padding: 15px;
}
`;



const UserInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const UserDetail = styled.div`
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 5px;
`;

const SeparatedContainer = styled.div`
  //margin: 20px 0;  // Añade un margen de 20px arriba y abajo para la separación
    margin-bottom: 20px;  // Añade un margen de 20px solo en la parte inferior
  display: flex;
  justify-content: center;  // Centra el contenido horizontalmente
`;

const SeparatedContainerr = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px; /* Reducimos el padding en pantallas pequeñas */
  
  // Media query para pantallas grandes
  @media (min-width: 768px) {
    padding: 20px; /* Ajustamos el padding en pantallas grandes */
  }
`;



const IconLegend = ({ icon: IconComponent, isActive, legend, onClick, size = 32, title }) => (
    <StyledButton isActive={isActive} onClick={onClick} size={44}>
      <IconComponent size={30} />
      <span>{legend}</span>
    </StyledButton>
  );


  const DataCurso = () => {
    const timeoutRef = useRef(null);
    const [curso, setCurso] = useState({});
    const [instituto, setInstituto] = useState({});
    const [menuOpen, setMenuOpen] = useState(false);
    const [profile, setProfile] = useState({});
    const [profesor, setProfe] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [selectedTab, setSelectedTab] = useState('cuota1');
    const [isCuponModalOpen, setIsCuponModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [solicitudEnviada, setSolicitudEnviada] = useState(false);
    const [isSolicitudVisible, setIsSolicitudVisible] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams(); // Obtén el ID desde los parámetros de la URL
  
    const handleMouseEnter = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setMenuOpen(true);
    };
  
    const handleMouseLeave = () => {
      timeoutRef.current = setTimeout(() => {
        setMenuOpen(false);
      }, 200);
    };
  
    const handleLogout = () => {
      localStorage.removeItem('token');
      setTimeout(() => {
        navigate('/');
      }, 100);
    };
  
    useEffect(() => {
      const fetchProfile = async () => {
        try {
          const response = await getProfile();
          setProfile(response.data);
        } catch (error) {
          console.log('Error al obtener el perfil:', error);
        }
      };
  
      fetchProfile();
    }, []);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const result = await CoursePorId(id);
          setCurso(result.data);
        } catch (error) {
          console.error('Error al recuperar datos del curso:', error);
        }
      };
      if (id) {
        fetchData();
      }
    }, [id]);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const result = await InstitutosByUser(curso.institutoId);
          setInstituto(result.data);
        } catch (error) {
          console.error('Error al recuperar datos de instituto:', error);
        }
      };
      if (curso.institutoId) {
        fetchData();
      }
    }, [curso]);
  
    useEffect(() => {
      const fetchProfe = async () => {
        if (curso && Array.isArray(curso.profeId) && curso.profeId.length > 0) {
          try {
            const profes = await Promise.all(curso.profeId.map(async (id) => {
              if (id) {
                const response = await UserDetailsById(id);
                return response.data;
              } else {
                console.warn('Profe ID is undefined or null');
                return null;
              }
            }));
            setProfe(profes.filter(profe => profe !== null));  // Filtrar nulos
          } catch (error) {
            console.error('Error al recuperar los profesores:', error);
          }
        }
      };
  
      fetchProfe();
    }, [curso]);
  
    const handleTabChange = (tab) => {
      setSelectedTab(tab);
    };
  
    const getPrice = (curso, tab) => {
      if (!curso.prices) {
        return 0;
      }
      switch (tab) {
        case 'cuota1':
          return ((curso.prices?.mensual || 0) * (1 - (curso.descuento / 100))).toFixed(0);
        case 'cuota2':
          return ((curso.prices?.bimensual || 0) * (1 - (curso.descuento / 100))).toFixed(0);
        case 'cuota4':
          return ((curso.prices?.cuatrimestral || 0) * (1 - (curso.descuento / 100))).toFixed(0);
        case 'cupon':
          return '';
        default:
          return 0;
      }
    };
  
    const getPriceSD = (curso, tab) => {
      if (!curso.prices) {
        return 0;
      }
      switch (tab) {
        case 'cuota1':
          return curso.prices?.mensual || 0;
        case 'cuota2':
          return curso.prices?.bimensual || 0;
        case 'cuota4':
          return curso.prices?.cuatrimestral || 0;
        case 'cupon':
          return '';
        default:
          return 0;
      }
    };
  
    const getCuota = (tab) => { //usar para asignar los nombres de las etiquetas tab por si se quieren cambiar
      switch (tab) {
        case 'cuota1':
          return 'Cuotas de:';
        case 'cuota2':
          return 'Cuotas de:';
        case 'cuota4':
          return 'Todo el Curso:';
        case 'cupon':
          return '';
        default:
          return '';
      }
    };
  
    const getDto = (course, tab) => {
      if (!course.prices || !course.descuento) {
        return 0;
      }
      switch (tab) {
        case 'cuota1':
          return (course.prices?.mensual * (course.descuento / 100)).toFixed(0);
        case 'cuota2':
          return (course.prices?.bimensual * (course.descuento / 100)).toFixed(0);
        case 'cuota4':
          return (course.prices?.cuatrimestral * (course.descuento / 100)).toFixed(0);
        case 'cupon':
          return '';
        default:
          return 0;
      }
    };
  
    const getTabColor = (tab) => {
      switch (tab) {
        case 'cuota1':
          return '#29312F'; //#6f856b
        case 'cuota2':
          return '#5817C2'; //#6b7f85
        case 'cuota4':
          return '#C0162E'; //#6d6b85
        case 'cupon':
          return '#0F947A'; //#856b7c
        default:
          return '#333333';
      }
    };
  
    const openModal = (content) => {
      setModalContent(content);
      setIsOpen(true);
    };
  
    const closeModal = () => {
      setIsOpen(false);
    };
  
    const handleGenerateCoupon = async (curso) => {
      if(profile) {
        try {
          const response = await generateCouponCode({ userId: profile._id, courseId: curso._id });
          setModalMessage(response.data.message);
          setSelectedCourse(curso);
          setIsCuponModalOpen(true);
        } catch (error) {
          console.log('Error al generar el código de cupón:', error);
          setModalMessage('No hay cupones disponibles para esta cuenta.');
          setIsCuponModalOpen(true);
        }
      } else {
        alert("Debes de iniciar sesión primero para poder realizar esta acción");
      }
    };
  
    const handleVerifyCoupon = async (code) => {
      try {
        const response = await verifyCouponCode({ userId: profile._id, code, courseId: selectedCourse._id });
        alert(response.data.message);
        closeCuponModal();
      } catch (error) {
        console.log('Error al verificar el código de cupón:', error);
        alert('Código de verificación inválido o caducado.');
      }
    };
  
    const closeCuponModal = () => {
      setIsCuponModalOpen(false);
      setSelectedCourse(null);
    };
  
    const handleContactoClick = (e) => {
      e.stopPropagation(); // Evita que el clic en el enlace de contacto active el enlace principal
    };
  
    useEffect(() => {
      if (instituto && instituto.solicitudprofe && profile) {
          // Verifica si el profile._id ya está en la matriz solicitudprofe
          if (instituto.solicitudprofe.includes(profile._id)) {
              setSolicitudEnviada(true);
              setIsSolicitudVisible(false);
          }
          if (instituto.administradores.includes(profile._id)) {
            setSolicitudEnviada(true);
            setIsSolicitudVisible(false);
          }
          if(!profile) {
            alert("Debes de iniciar sesión primero para poder realizar esta acción");
          }
      }
    }, [instituto, profile]);
  
    const handleSolicitud = async () => {
      try {
        const response = await solicitudProfe(instituto._id, profile._id);
        alert(response.data.message);
        setSolicitudEnviada(true);
        setIsSolicitudVisible(false);
      } catch (error) {
        console.log('Error al enviar la solicitud de profesor:', error);
        alert('Error al enviar la solicitud de profesor. Inténtalo de nuevo más tarde.');
      }
    };

    const precioPrivado = (coursePrecio) => {
        return !profile ? !coursePrecio : coursePrecio;
      };
  
    return (
      <HomeContainer>
        <Header>
          <LogoContainer>
            {instituto && instituto.imagen ? (
              <img src={`/imInst/${instituto.imagen}`} alt="Perfil" style={{ maxHeight: '70px', maxWidth: '100%' }} onError={(e) => { e.target.onerror = null; e.target.src="/images/logo.svg"; }} />
            ) : (
              <Logo src="/images/logo.svg" alt="Logo" height="70px" />
            )}
            {instituto && instituto.nombre ? (
              <Title style={{ fontSize: '26px', margin: '0 0 0 10px' }}>{instituto.nombre}</Title>
            ) : (
              <Title>estudiochat</Title>
            )}
          </LogoContainer>
          <NavMenu
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <DropdownMenu isOpen={menuOpen}>
              <DropdownHeader>
                <ImageContainer>
                  {profile && profile.imagen ? (
                    <img src={`/imPerf/${profile.imagen}`} alt="Perfil" style={{ maxHeight: '70px', maxWidth: '100%' }} onError={(e) => { e.target.onerror = null; e.target.src="/images/logo.svg"; }} />
                  ) : (
                    <Logo src="/images/logo.svg" alt="Logo" height="20px" />
                  )}
                </ImageContainer>
                <Title style={{ fontSize: '16px', margin: '0 0 0 10px' }}>{profile.name}</Title>
              </DropdownHeader>
              <MenuItem to="/institutos"><FaGraduationCap style={{ marginRight: '8px' }} />Institutos</MenuItem>
              <MenuItem to="/planes-comerciales"><FaRegListAlt style={{ marginRight: '8px' }} />Nuestros Planes</MenuItem>
              <MenuItem to="/servicio"><FaServicestack style={{ marginRight: '8px' }} />Servicios</MenuItem>
              <MenuItem to="/quienes-somos"><FaInfoCircle style={{ marginRight: '8px' }} />Quienes Somos</MenuItem>
              <MenuItem to="/contacto"><FaEnvelope style={{ marginRight: '8px' }} />Contacto</MenuItem>
              {(profile.courses && profile.courses.length > 0) && <MenuItem to="/mis-cursos"><FaBook style={{ marginRight: '8px' }} />Mis Cursos</MenuItem>}
              {(profile && profile.isVerified === true) && <MenuItem to="/editar-perfil"><FaEdit style={{ marginRight: '8px' }} />Editar Perfil</MenuItem>}
              {(profile && profile.isVerified === true) && <MenuItem to="/panel-pago"><FaCreditCard style={{ marginRight: '8px' }} />Panel Pago</MenuItem>}
              {(profile && profile.isVerified && isSolicitudVisible) && (
                  <MenuItem onClick={handleSolicitud}>
                      <FaUserPlus style={{ marginRight: '8px' }} />
                      {solicitudEnviada ? 'Solicitud Enviada' : 'Solicitud Profe'}
                  </MenuItem>
              )}
              {(profile.role === 'supadm' || profile.role === 'admin') && <MenuItem to="/admin-home"><FaCog style={{ marginRight: '8px' }} />Configuracion</MenuItem>}
              {(profile && profile.sessionToken !== null) && <MenuItem onClick={handleLogout}><FaSignOutAlt style={{ marginRight: '8px' }} />Salir</MenuItem>}
              <MenuItem to="/info-plan">Nuestros Planes</MenuItem>
            </DropdownMenu>
            <MenuButton onClick={() => setMenuOpen(!menuOpen)} />
          </NavMenu>
        </Header>
        <MainContent>
        <>
        <SeparatedContainerr>
        {curso.courseName && <MainText>{curso.courseName}</MainText>}
        {curso.introduction && <PromotionText>{curso.introduction}</PromotionText>}
        </SeparatedContainerr>
          <ContentSectionn>
            <VideoWrapper>
              {curso.video ? (
                <VideoContainer controls autoPlay controlsList="nodownload">
                  <source src={`/videoCurso/${curso.video}`} type="video/mp4" />
                  Su navegador no soporta este tipo de videos.
                </VideoContainer>
              ) : (
                <ImagenContainer>
                  <img src={`/imCurso/${curso.imagen}`} alt="Curso" />
                </ImagenContainer>
              )}
            </VideoWrapper>
            <TextWrapper>
                <SeparatedContainer>
              {instituto.contacto && (
                <ContactoContainer>
                  <FaWhatsapp size={24} color="#25D366" />
                  <ContactoLink href={`https://wa.me/${instituto.contacto}`} target="_blank" rel="noopener noreferrer">
                    {instituto.contacto}
                  </ContactoLink>
                </ContactoContainer>
              )}
              </SeparatedContainer>
            <CourseCard>
            {curso.prices && selectedTab !== 'cupon' && curso.checkpoints.mostrar === true && !precioPrivado(curso.precio) && <Precio>{getCuota(selectedTab)}${getPrice(curso, selectedTab)}</Precio>}
            {curso.descuento > 0 && curso.checkpoints.mostrar === true && !precioPrivado(curso.precio) && selectedTab !== 'cupon' && (
              <>
                <div style={{  display: 'flex', alignItems: 'baseline', justifyContent: 'center'}}>
                  <h3 style={{ color: '#333333', margin: '0 10px 0 0' }}>Antes: </h3>
                  <PrecioTachado>${getPriceSD(curso, selectedTab)}</PrecioTachado>
                  <Descuento>Ahorras: ${getDto(curso, selectedTab)}</Descuento>
                </div>
              </>
            )}
            {selectedTab === 'cupon' && (
              <h3 style={{ color: '#333333', margin: '0 10px 0 0' }}>El cupon no presenta cargo</h3>
            )}
            <TabsContainer>
              {curso.prices?.mensual > 0 && curso.checkpoints.mostrar === true && !precioPrivado(curso.precio) && (
                <Tab
                  className="cuota1"
                  selected={selectedTab === 'cuota1'}
                  onClick={(e) => handleTabChange('cuota1', e)}
                  title='Selecciona la forma de pago en cuotas mensual, bimensual, total'
                >
                  Cuota
                </Tab>
              )}
              {curso.prices?.bimensual > 0 && curso.checkpoints.mostrar === true && !precioPrivado(curso.precio) && (
                <Tab
                  className="cuota2"
                  selected={selectedTab === 'cuota2'}
                  onClick={(e) => handleTabChange('cuota2', e)}
                  title='Selecciona la forma de pago en cuotas, mensual, bimensual, total'
                >
                  Medio
                </Tab>
              )}
              {curso.prices?.cuatrimestral > 0 && curso.checkpoints.mostrar === true && !precioPrivado(curso.precio) && (
                <Tab
                  className="cuota4"
                  selected={selectedTab === 'cuota4'}
                  onClick={(e) => handleTabChange('cuota4', e)}
                  title='Selecciona la forma de pago en cuotas, mensual, bimensual, total'
                >
                  Todo
                </Tab>
              )}
              {curso.checkpoints?.cupon && (
                <Tab
                  className="cupon"
                  selected={selectedTab === 'cupon'}
                  onClick={(e) => handleTabChange('cupon', e)}
                  title='Selecciona para canjear tu cupón'
                >
                  <FaTicketAlt style={{ color: '#ffff', fontSize: '24px' }} />
                </Tab>
              )}
            </TabsContainer>
            <TabContent tabColor={getTabColor(selectedTab)}>
              <ButtonContainer onClick={handleContactoClick}>
                {selectedTab === 'cupon' ? (
                  <Button to="#" isPrice onClick={() => handleGenerateCoupon(curso)}>
                    <FaShoppingCart /> Obtener
                  </Button>
                ) : (
                    curso.checkpoints?.mostrar === true && !precioPrivado(curso.precio) && (
                  <Button to={`/comprar/${curso._id}`} isPrice>
                    <FaShoppingCart /> ${getPrice(curso, selectedTab)}
                  </Button>
                    )
                )}
              </ButtonContainer>
              {selectedTab === 'cuota1' && curso.prices?.bimensual > 0 && curso.prices?.cuatrimestral > 0 && curso.checkpoints.mostrar === true && !precioPrivado(curso.precio) && (
                <Disclaimer>
                  El costo incluye solo una cuota del curso, este curso completo consta de 4 cuotas. Consulte <Link to="/bases-y-condiciones" style={{ color: '#ffff' }}>bases y condiciones</Link>.
                </Disclaimer>
              )}
              {selectedTab === 'cuota1' && curso.prices?.bimensual > 0 && curso.prices?.cuatrimestral === 0 && curso.checkpoints.mostrar === true && !precioPrivado(curso.precio) && (
                <Disclaimer>
                  El costo incluye solo una cuota del curso, este curso completo consta de 2 cuotas. Consulte <Link to="/bases-y-condiciones" style={{ color: '#ffff' }}>bases y condiciones</Link>.
                </Disclaimer>
              )}
              {selectedTab === 'cuota1' && curso.prices?.bimensual === 0 && curso.checkpoints.mostrar === true && !precioPrivado(curso.precio) && (
                <Disclaimer>
                  El costo es por todo el curso completo. Consulte <Link to="/bases-y-condiciones" style={{ color: '#ffff' }}>bases y condiciones</Link>.
                </Disclaimer>
              )}
              {selectedTab === 'cuota2' && curso.prices?.cuatrimestral > 0 && curso.checkpoints.mostrar === true && !precioPrivado(curso.precio) && (
                <Disclaimer>
                  El costo incluye el primer módulo del curso, se deberan abonar dos cuotas como esta para completar el curso. Consulte <Link to="/bases-y-condiciones" style={{ color: '#ffff' }}>bases y condiciones</Link>.
                </Disclaimer>
              )}
              {selectedTab === 'cuota2' && curso.prices?.cuatrimestral === 0 && curso.checkpoints.mostrar === true && !precioPrivado(curso.precio) && (
                <Disclaimer>
                  El costo es por todo el curso completo. Consulte <Link to="/bases-y-condiciones" style={{ color: '#ffff' }}>bases y condiciones</Link>.
                </Disclaimer>
              )}
              {selectedTab === 'cuota4' && curso.checkpoints.mostrar === true && !precioPrivado(curso.precio) && (
                <Disclaimer>
                  El costo es por todo el curso completo. Consulte <Link to="/bases-y-condiciones" style={{ color: '#ffff' }}>bases y condiciones</Link>.
                </Disclaimer>
              )}
              {selectedTab === 'cupon' && (
                <Disclaimer>
                  Promoción válida para algunos usuarios, consulte <Link to="/bases-y-condiciones" style={{ color: '#ffff' }}>bases y condiciones</Link>.
                </Disclaimer>
              )}
            </TabContent>
            </CourseCard>
            </TextWrapper>
          </ContentSectionn>
        </>
        <>
        <ButtonnContainer>
          <IconContainer>
            <IconLegend
              icon={FaComments}
              isActive={curso.checkpoints?.chat}
              onClick={() => openModal('Tu mejor amigo a la hora de estudiar. Una IA entrenada para responderte sobre los temas especificos del curso')}
               title="Toca para ams info"
            />
            <IconLegend
              icon={FaVideo}
              isActive={curso.checkpoints?.video}
              onClick={() => openModal('Clases grabadas con todos los contenidos teóricos y prácticos que tienes que saber')}
               title="Toca para ams info"
            />
            <IconLegend
              icon={FaQuestionCircle}
              isActive={curso.checkpoints?.quiz}
              onClick={() => openModal('Bien dicen "la practica hace al maestro" Te bribdamos cuestionarios y pruebas para que puedas aplicar tus conocimientos y aprender en el proceso.')}
               title="Toca para ams info"
            />
            <IconLegend
              icon={FaFilePdf}
              isActive={curso.checkpoints?.pdf}
              onClick={() => openModal('Todos los materiales prácticos y teóricos que necesitas para realizar el curso')}
               title="Toca para ams info"
            />
            <IconLegend
              icon={FaLink}
              isActive={curso.checkpoints?.links}
              onClick={() => openModal('Enlaces útiles que puedes usar para seguir aprendiendo. Recopilados por los porfesores del curso, los mismos pueden servirte para profundizar temas,ver ejemplos reales entre tantas otras cosas.')}
              title="Toca para ams info"
            />
          </IconContainer>
        </ButtonnContainer>
          <Modall isOpen={isOpen}>
            <ModallContent>
              <h2>Información Adicional</h2>
              <p>{modalContent}</p>
              <CloseButtonn onClick={closeModal}>Cerrar</CloseButtonn>
            </ModallContent>
          </Modall>
        </>
        <TextWrapper>
        {curso.planprograma && (
          <>
            <MateriasTitle>Programa del Curso</MateriasTitle>
            <div>
            <MateriasList>
              {curso.planprograma.split('\n').map((programa, index) => (
                <li key={index}>{programa}</li>
              ))}
            </MateriasList>
            </div>
          </>
        )}
        {curso.information && (
        <>
        <MateriasTitle>Contenido:</MateriasTitle>
            <div> 
          <DescriptionText>
            {curso.information.split('\n').map((para, index) => (
              <p key={index}>{para}</p>
            ))}
          </DescriptionText>
          </div>
        </>
        )}
        </TextWrapper>
        <UserList>
          {profesor.length > 0 ? (
            profesor.map((profe, index) => (
              <UserItem key={index}>
                <ImageContainerr>
                  {profe && profe.imagen ? (
                    <img src={`/imPerf/${profe.imagen}`} alt="Perfil" style={{ maxHeight: '70px', maxWidth: '100%' }} onError={(e) => { e.target.onerror = null; e.target.src="/images/logo.svg"; }} />
                  ) : (
                    <Logo src="/images/logo.svg" alt="Logo" height="20px" />
                  )}
                </ImageContainerr>
                <UserInfo>
                  <div>
                    <UserDetail><strong>Prof:</strong> {profe.firstName} {profe.lastName}</UserDetail>
                    <Descripcion>{profe.descripcion}</Descripcion>
                  </div>
                </UserInfo>
              </UserItem>
            ))
          ) : (
            <p></p>
          )}
        </UserList>
        <Modal isOpen={isModalOpen}>
          <ModalContent>
            <h2>{selectedCourse?.courseName}</h2>
            <p>{selectedCourse?.information}</p>
            <CloseButton onClick={closeModal}>Cerrar</CloseButton>
          </ModalContent>
        </Modal>
        <CouponModal isOpen={isCuponModalOpen} onClose={closeCuponModal} onSubmit={handleVerifyCoupon} message={modalMessage} />
        </MainContent>
        <Footer>
          <p>&copy; 2024 Todos los derechos reservados a <LinkText to="https://www.labordt.com">Labor D&T</LinkText></p>
          <SocialIcons>
            <SocialIcon href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></SocialIcon>
            <SocialIcon href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></SocialIcon>
            <SocialIcon href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></SocialIcon>
          </SocialIcons>
        </Footer>
      </HomeContainer>
    );
  };
  
  export default DataCurso;