import React, { useEffect, useState }  from 'react';
import { useNavigate } from 'react-router-dom';
import { getProfile } from '../api';
//import io from 'socket.io-client';

const UserPage1 = () => {

//esta funcion es para mostrar los datos de usuario y mail al iniciar, nada tiene que ver con la configuracion necesaria para el 
//funcionamiento de un perfil de usuario registrado, eso pasa por las rutas del backend, el middlewere que controla si el token es valido
//a nivel de frontend, se controla con react-rute-dom quien redirecciona y permite moverse dentro de la web, en las paginas configuradas
//aquellas paginas configuradas que tienen control de contraseña de uso, se establece en la configuracion de react-route-dom 
//se utiliza con navigate(/pagina a donde ir) de esa manera se puede navegar en el frontend manteniendo una secion iniciada
//dado que el frontend mediante axios almacena en el navegador en localStore el token de navegacion que envia el backend al momento de loguearse
//luego ese token es levantado desde localStore para su uso en el frontend y para verificar que el usuario permanece logeado sino se sale a inicio
//al configurar axios se le permita a axios comunicarse con el backend mediante el servidor (en backend app.js) el servidor recibe las rutas y datos de axios
//por ende se usa axios para eso para comunicarse con el servidor del backend, por ejemplo la ruta auth/login es porque en el server (app.js) hay una 
//llinea que al recibir api/auth que la api es por la configuracion de axios (axios esta configurado en un archivo denominado api.js en el frontend)
//esa linea del servidor redirige la solicitud de axios al backend en este caso a la ruta o authRoute.js y esta ruta al authController
//que es el authController quien luego contesta la solicitud de la api ( de authControllers) respecto a la accion inicial del frontend
//e esta forma es que opera la comunicacion el uso de las rutas y el intercambio entre el front y el back con el uso de perfiles autentificados con contrasdeña

const [profile, setProfile] = useState({});
const navigate = useNavigate();
//const socket = io('http://localhost:3000'); //para usar WebSocket

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getProfile();
        setProfile(response.data);
      } catch (error) {
        console.log('Error al obtener el perfil:', error);
      }
    };

    fetchProfile();

    //socket.on('sessionInvalidated', (message) => {
       // alert(message);
        //handleLogout();
   // });

    //return () => {
        //socket.disconnect();
   // };
}, []);
  
//es para realizar el logout
    const handleLogout = () => {
        localStorage.removeItem('token'); //esto elimina el token que haia sido almacenado en localStore
        navigate('/');
    };

    const goToUserPage2 = () => {
        navigate('/super-admin');
    };

    const goToUserPage3 = () => {
        navigate('/crear-curso');
    };

    return (
        <div>
            <h1>Welcome to User Page 1</h1>
            <p>Nombre: {profile.name}</p>
            <p>Correo Electrónico: {profile.email}</p>
            <button onClick={goToUserPage2}>Super Admin</button>
            <button onClick={goToUserPage3}>Go to User Page 3</button>
            <button onClick={handleLogout}>Logout</button>
        </div>
    );
};

export default UserPage1;
