import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getConversations, createConversation } from '../api';
import { useNavigate } from 'react-router-dom';

const ListContainer = styled.div`
    width: 25%;
    border-right: 1px solid #ccc;
    padding: 10px;
    box-sizing: border-box;
    background-color: #2c2c2c;
    color: #ffffff;
    height: 100vh;
    overflow-y: auto;

    @media (max-width: 768px) {
        width: 100%;
        height: auto;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const Title = styled.h2`
    margin: 0;
`;

const NewButton = styled.button`
    background-color: #007bff;
    color: #ffffff;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
`;

const ConversationItem = styled.div`
    padding: 10px;
    margin-bottom: 10px;
    background-color: #3c3c3c;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #4c4c4c;
    }
`;

const ConversationList = ({ onSelect }) => {
    const [conversations, setConversations] = useState([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchConversations();
    }, []);

    /*const fetchConversations = async () => {
        try {
            const res = await getConversations();
            setConversations(res.data);
        } catch (error) {
            console.error('Error al obtener las conversaciones:', error);
        }
    };*/

    const fetchConversations = async () => {
        try {
            const res = await getConversations();
            console.log('Respuesta de getConversations:', res.data); // Log para depuración
    
            if (Array.isArray(res.data)) {
                setConversations(res.data);
                setError(null);
            } else {
                console.error('Respuesta inesperada de la API:', res.data);
                setError('No se pudieron cargar las conversaciones.');
                setConversations([]);
            }
        } catch (error) {
            console.error('Error al obtener las conversaciones:', error);
            setError('Error al cargar las conversaciones.');
            setConversations([]);
        }
    };

    const handleCreateConversation = async () => {
        const title = prompt('Ingresa el título de la nueva conversación:');
        if (title) {
            try {
                await createConversation(title);
                fetchConversations();
            } catch (error) {
                console.error('Error al crear la conversación:', error);
            }
        }
    };

    return (
        <ListContainer>
        <Header>
            <Title>Conversaciones</Title>
            <NewButton onClick={handleCreateConversation}>Nueva</NewButton>
        </Header>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {conversations.length === 0 && !error ? (
            <p>No tienes conversaciones. Crea una nueva para empezar.</p>
        ) : (
            conversations.map(conv => (
                <ConversationItem key={conv._id} onClick={() => onSelect(conv)}>
                    {conv.title}
                </ConversationItem>
            ))
        )}
    </ListContainer>
);
};

export default ConversationList;
