import React, { useState } from 'react';
import styled from 'styled-components';

// Estilos
const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0;
  padding: 20px;
`;

const Input = styled.input`
  padding: 10px;
  width: 80%;
  max-width: 400px;
  margin: 20px 0;
  border-radius: 10px;
  border: 1px solid #cccccc;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  background-color: #007bff;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const ResponseContainer = styled.div`
  padding: 20px;
  width: 80%;
  max-width: 600px;
  margin: 20px 0;
  border-radius: 10px;
  border: 1px solid #cccccc;
  background-color: #f9f9f9;
  color: #333333;
`;

// Componente Principal
const ChatOn = () => {
  const [query, setQuery] = useState(''); // Estado para almacenar la consulta del usuario
  const [responseText, setResponseText] = useState(''); // Estado para almacenar la respuesta del servidor
  const [isLoading, setIsLoading] = useState(false); // Estado para controlar el estado de carga

  const handleSearch = async () => {
    setResponseText('');
    setIsLoading(true);
    try {
      const response = await fetch('http://localhost:3000/api/user/chaton', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}` // Añade el token si es necesario
        },
        body: JSON.stringify({ query }) // Envía la consulta del usuario en el cuerpo de la solicitud
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let done = false;
      let result = '';

      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;
        result += decoder.decode(value, { stream: !done });

        const parts = result.split('\n\n');
        result = parts.pop(); // Deja la parte incompleta para la siguiente iteración
        const processedText = parts.map(part => {
          if (part.startsWith('data: ')) {
            return part.slice(6); // Elimina 'data: '
          }
          return '';
        }).join('');

        setResponseText((prevText) => prevText + processedText);
      }

      setIsLoading(false);
    } catch (error) {
      console.error('Error al realizar la consulta:', error);
      setResponseText('Error al realizar la consulta.');
      setIsLoading(false);
    }
  };

  return (
    <AppContainer>
      <h1>Asistente de Búsqueda</h1>
      <Input
        type="text"
        placeholder="Ingresa tu consulta..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <Button onClick={handleSearch} disabled={isLoading}>
        {isLoading ? 'Cargando...' : 'Buscar'}
      </Button>
      <ResponseContainer>
        {responseText ? responseText : 'No hay respuesta aún.'}
      </ResponseContainer>
    </AppContainer>
  );
};

export default ChatOn;





