import React, { useState, useEffect } from 'react';
import { createChat, updateChat, deleteChat, uploadFile, getUserChats, getProfile } from '../api';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaTrashAlt, FaHome, FaUpload } from 'react-icons/fa';
import Modal from 'react-modal';

// Definición de los estilos de los componentes
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #1c1c1c;
  color: #ffffff;
  min-height: 100vh;
`;

const Header = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const NavBarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const Select = styled.select`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  margin-right: 10px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  flex: 1;
  
`;

const Input1 = styled.input`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  flex: 1;
  width: 50%; /* Hacer el input más corto */
  pointer-events: auto; /* Permitir eventos de puntero en los inputs */
  text-align: center; /* Centrar el texto dentro del input */
`;

const TextInput = styled(Input)`
  flex: 1;
`;

const FileInput = styled.input`
  display: none;
`;

const OuterButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%; /* Ocupa el 100% del ancho */
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px; /* Espacio entre los botones */
  margin-bottom: 20px; /* Espacio debajo del contenedor */
  width: auto; /* Ajustar el ancho del contenedor */
`;

const CenteredButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Espacio encima del contenedor */
`;

const Button = styled.button`
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 15px; /* Aumentar el padding */
  border: none;
  border-radius: 8px; /* Aumentar el border-radius */
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px; /* Aumentar el gap */
  margin: 8px; /* Aumentar el margin */
  justify-content: center;
  width: 165px; /* Aumentar el width */
  font-size: 1em; /* Aumentar el tamaño del texto */

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #444;
    cursor: not-allowed;
  }
`;

const DeleteButton = styled(Button)`
  background-color: #dc3545;

  &:hover {
    background-color: #c82333;
  }
`;

// Asegúrate de que los iconos dentro de los botones también sean más grandes
const IconStyle = styled.span`
  font-size: 1.3em; /* Aumentar el tamaño de los iconos */
`;

const AdvancedSection = styled.fieldset`
  border: 1px solid #444;
  border-radius: 5px;
  padding: 20px 10px 10px 10px; /* Espacio adicional para el checkbox */
  color: ${props => (props.disabled ? '#2a2a2a' : '#f0f0f0')}; /* Un poco más oscuro que el fondo para deshabilitado, gris casi blanco para habilitado */
  position: relative;
  pointer-events: none; /* Deshabilitar eventos de puntero */
`;

const AdvancedSection1 = styled.fieldset`
  border: 1px solid #444;
  border-radius: 5px;
  padding: 20px 10px 10px 10px; /* Espacio adicional para el checkbox */
  color: ${props => (props.disabled ? '#2a2a2a' : '#f0f0f0')}; /* Un poco más oscuro que el fondo para deshabilitado, gris casi blanco para habilitado */
  position: relative;
`;

const AdvancedLabel = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  pointer-events: auto; /* Permitir eventos de puntero en el contenido del fieldset */
  align-items: center; /* Centrar verticalmente el contenido del label */
  justify-content: space-between; /* Separar el texto y el input */
`;

const Row = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const ModalHeader = styled.h2`
  margin-bottom: 10px;
`;

const ModalContent = styled.p`
  margin-bottom: 20px;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;


Modal.setAppElement('#root');

const NavBar = ({ onSelectChat, chats, selectEnabled, setSelectEnabled }) => {
  return (
    <NavBarContainer>
      <Select onChange={(e) => onSelectChat(e.target.value)} disabled={!selectEnabled}>
        <option value="">Nuevo modelo de chat</option>
        {chats.map(chat => (
          <option key={chat._id} value={chat._id}>{chat.nombre}</option>
        ))}
      </Select>
      <CheckboxLabel>
        <input type="checkbox" checked={selectEnabled} onChange={() => setSelectEnabled(!selectEnabled)} />
        Habilitar editar chat
      </CheckboxLabel>
    </NavBarContainer>
  );
};

const CrearChat = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    categoria: '',
    parametros: {
      temperatura: 0.3,
      fragmentosContexto: 3,
      penalizacionFrecuencia: 0,
      penalizacionPresencia: 0,
      top_p: 0.5,
    },
    assistent: ['', '', ''],
    user: ['', '', ''],
  });
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [advancedEnabled, setAdvancedEnabled] = useState(false);
  const [fileVectorId, setFileVectorId] = useState('');
  const [chats, setChats] = useState([]);
  const [selectEnabled, setSelectEnabled] = useState(false);
  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [selectedChatId, setSelectedChatId] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fileUploadEnabled, setFileUploadEnabled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await getProfile();
        setUser(profile.data);
      } catch (error) {
        console.error('Error al obtener el perfil del usuario:', error);
      }
    };

    fetchUserProfile();
  }, []);

  useEffect(() => {
    if (selectEnabled && user) {
      const fetchChats = async () => {
        try {
          const response = await getUserChats();
          setChats(response.data);
        } catch (error) {
          console.error('Error al obtener los chats:', error);
        }
      };

      fetchChats();
    }
  }, [selectEnabled, user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [field, index] = name.split('.');
    if (index !== undefined) {
      setFormData((prevData) => ({
        ...prevData,
        [field]: prevData[field].map((item, i) => (i === parseInt(index) ? value : item)),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleNumberChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      setFormData((prevData) => ({
        ...prevData,
        parametros: {
          ...prevData.parametros,
          [name]: parsedValue,
        },
      }));
    }
  };

  const handleIntegerChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue)) {
      setFormData((prevData) => ({
        ...prevData,
        parametros: {
          ...prevData.parametros,
          [name]: parsedValue,
        },
      }));
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const simulateUploadProgress = (fileSize) => {
    const totalDuration = 2000 + fileSize * 2; // Simulate 2 seconds per KB
    let startTime = Date.now();

    const updateProgress = () => {
      const elapsedTime = Date.now() - startTime;
      const percentCompleted = Math.min((elapsedTime / totalDuration) * 100, 99); // Cap at 99%
      setUploadProgress(Math.floor(percentCompleted));

      if (percentCompleted < 99) {
        requestAnimationFrame(updateProgress);
      }
    };

    requestAnimationFrame(updateProgress);
  };

  const handleUploadFile = async () => {
    try {
      if (!file) {
        alert('Seleccione un archivo primero.');
        return;
      }

      setProcessing(true);
      simulateUploadProgress(file.size);

      const formData = new FormData();
      formData.append('filePath', file);

      const response = await uploadFile(formData);

      setFileVectorId(response.data.fileVectorId);
      setProcessing(false); // Detener la barra de progreso cuando se complete el procesamiento
      setUploadProgress(100); // Mostrar 100% al completar el proceso
    } catch (error) {
      console.error('Error al cargar el archivo:', error);
      setProcessing(false); // Detener la barra de progreso en caso de error
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let chatData = {
      ...formData,
      fileVectorId: fileVectorId
    };

    if (!fileUploadEnabled) {
      chatData = {
        ...formData,
      };
    }

    try {
      let res;
      if (selectedChatId && !showDeleteModal) {
        res = await updateChat(selectedChatId, chatData);
        setModalMessage('Chat actualizado exitosamente.');
        setShowModal(true);
      } else {
        res = await createChat(chatData);
        setModalMessage('Chat creado exitosamente.');
        setShowModal(true);
      }
      console.log('Respuesta del chat:', res.data);
    } catch (error) {
        if(!showDeleteModal){
        console.error('Error al crear o actualizar el chat:', error);
        setModalMessage('Error al crear o actualizar el chat.');
        setShowModal(true);
        }
    }
  };

  const handleSelectChat = (chatId) => {
    setSelectedChatId(chatId);
    if (chatId === '') {
      setFormData({
        nombre: '',
        categoria: '',
        parametros: {
          temperatura: 0.3,
          fragmentosContexto: 3,
          penalizacionFrecuencia: 0,
          penalizacionPresencia: 0,
          top_p: 0.5,
        },
        assistent: ['', '', ''],
        user: ['', '', ''],
      });
      setFileUploadEnabled(false);
    } else {
      const selectedChat = chats.find(chat => chat._id === chatId);
      if (selectedChat) {
        setFormData({
          nombre: selectedChat.nombre,
          categoria: selectedChat.categoria,
          parametros: selectedChat.parametros,
          assistent: selectedChat.assistent,
          user: selectedChat.user,
        });
        setFileVectorId(selectedChat.fileVectorId);
        setFileUploadEnabled(false);
      }
    }
  };

  const handleDeleteChat = async () => {
    try {
      await deleteChat(selectedChatId);
      setModalMessage('Chat eliminado exitosamente.');
      setShowModal(true);
      setShowDeleteModal(false); // Aseguramos de cerrar el modal de eliminación aquí
      setSelectedChatId('');
      setFormData({
        nombre: '',
        categoria: '',
        parametros: {
          temperatura: 0.3,
          fragmentosContexto: 3,
          penalizacionFrecuencia: 0,
          penalizacionPresencia: 0,
          top_p: 0.5,
        },
        assistent: ['', '', ''],
        user: ['', '', ''],
      });
      const response = await getUserChats();
      setChats(response.data);
    } catch (error) {
      console.error('Error al eliminar el chat:', error);
      setModalMessage('Error al eliminar el chat.');
      setShowModal(true);
      setShowDeleteModal(false); // Aseguramos de cerrar el modal de eliminación aquí
    }
  };

  const toggleAdvanced = () => {
    setAdvancedEnabled(!advancedEnabled);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleNavigate = () => {
    navigate('/admin-home');
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  return (
    <Container>
      <Header>Gestión de Chats</Header>
      <NavBar onSelectChat={handleSelectChat} chats={chats} selectEnabled={selectEnabled} setSelectEnabled={setSelectEnabled} />
      <Form onSubmit={handleSubmit}>
        <Row>
          <TextInput type="text" name="nombre" placeholder="Nombre" value={formData.nombre} onChange={handleChange} required />
          <TextInput type="text" name="categoria" placeholder="Categoría" value={formData.categoria} onChange={handleChange} required />
        </Row>
        <Row>
        <AdvancedSection1>
          <legend>Contexto (subir archivo .txt):</legend>
            <ButtonContainer>
                <FileInput type="file" id="file" onChange={handleFileChange} disabled={!fileUploadEnabled} />
                <Button type="button" onClick={() => document.getElementById('file').click()} disabled={!fileUploadEnabled}>
                  Seleccionar Archivo
                </Button>
                <Button type="button" onClick={handleUploadFile} disabled={!fileUploadEnabled}>
                <IconStyle><FaUpload /></IconStyle> Subir Archivo
                </Button>
              </ButtonContainer>
              <CheckboxLabel>
                  <input type="checkbox" checked={fileUploadEnabled} onChange={() => setFileUploadEnabled(!fileUploadEnabled)} />
                  Habilitar subida de archivo
              </CheckboxLabel>
        {processing && (
          <div>
            <progress value={uploadProgress} max="100">{uploadProgress}%</progress>
            <p>Procesando: {uploadProgress}%</p>
          </div>
        )}
        </AdvancedSection1>
        </Row>
        <Row>
          <CheckboxLabel>
            <input type="checkbox" checked={advancedEnabled} onChange={toggleAdvanced} />
            Habilitar Parámetros Avanzados
        </CheckboxLabel>
        </Row>
        <AdvancedSection disabled={!advancedEnabled}>
        <legend>Parámetros Avanzados</legend>
          <AdvancedLabel>
          Temperatura:
            <Input1 type="number" name="temperatura" step="0.1" min="0" max="2" value={formData.parametros.temperatura} onChange={handleNumberChange} disabled={!advancedEnabled} />
          </AdvancedLabel>
          <AdvancedLabel>
            Fragmentos de Contexto:
            <Input1 type="number" name="fragmentosContexto" min="0" max="20" value={formData.parametros.fragmentosContexto} onChange={handleIntegerChange} disabled={!advancedEnabled} />
          </AdvancedLabel>
          <AdvancedLabel>
            Penalización de Frecuencia:
            <Input1 type="number" name="penalizacionFrecuencia" step="0.1" min="-2" max="2" value={formData.parametros.penalizacionFrecuencia} onChange={handleNumberChange} disabled={!advancedEnabled} />
          </AdvancedLabel>
          <AdvancedLabel>
            Penalización de Presencia:
            <Input1 type="number" name="penalizacionPresencia" step="0.1" min="-2" max="2" value={formData.parametros.penalizacionPresencia} onChange={handleNumberChange} disabled={!advancedEnabled} />
          </AdvancedLabel>
          <AdvancedLabel>
            Top P:
            <Input1 type="number" name="top_p" step="0.1" min="0" max="1" value={formData.parametros.top_p} onChange={handleNumberChange} disabled={!advancedEnabled} />
          </AdvancedLabel>
        </AdvancedSection>
        <fieldset>
          <legend>Asistent y User</legend>
          {[0, 1, 2].map((index) => (
            <Row key={index}>
              <Input type="text" name={`user.${index}`} placeholder={`User ${index + 1}`} value={formData.user[index]} onChange={handleChange} style={{flex: 2}} />
              <Input type="text" name={`assistent.${index}`} placeholder={`Asistent ${index + 1}`} value={formData.assistent[index]} onChange={handleChange} style={{flex: 2}} />
            </Row>
          ))}
        </fieldset>
        <Row>
        <OuterButtonContainer>
          <ButtonContainer>
            <Button type="submit" style={{flex: 1}}>{selectedChatId ? 'Actualizar' : 'Crear'}</Button>
            {selectedChatId && (
              <DeleteButton onClick={openDeleteModal} style={{flex: 1}}><IconStyle><FaTrashAlt /></IconStyle>Eliminar Chat</DeleteButton>
            )}
          </ButtonContainer>
        </OuterButtonContainer>
        </Row>
      </Form>
      {showModal && (
        <Modal
          isOpen={showModal}
          onRequestClose={closeModal}
          contentLabel="Mensaje del sistema"
          style={{
            content: {
              background: '#1c1c1c',
              color: '#ffffff',
              borderRadius: '10px',
              padding: '20px',
              width: '400px',
              maxHeight: '200px',
              margin: '0 auto',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              position: 'fixed',
              textAlign: 'center',
              overflow: 'hidden'
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }
          }}
        >
          <ModalContainer>
            <ModalHeader>Mensaje del Sistema</ModalHeader>
            <ModalContent>{modalMessage}</ModalContent>
            <Button onClick={closeModal}>Cerrar</Button>
          </ModalContainer>
        </Modal>
      )}
      {showDeleteModal && (
        <Modal
          isOpen={showDeleteModal}
          onRequestClose={closeDeleteModal}
          contentLabel="Confirmar Eliminación"
          style={{
            content: {
              background: '#1c1c1c',
              color: '#ffffff',
              borderRadius: '10px',
              padding: '20px',
              width: '400px',
              maxHeight: '200px',
              margin: '0 auto',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              position: 'fixed',
              textAlign: 'center',
              overflow: 'hidden'
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }
          }}
        >
          <ModalContainer>
            <ModalHeader>Confirmar Eliminación</ModalHeader>
            <ModalContent>¿Está seguro de que desea eliminar el chat "{formData.nombre}"?</ModalContent>
            <ModalActions>
              <DeleteButton onClick={handleDeleteChat}>
              <IconStyle><FaTrashAlt /></IconStyle> Eliminar
              </DeleteButton>
              <Button onClick={closeDeleteModal}>Cancelar</Button>
            </ModalActions>
          </ModalContainer>
        </Modal>
      )}
      <CenteredButtonContainer>
      <Button onClick={handleNavigate} style={{width: '80px'}}>
      <IconStyle><FaHome /></IconStyle> Inicio
      </Button>
      </CenteredButtonContainer>
    </Container>
  );
};

export default CrearChat;