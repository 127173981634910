import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { getConversationById, sendMessage } from '../api';

const ChatContainer = styled.div`
    width: 75%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    background-color: #1c1c1c;
    color: #ffffff;
    height: 100vh;

    @media (max-width: 768px) {
        width: 100%;
        height: auto;
    }
`;

const Header = styled.div`
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
`;

const Title = styled.h2`
    margin: 0;
`;

const Messages = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 10px 0;
`;

const Message = styled.div`
    margin-bottom: 10px;
    text-align: ${props => (props.role === 'user' ? 'right' : props.role === 'assistant' ? 'left' : 'center')};
    font-style: ${props => (props.role === 'summary' ? 'italic' : 'normal')};
    color: ${props => (props.role === 'summary' ? '#aaa' : '#fff')};
`;

const InputArea = styled.div`
    display: flex;
    border-top: 1px solid #ccc;
    padding-top: 10px;
`;

const Input = styled.input`
    flex: 1;
    padding: 10px;
    border: 1px solid #444;
    border-radius: 5px;
    background-color: #2c2c2c;
    color: #ffffff;
    margin-right: 10px;
`;

const SendButton = styled.button`
    background-color: #28a745;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #218838;
    }
`;

const ChatWindow = ({ conversationId }) => {
    const [conversation, setConversation] = useState(null);
    const [message, setMessage] = useState('');
    const messagesEndRef = useRef(null);

    useEffect(() => {
        fetchConversation();
    }, [conversationId]);

    const fetchConversation = async () => {
        try {
            const res = await getConversationById(conversationId);
            setConversation(res.data);
            scrollToBottom();
        } catch (error) {
            console.error('Error al obtener la conversación:', error);
        }
    };

    const handleSend = async () => {
        if (message.trim() === '') return;
        try {
            const res = await sendMessage(conversationId, message);
            setConversation(prev => ({
                ...prev,
                messages: [...prev.messages, { role: 'user', content: message }, res.data]
            }));
            setMessage('');
            scrollToBottom();
        } catch (error) {
            console.error('Error al enviar el mensaje:', error);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSend();
        }
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    if (!conversation) return <ChatContainer>Cargando conversación...</ChatContainer>;

    return (
        <ChatContainer>
            <Header>
                <Title>{conversation.title}</Title>
            </Header>
            <Messages>
                {conversation.messages.map((msg, index) => (
                    <Message key={index} role={msg.role}>
                        <strong>
                            {msg.role === 'user' ? 'Tú' : msg.role === 'assistant' ? 'ChatGPT' : 'Resumen'}:
                        </strong> {msg.content}
                    </Message>
                ))}
                <div ref={messagesEndRef} />
            </Messages>
            <InputArea>
                <Input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Escribe tu mensaje..."
                />
                <SendButton onClick={handleSend}>Enviar</SendButton>
            </InputArea>
        </ChatContainer>
    );
};

export default ChatWindow;
