import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1c1c1c;
  color: #ffffff;
`;

const Header = styled.header`
  width: 100%;
  padding: 10px 20px;
  background-color: #1c1c1c;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  height: 40px;
`;

const Title = styled.h1`
  font-family: Arial, sans-serif;
  font-size: 24px;
  color: #ffffff;
  margin-left: 10px;
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
`;

const LogoSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const LargeLogo = styled.img`
  max-width: 70%;
  height: auto;
`;

const ContentSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const MainText = styled.h1`
  font-family: Arial, sans-serif;
  font-size: 25px;
  margin-bottom: 50px;
`;

const CountdownText = styled.p`
  font-family: Arial, sans-serif;
  font-size: 24px;
  margin-top: -10px;
`;

const LinkText = styled(Link)`
  color: #007bff;
  text-decoration: none;
`;

const Footer = styled.footer`
  background-color: #1c1c1c;
  padding: 20px;
  text-align: center;
  color: #ffffff;
`;

const SocialIcons = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const SocialIcon = styled.a`
  color: #ffffff;
  font-size: 24px;
  text-decoration: none;
  &:hover {
    color: #007bff;
  }
`;

const UsuarioVerificado = () => {
  const [countdown, setCountdown] = useState(20);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown <= 1) {
          clearInterval(timer);
          setRedirect(true);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    const timeout = setTimeout(() => {
      setRedirect(true);
    }, 20000);

    return () => {
      clearInterval(timer);
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (redirect) {
      window.location.href = "/";
    }
  }, [redirect]);
  
    return (
      <HomeContainer>
        <Header>
          <LogoContainer>
            <Logo src="/images/logo.svg" alt="Logo" />
            <Title>estudiochat</Title>
          </LogoContainer>
        </Header>
        <MainContent>
          <LogoSection>
            <LargeLogo src="/images/logo.svg" alt="Logo" />
          </LogoSection>
          <ContentSection>
            <MainText>Su usuario ya ha sido verificado. Este link ya fue utilizado. En caso de que usted no haya realizado esta acción, pongase en contacto con nosotros. Muchas gracias.</MainText>
            <CountdownText>Redirigiendo en {countdown} segundos...</CountdownText>
            {countdown === 0 && (
              <Link to="/">Ir a página de inicio</Link>
            )}
          </ContentSection>
        </MainContent>
        <Footer>
          <p>&copy; 2024 Todos los derechos reservados a <LinkText to="https://www.labordt.com">Labor D&T</LinkText></p>
          <SocialIcons>
            <SocialIcon href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></SocialIcon>
            <SocialIcon href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></SocialIcon>
            <SocialIcon href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></SocialIcon>
          </SocialIcons>
        </Footer>
      </HomeContainer>
    );
  };

export default UsuarioVerificado;
