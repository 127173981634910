import React, { useEffect, useState } from 'react';
import { getPlanVentaById, getPlanVentas } from '../api'; // actualizamos el método importado
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

// Estilos

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const PriceInfo = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

const StrikethroughText = styled.span`
  text-decoration: line-through;
  color: #ff6b6b; /* Color rojo para el precio tachado */
  font-size: 1.2em;
  margin-right: 10px;
`;

const DiscountedText = styled.span`
  color: #4caf50; /* Color verde para el precio con descuento */
  font-size: 1.5em;
  font-weight: bold;
`;

const RegularText = styled.span`
  color: #ffffff; /* Color blanco para el precio regular */
  font-size: 1.5em;
  font-weight: bold;
`;

const SavingsText = styled.div`
  color: #4caf50; /* Color verde para el ahorro */
  font-size: 1.2em;
  margin-top: 10px;

  @media (min-width: 768px) {
    margin-top: 0;
    margin-left: 10px;
  }
`;

const SavingsTextMonthly = styled.div`
  color: #D4A017; /* Color amarillo ámbar para el ahorro mensual */
  font-size: 1.2em;
  margin-top: 10px;

  @media (min-width: 768px) {
    margin-top: 0;
    margin-left: 10px;
  }
`;

const LimitedTimeText = styled.span`
  color: #D4A017; /* Color amarillo ámbar */
  font-size: 0.9em;
  margin-left: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px; /* Espaciado entre botones */
  align-items: center;
  flex-direction: row;
  margin-top: 20px; /* Espacio adicional para pantallas pequeñas */

  @media (min-width: 768px) {
    margin-top: 0;
  }
`;

const ToggleButton = styled.button`
  background-color: ${props => (props.active ? (props.isDiscounted ? '#D4A017' : '#4caf50') : '#444')};
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1em;
  width: 100px; /* Ancho fijo para los botones */

  &:hover {
    opacity: 0.8;
  }
`;





const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #1E1E1E;  /* Color del fondo actualizado */
`;

const PlanContainer = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  color: white;
  background-color: #1E1E1E;
`;

const Tab = styled.div`
  margin-bottom: 20px;
`;

const TabLabel = styled.label`
  display: block;
  padding: 10px;
  background: #ffffff;
  cursor: pointer;
  border: 1px solid #ddd;
  color: black;
`;

const TabContent = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.35s;
  border: 1px solid #ddd;
  border-top: none;
  padding: 0 10px;
  color: white;
  background-color: #1f1f1f;
`;

const Input = styled.input`
  display: none;

  &:checked ~ ${TabContent} {
    max-height: 1000px;
    padding: 10px;
    overflow-y: auto;
  }
`;

const SectionTitle = styled.h2`
  color: #ccc;
  border-bottom: 2px solid #444;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

const Text = styled.p`
  margin: 5px 0;
  color: #ffffff;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const VariableItem = styled.div`
  background: #2c2c2c;
  border: 1px solid #444;
  padding: 10px;
  margin: 10px 0;
`;

const Selector = styled.select`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
`;

const TotalText = styled.p`
  font-size: 1.5em;
  color: #ffffff;
`;

// Componente Principal
const InfoPlan = () => {
  const [plan, setPlan] = useState({});
  const [planVentas, setPlanVentas] = useState([]);
  const [selectedPlanVenta, setSelectedPlanVenta] = useState(null);
  const [isAnnual, setIsAnnual] = useState(false);
  const [selectedModels, setSelectedModels] = useState({
    gpt3_5turbo: true,
    gpt3_5turbo16k: false,
    gpt4_0turbo: false,
    gpt4_0o: false,
  });
  
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responsePlan = await getPlanVentas();
        setPlanVentas(responsePlan.data);
      } catch (error) {
        console.error('Error al recuperar datos:', error);
        navigate('/'); //si el server no devuelve nada vuelve al inicio (seguramente se inicio sesion en otra pc)
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedPlanVenta) {
      const fetchData = async () => {
        try {
          const result = await getPlanVentaById(selectedPlanVenta._id);
          setPlan(result.data);
        } catch (error) {
          console.error('Error al recuperar datos:', error);
        }
      };
      fetchData();
    }
  }, [selectedPlanVenta]);

  const handleSelectChange = (e) => {
    const planid = e.target.value;
    const plann = planVentas.find((inst) => inst._id === planid);
    setSelectedPlanVenta(plann);
  };

  const handleModelChange = (e) => {
    const { name, checked } = e.target;
    setSelectedModels((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };
  

  const calculateWithVAT = (value, vat) => {
    return value * (1 + vat / 100);
  };
  
  const calcularCostoFijoMensual = () => {
    let costoFijoMensual = 0;
  
    // Sumar valores numéricos fijos con IVA solo si no son -1
    if (plan.privado !== undefined && plan.privado !== -1) costoFijoMensual += plan.privado;
    if (plan.mercadoP !== undefined && plan.mercadoP !== -1) costoFijoMensual += plan.mercadoP;
    if (plan.Cmanual !== undefined && plan.Cmanual !== -1) costoFijoMensual += plan.Cmanual;
    if (plan.Gmanual !== undefined && plan.Gmanual !== -1) costoFijoMensual += plan.Gmanual;
    if (plan.AutoGest !== undefined && plan.AutoGest !== -1) costoFijoMensual += plan.AutoGest;
    if (plan.evaluacionD !== undefined && plan.evaluacionD !== -1) costoFijoMensual += plan.evaluacionD;
    if (plan.evaluacionA !== undefined && plan.evaluacionA !== -1) costoFijoMensual += plan.evaluacionA;
    if (plan.evaluacionI !== undefined && plan.evaluacionI !== -1) costoFijoMensual += plan.evaluacionI;
    if (plan.plancurso !== undefined && plan.plancurso !== -1) costoFijoMensual += plan.plancurso;
  
    // Sumar todos los componentes fijos de las matrices solo si no son -1
    const addFixedComponents = (components) => {
      return components.reduce((acc, component) => component.pf !== -1 ? acc + component.pf : acc, 0);
    };

    if (plan.chats) costoFijoMensual += addFixedComponents(plan.chats);
    if (plan.ENchats) costoFijoMensual += addFixedComponents(plan.ENchats);
    if (selectedModels.gpt3_5turbo && plan.gpt3_5turbo) costoFijoMensual += addFixedComponents(plan.gpt3_5turbo);
    if (selectedModels.gpt3_5turbo16k && plan.gpt3_5turbo16k) costoFijoMensual += addFixedComponents(plan.gpt3_5turbo16k);
    if (selectedModels.gpt4_0turbo && plan.gpt4_0turbo) costoFijoMensual += addFixedComponents(plan.gpt4_0turbo);
    if (selectedModels.gpt4_0o && plan.gpt4_0o) costoFijoMensual += addFixedComponents(plan.gpt4_0o);
    if (plan.cursos) costoFijoMensual += addFixedComponents(plan.cursos);
    if (plan.video) costoFijoMensual += addFixedComponents(plan.video);
    if (plan.Mvideo) costoFijoMensual += addFixedComponents(plan.Mvideo);
    if (plan.RXvideo) costoFijoMensual += addFixedComponents(plan.RXvideo);
    if (plan.cuestionarios) costoFijoMensual += addFixedComponents(plan.cuestionarios);
    if (plan.usuarios) costoFijoMensual += addFixedComponents(plan.usuarios);
    if (plan.instituto) costoFijoMensual += addFixedComponents(plan.instituto);
    if (plan.archivos) costoFijoMensual += addFixedComponents(plan.archivos);
    if (plan.Marchivos) costoFijoMensual += addFixedComponents(plan.Marchivos);
    if (plan.RXarchivos) costoFijoMensual += addFixedComponents(plan.RXarchivos);
    if (plan.AdmIA) costoFijoMensual += addFixedComponents(plan.AdmIA);
    if (plan.mailCob) costoFijoMensual += addFixedComponents(plan.mailCob);
  
    return costoFijoMensual;
  };
  
  
  const calcularCostoMensualConBonificacion = (mensual) => {
    if (plan.bonifcacionAD > 0) {
      const descuento = mensual * (plan.bonifcacionAD / 100);
      return mensual - descuento;
    }
    return mensual;
  };
  
  const calcularCostoMensualSinBonificacionConIVA = (mensual) => {
    return calculateWithVAT(mensual, plan.iva);
  };

  const calcularCostoAnualConDescuento = (mensual) => {
    const anualSinIVA = mensual * 12;
    const descuento = anualSinIVA * (plan.bonificacionAnual / 100);
    return anualSinIVA - descuento;
  };
  
  
  const costoFijoMensual = plan ? calcularCostoFijoMensual() : 0;
  const costoMensualConBonificacion = plan ? calcularCostoMensualConBonificacion(costoFijoMensual) : 0;
  const precioFinalMensual = plan ? calculateWithVAT(costoMensualConBonificacion, plan.iva) : 0;
  const precioMensualSinBonificacionConIVA = plan ? calcularCostoMensualSinBonificacionConIVA(costoFijoMensual) : 0;
  const ahorroMensual = precioMensualSinBonificacionConIVA - precioFinalMensual;
  
  const costoAnualConDescuento = plan ? calcularCostoAnualConDescuento(costoFijoMensual) : 0;
  const precioAnualSinDescuento = plan ? calculateWithVAT(costoFijoMensual * 12, plan.iva) : 0;
  const precioFinalAnual = plan ? calculateWithVAT(costoAnualConDescuento, plan.iva) : 0;
  const ahorroAnual = precioAnualSinDescuento - precioFinalAnual;
  
  

  return (
    <PageContainer>
      <PlanContainer>
        <h1>Plan de Venta</h1>
        <Selector onChange={handleSelectChange}>
          <option value="">Seleccione un plan de venta</option>
          {planVentas.map((plannVenta) => (
            <option key={plannVenta._id} value={plannVenta._id}>
              {plannVenta.nombre}
            </option>
          ))}
        </Selector>
    {plan && (
        <>
{!isNaN(precioFinalMensual) && (
  <PriceContainer>
    <PriceInfo>
                {isAnnual ? (
                    <>
                    <div>
                        <StrikethroughText>${precioAnualSinDescuento.toFixed(2)}</StrikethroughText>
                        <DiscountedText>${precioFinalAnual.toFixed(2)}</DiscountedText>
                    </div>
                    <SavingsText>Ahorras ${ahorroAnual.toFixed(2)}</SavingsText>
                    </>
                ) : (
                    <>
                    
                        <RegularText>${precioFinalMensual.toFixed(2)} / mes</RegularText>
                        {plan.bonifcacionAD > 0 && (
                        <><div>
                            <StrikethroughText>${precioMensualSinBonificacionConIVA.toFixed(2)}</StrikethroughText>
                            </div>
                            <SavingsTextMonthly>Ahorras ${ahorroMensual.toFixed(2)}</SavingsTextMonthly>
                            <LimitedTimeText>por tiempo limitado</LimitedTimeText>
                            </>
                        )}
                    
                    </>
                )}
                </PriceInfo>
                <ButtonContainer>
                <ToggleButton
                    active={!isAnnual}
                    onClick={() => setIsAnnual(false)}
                    isDiscounted={plan.bonifcacionAD > 0}
                >
                    Mensual
                </ToggleButton>
                <ToggleButton
                    active={isAnnual}
                    onClick={() => setIsAnnual(true)}
                    isDiscounted={false}
                >
                    Anual
                </ToggleButton>
                </ButtonContainer>
            </PriceContainer>
            )}


            <Tab>
              <Input type="checkbox" id="detalles" />
              <TabLabel htmlFor="detalles">Detalles del Plan</TabLabel>
              <TabContent>
                <Text><strong>Nombre:</strong> {plan.nombre}</Text>
                <Text><strong>Descripción:</strong> {plan.descripcion}</Text>
                <Text>{plan.promocion}</Text>
              </TabContent>
            </Tab>

            <Tab>
            <Input type="checkbox" id="fijos" />
            <TabLabel htmlFor="fijos">Componentes Fijos</TabLabel>
            <TabContent>
                <Text>Se realiza una descripción de los costos fijos de los componentes que incluye el plan (tener presente que los servicios también presentan costos fijos y variables por su utilización):</Text>
                {plan.privado !== undefined && plan.privado !== -1 && (
                <Text>
                    {plan.privado !== 0 ? (
                    <>
                        <strong>Instituto Exclusivo:</strong> ${calculateWithVAT(plan.privado, plan.iva)}<br />
                        <em>Descripción:</em> Un instituto exclusivo no se visualiza en la lista de institutos de la plataforma, tampoco se puede buscar por buscador. Solo pueden acceder a él aquellos usuarios que son invitados expresamente por el administrador del instituto.
                    </>
                    ) : (
                    <>
                        <strong>Instituto Exclusivo:</strong> Esta característica no presenta cargos adicionales a los ya establecidos en los demás parámetros.<br />
                        <em>Descripción:</em> Un instituto exclusivo no se visualiza en la lista de institutos de la plataforma, tampoco se puede buscar por buscador. Solo pueden acceder a él aquellos usuarios que son invitados expresamente por el administrador del instituto.
                    </>
                    )}
                </Text>
                )}
                {plan.Gmanual !== undefined && plan.Gmanual !== -1 && (
                <Text>
                    {plan.Gmanual !== 0 ? (
                    <>
                        <strong>Gestión Manual:</strong> ${calculateWithVAT(plan.Gmanual, plan.iva)}<br />
                        <em>Descripción:</em> Gastos asociados a la gestión manual de tareas y procesos dentro de la plataforma.
                    </>
                    ) : (
                    <>
                        <strong>Gestión Manual:</strong> Esta característica no presenta cargos adicionales a los ya establecidos en los demás parámetros.<br />
                        <em>Descripción:</em> Involucra los recursos utilizados para administrar manualmente tareas y procesos dentro de la plataforma, como la gestión de datos de usuarios, sistemas de alta y baja, entre otros.
                    </>
                    )}
                </Text>
                )}
                {plan.AutoGest !== undefined && plan.AutoGest !== -1 && (
                <Text>
                    {plan.AutoGest !== 0 ? (
                    <>
                        <strong>Auto Gestión:</strong> ${calculateWithVAT(plan.AutoGest, plan.iva)}<br />
                        <em>Descripción:</em> Servicios de autogestión que permiten a los usuarios manejar sus propias configuraciones y datos.
                    </>
                    ) : (
                    <>
                        <strong>Auto Gestión:</strong> Esta característica no presenta cargos adicionales a los ya establecidos en los demás parámetros.<br />
                        <em>Descripción:</em> Servicios de autogestión que permiten a los usuarios manejar sus propias configuraciones y datos.
                    </>
                    )}
                </Text>
                )}
                {plan.evaluacionD !== undefined && plan.evaluacionD !== -1 && (
                <Text>
                    {plan.evaluacionD !== 0 ? (
                    <>
                        <strong>Evaluación Docente:</strong> ${calculateWithVAT(plan.evaluacionD, plan.iva)}<br />
                        <em>Descripción:</em> Herramientas y servicios para la evaluación del desempeño de los docentes.
                    </>
                    ) : (
                    <>
                        <strong>Evaluación Docente:</strong> Esta característica no presenta cargos adicionales a los ya establecidos en los demás parámetros.<br />
                        <em>Descripción:</em> Herramientas y servicios para la evaluación del desempeño de los docentes.
                    </>
                    )}
                </Text>
                )}
                {plan.evaluacionA !== undefined && plan.evaluacionA !== -1 && (
                <Text>
                    {plan.evaluacionA !== 0 ? (
                    <>
                        <strong>Evaluación Alumno:</strong> ${calculateWithVAT(plan.evaluacionA, plan.iva)}<br />
                        <em>Descripción:</em> Funcionalidades para la evaluación del rendimiento y progreso de los alumnos.
                    </>
                    ) : (
                    <>
                        <strong>Evaluación Alumno:</strong> Esta característica no presenta cargos adicionales a los ya establecidos en los demás parámetros.<br />
                        <em>Descripción:</em> Funcionalidades para la evaluación del rendimiento y progreso de los alumnos.
                    </>
                    )}
                </Text>
                )}
                {plan.evaluacionI !== undefined && plan.evaluacionI !== -1 && (
                <Text>
                    {plan.evaluacionI !== 0 ? (
                    <>
                        <strong>Evaluación Institucional:</strong> ${calculateWithVAT(plan.evaluacionI, plan.iva)}<br />
                        <em>Descripción:</em> Servicios de evaluación del desempeño y eficiencia de la institución educativa.
                    </>
                    ) : (
                    <>
                        <strong>Evaluación Institucional:</strong> Esta característica no presenta cargos adicionales a los ya establecidos en los demás parámetros.<br />
                        <em>Descripción:</em> Servicios de evaluación del desempeño y eficiencia de la institución educativa.
                    </>
                    )}
                </Text>
                )}
                {plan.plancurso !== undefined && plan.plancurso !== -1 && (
                <Text>
                    {plan.plancurso !== 0 ? (
                    <>
                        <strong>Plan de Curso:</strong> ${calculateWithVAT(plan.plancurso, plan.iva)}<br />
                        <em>Descripción:</em> Característica asociados a la planificación y estructuración de cursos en la plataforma.
                    </>
                    ) : (
                    <>
                        <strong>Plan de Curso:</strong> Esta característica no presenta cargos adicionales a los ya establecidos en los demás parámetros.<br />
                        <em>Descripción:</em> Característica asociados a la planificación y estructuración de cursos en la plataforma.
                    </>
                    )}
                </Text>
                )}

                <Text>Todos los precios incluyen IVA.</Text>
            </TabContent>
            </Tab>

            <Tab>
            <Input type="checkbox" id="variables" />
            <TabLabel htmlFor="variables">Costos Variables</TabLabel>
            <TabContent>
            <Text>Estos costos dependen del uso que le des a cada servicio. Aquí se detallan los diferentes elementos que pueden incrementar el costo dependiendo de su utilización:</Text>
            {plan.chats && plan.chats.length > 0 && plan.chats.filter(chat => chat.pf !== -1).map((chat, index) => (
                <VariableItem key={index}>
                    <Text><strong>Modelo de Chat</strong></Text>
                    <Text>Esta herramienta es crucial para brindar una ayuda 24/7 a tus estudiantes. Pueden sacarse todas las dudas y estudiar con su profesor IA favorito.</Text>
                    {!(chat.pf === 0 && chat.cf === 0) && (
                        <>
                            <Text><strong>Cantidad Fija:</strong> {chat.cf}</Text>
                            <Text><strong>Precio Fijo:</strong> ${calculateWithVAT(chat.pf, plan.iva)}</Text>
                        </>
                    )}
                    <Text><strong>Cantidad Variable:</strong> {chat.cv}</Text>
                    <Text><strong>Precio Variable:</strong> ${calculateWithVAT(chat.pv, plan.iva)}</Text>
                    <Text><strong>Cantidad Variable Total:</strong> {chat.cvt}</Text>
                    <Text><strong>Precio Adicional Variable:</strong> ${calculateWithVAT(chat.pav, plan.iva)}</Text>
                    {chat.pf === 0 && chat.cf === 0 ? (
                        <Text>Ejemplo: El plan es a demanda, si quieres puedes agregar hasta {chat.cvt} chats por con costo de: ${calculateWithVAT(chat.pv, plan.iva)} cada uno. Si quieres incorporar mas chats que ese nivel, puedes hacerlo pagando ${calculateWithVAT(chat.pav, plan.iva)} por cada unidad adicional. Todos los precios incluyen IVA.</Text>
                    ) : (
                        <Text>Ejemplo: El plan incluye {chat.cf} chats distintos, si quieres puedes agregar hasta {chat.cvt} chats por con costo de: ${calculateWithVAT(chat.pv, plan.iva)} cada uno. Si quieres incorporar mas chats que este limite puedes hacerlo pagando ${calculateWithVAT(chat.pav, plan.iva)} por cada unidad adicional. Todos los precios incluyen IVA.</Text>
                    )}
                </VariableItem>
            ))}
            {plan.ENchats && plan.ENchats.length > 0 && plan.ENchats.filter(chat => chat.pf !== -1).map((chat, index) => (
                <VariableItem key={index}>
                    <Text><strong>Entrenamiento de Chats</strong></Text>
                    <Text>Entrenamiento de chats por sistema de embedding. Permite mejorar la interacción del chatbot con los usuarios.</Text>
                    {!(chat.pf === 0 && chat.cf === 0) && (
                        <>
                            <Text><strong>Cantidad Fija:</strong> {chat.cf}</Text>
                            <Text><strong>Precio Fijo:</strong> ${calculateWithVAT(chat.pf, plan.iva)}</Text>
                        </>
                    )}
                    <Text><strong>Cantidad Variable:</strong> {chat.cv}</Text>
                    <Text><strong>Precio Variable:</strong> ${calculateWithVAT(chat.pv, plan.iva)}</Text>
                    <Text><strong>Cantidad Variable Total:</strong> {chat.cvt}</Text>
                    <Text><strong>Precio Adicional Variable:</strong> ${calculateWithVAT(chat.pav, plan.iva)}</Text>
                    {chat.pf === 0 && chat.cf === 0 ? (
                        <Text>Ejemplo: El plan es a demanda, si necesitas entrenar más el sistema de chats, el costo adicional será de ${calculateWithVAT(chat.pv, plan.iva)} por cada unidad adicional. Si necesitas más entrenamientos que ese nivel, puedes hacerlo pagando ${calculateWithVAT(chat.pav, plan.iva)} por cada unidad adicional. Todos los precios incluyen IVA.</Text>
                    ) : (
                        <Text>Ejemplo: Si necesitas entrenar más el sistema de chats, el costo adicional será de ${calculateWithVAT(chat.pv, plan.iva)} por cada unidad adicional.</Text>
                    )}
                    <Text>Todos los precios incluyen IVA.</Text>
                </VariableItem>
            ))}

                <div>
                <Text><strong>Elije el modelo de IA que prefieres usar para el chat</strong></Text>
                </div>
                <div>
                {plan.gpt3_5turbo && plan.gpt3_5turbo.some(chat => chat.pf !== -1) && (
                    <label>
                    <input
                        type="checkbox"
                        name="gpt3_5turbo"
                        checked={selectedModels.gpt3_5turbo}
                        onChange={handleModelChange}
                    />
                    GPT-3.5 Turbo
                    </label>
                )}
                
                {plan.gpt3_5turbo16k && plan.gpt3_5turbo16k.some(chat => chat.pf !== -1) && (
                    <label>
                    <input
                        type="checkbox"
                        name="gpt3_5turbo16k"
                        checked={selectedModels.gpt3_5turbo16k}
                        onChange={handleModelChange}
                    />
                    GPT-3.5 Turbo 16k
                    </label>
                )}
                
                {plan.gpt4_0turbo && plan.gpt4_0turbo.some(chat => chat.pf !== -1) && (
                    <label>
                    <input
                        type="checkbox"
                        name="gpt4_0turbo"
                        checked={selectedModels.gpt4_0turbo}
                        onChange={handleModelChange}
                    />
                    GPT-4.0 Turbo
                    </label>
                )}
                
                {plan.gpt4_0o && plan.gpt4_0o.some(chat => chat.pf !== -1) && (
                    <label>
                    <input
                        type="checkbox"
                        name="gpt4_0o"
                        checked={selectedModels.gpt4_0o}
                        onChange={handleModelChange}
                    />
                    GPT-4.0 OpenAI
                    </label>
                )}
                </div>
                {plan.gpt3_5turbo && plan.gpt3_5turbo.length > 0 && selectedModels.gpt3_5turbo && plan.gpt3_5turbo.filter(chat => chat.pf !== -1).map((chat, index) => (
                <VariableItem key={index}>
                    <Text><strong>GPT-3.5 Turbo</strong></Text>
                    <Text>Modelo rápido y eficiente, perfecto para chatbots y asistentes virtuales. Ofrece respuestas precisas y coherentes en tiempo real, mejorando la experiencia del usuario.</Text>
                    {!(chat.pf === 0 && chat.cf === 0) && (
                        <>
                            <Text><strong>Cantidad Fija:</strong> {chat.cf}</Text>
                            <Text><strong>Precio Fijo:</strong> ${calculateWithVAT(chat.pf, plan.iva)}</Text>
                        </>
                    )}
                    <Text><strong>Cantidad Variable:</strong> {chat.cv}</Text>
                    <Text><strong>Precio Variable:</strong> ${calculateWithVAT(chat.pv, plan.iva)}</Text>
                    <Text><strong>Cantidad Variable Total:</strong> {chat.cvt}</Text>
                    <Text><strong>Precio Adicional Variable:</strong> ${calculateWithVAT(chat.pav, plan.iva)}</Text>
                    {chat.pf === 0 && chat.cf === 0 ? (
                        <Text>Ejemplo: El plan es a demanda, si necesitas más interacciones con GPT-3.5 Turbo, el costo adicional por cada interacción será de ${calculateWithVAT(chat.pv, plan.iva)} por cada {chat.cv} tokens, hasta un máximo de {chat.cvt} tokens, después de lo cual se aplicará un costo adicional de ${calculateWithVAT(chat.pav, plan.iva)} por cada 1000 tokens consumidos. Todos los precios incluyen IVA.</Text>
                    ) : (
                        <Text>Ejemplo: Si necesitas más interacciones con GPT-3.5 Turbo, el costo adicional por cada interacción será de ${calculateWithVAT(chat.pv, plan.iva)} por cada {chat.cv} tokens, hasta un máximo de {chat.cvt} tokens, después de lo cual se aplicará un costo adicional de ${calculateWithVAT(chat.pav, plan.iva)} por cada 1000 tokens consumidos. Todos los precios incluyen IVA.</Text>
                    )}
                    <Text>Todos los precios incluyen IVA.</Text>
                </VariableItem>
            ))}

            {plan.gpt3_5turbo16k && plan.gpt3_5turbo16k.length > 0 && selectedModels.gpt3_5turbo16k && plan.gpt3_5turbo16k.filter(chat => chat.pf !== -1).map((chat, index) => (
                <VariableItem key={index}>
                    <Text><strong>GPT-3.5 Turbo 16k</strong></Text>
                    <Text>Capaz de manejar textos largos con hasta 16,000 tokens. Ideal para análisis de documentos extensos y generación de reportes detallados, útil en sectores legales y académicos.</Text>
                    {!(chat.pf === 0 && chat.cf === 0) && (
                        <>
                            <Text><strong>Cantidad Fija:</strong> {chat.cf}</Text>
                            <Text><strong>Precio Fijo:</strong> ${calculateWithVAT(chat.pf, plan.iva)}</Text>
                        </>
                    )}
                    <Text><strong>Cantidad Variable:</strong> {chat.cv}</Text>
                    <Text><strong>Precio Variable:</strong> ${calculateWithVAT(chat.pv, plan.iva)}</Text>
                    <Text><strong>Cantidad Variable Total:</strong> {chat.cvt}</Text>
                    <Text><strong>Precio Adicional Variable:</strong> ${calculateWithVAT(chat.pav, plan.iva)}</Text>
                    {chat.pf === 0 && chat.cf === 0 ? (
                        <Text>Ejemplo: El plan es a demanda, si necesitas más interacciones con GPT-3.5 Turbo 16k, el costo adicional por cada interacción será de ${calculateWithVAT(chat.pv, plan.iva)} por cada {chat.cv} tokens, hasta un máximo de {chat.cvt} tokens, después de lo cual se aplicará un costo adicional de ${calculateWithVAT(chat.pav, plan.iva)} por cada 1000 tokens consumidos. Todos los precios incluyen IVA.</Text>
                    ) : (
                        <Text>Ejemplo: Si necesitas más interacciones con GPT-3.5 Turbo 16k, el costo adicional por cada interacción será de ${calculateWithVAT(chat.pv, plan.iva)} por cada {chat.cv} tokens, hasta un máximo de {chat.cvt} tokens, después de lo cual se aplicará un costo adicional de ${calculateWithVAT(chat.pav, plan.iva)} por cada 1000 tokens consumidos. Todos los precios incluyen IVA.</Text>
                    )}
                    <Text>Todos los precios incluyen IVA.</Text>
                </VariableItem>
            ))}

            {plan.gpt4_0turbo && plan.gpt4_0turbo.length > 0 && selectedModels.gpt4_0turbo && plan.gpt4_0turbo.filter(chat => chat.pf !== -1).map((chat, index) => (
                <VariableItem key={index}>
                    <Text><strong>GPT-4.0 Turbo</strong></Text>
                    <Text>Ofrece rendimiento superior y tiempos de respuesta rápidos. Ideal para atención al cliente y soporte técnico en tiempo real, proporcionando interacciones más precisas y satisfactorias.</Text>
                    {!(chat.pf === 0 && chat.cf === 0) && (
                        <>
                            <Text><strong>Cantidad Fija:</strong> {chat.cf}</Text>
                            <Text><strong>Precio Fijo:</strong> ${calculateWithVAT(chat.pf, plan.iva)}</Text>
                        </>
                    )}
                    <Text><strong>Cantidad Variable:</strong> {chat.cv}</Text>
                    <Text><strong>Precio Variable:</strong> ${calculateWithVAT(chat.pv, plan.iva)}</Text>
                    <Text><strong>Cantidad Variable Total:</strong> {chat.cvt}</Text>
                    <Text><strong>Precio Adicional Variable:</strong> ${calculateWithVAT(chat.pav, plan.iva)}</Text>
                    {chat.pf === 0 && chat.cf === 0 ? (
                        <Text>Ejemplo: El plan es a demanda, si necesitas más interacciones con GPT-4.0 Turbo, el costo adicional por cada interacción será de ${calculateWithVAT(chat.pv, plan.iva)} por cada {chat.cv} tokens, hasta un máximo de {chat.cvt} tokens, después de lo cual se aplicará un costo adicional de ${calculateWithVAT(chat.pav, plan.iva)} por cada 1000 tokens consumidos. Todos los precios incluyen IVA.</Text>
                    ) : (
                        <Text>Ejemplo: Si necesitas más interacciones con GPT-4.0 Turbo, el costo adicional por cada interacción será de ${calculateWithVAT(chat.pv, plan.iva)} por cada {chat.cv} tokens, hasta un máximo de {chat.cvt} tokens, después de lo cual se aplicará un costo adicional de ${calculateWithVAT(chat.pav, plan.iva)} por cada 1000 tokens consumidos. Todos los precios incluyen IVA.</Text>
                    )}
                    <Text>Todos los precios incluyen IVA.</Text>
                </VariableItem>
            ))}

            {plan.gpt4_0o && plan.gpt4_0o.length > 0 && selectedModels.gpt4_0o && plan.gpt4_0o.filter(chat => chat.pf !== -1).map((chat, index) => (
                <VariableItem key={index}>
                    <Text><strong>GPT-4.0 OpenAI</strong></Text>
                    <Text>El modelo más avanzado de OpenAI, con mejoras en coherencia y creatividad. Perfecto para creación de contenido complejo y automatización de tareas creativas, liderando en innovación y calidad.</Text>
                    {!(chat.pf === 0 && chat.cf === 0) && (
                        <>
                            <Text><strong>Cantidad Fija:</strong> {chat.cf}</Text>
                            <Text><strong>Precio Fijo:</strong> ${calculateWithVAT(chat.pf, plan.iva)}</Text>
                        </>
                    )}
                    <Text><strong>Cantidad Variable:</strong> {chat.cv}</Text>
                    <Text><strong>Precio Variable:</strong> ${calculateWithVAT(chat.pv, plan.iva)}</Text>
                    <Text><strong>Cantidad Variable Total:</strong> {chat.cvt}</Text>
                    <Text><strong>Precio Adicional Variable:</strong> ${calculateWithVAT(chat.pav, plan.iva)}</Text>
                    {chat.pf === 0 && chat.cf === 0 ? (
                        <Text>Ejemplo: El plan es a demanda, si necesitas más interacciones con GPT-4.0 OpenAI, el costo adicional por cada interacción será de ${calculateWithVAT(chat.pv, plan.iva)} por cada {chat.cv} tokens, hasta un máximo de {chat.cvt} tokens, después de lo cual se aplicará un costo adicional de ${calculateWithVAT(chat.pav, plan.iva)} por cada 1000 tokens consumidos. Todos los precios incluyen IVA.</Text>
                    ) : (
                        <Text>Ejemplo: Si necesitas más interacciones con GPT-4.0 OpenAI, el costo adicional por cada interacción será de ${calculateWithVAT(chat.pv, plan.iva)} por cada {chat.cv} tokens, hasta un máximo de {chat.cvt} tokens, después de lo cual se aplicará un costo adicional de ${calculateWithVAT(chat.pav, plan.iva)} por cada 1000 tokens consumidos. Todos los precios incluyen IVA.</Text>
                    )}
                    <Text>Todos los precios incluyen IVA.</Text>
                </VariableItem>
            ))}

            {plan.cursos && plan.cursos.length > 0 && plan.cursos.filter(curso => curso.pf !== -1).map((curso, index) => (
                <VariableItem key={index}>
                    <Text><strong>Cursos</strong></Text>
                    <Text>Módulos de cursos disponibles en la plataforma.</Text>
                    {!(curso.pf === 0 && curso.cf === 0) && (
                        <>
                            <Text><strong>Cantidad Fija:</strong> {curso.cf}</Text>
                            <Text><strong>Precio Fijo:</strong> ${calculateWithVAT(curso.pf, plan.iva)}</Text>
                        </>
                    )}
                    <Text><strong>Cantidad Variable:</strong> {curso.cv}</Text>
                    <Text><strong>Precio Variable:</strong> ${calculateWithVAT(curso.pv, plan.iva)}</Text>
                    <Text><strong>Cantidad Variable Total:</strong> {curso.cvt}</Text>
                    <Text><strong>Precio Adicional Variable:</strong> ${calculateWithVAT(curso.pav, plan.iva)}</Text>
                    {curso.pf === 0 && curso.cf === 0 ? (
                        <Text>Ejemplo: El plan es a demanda, si necesitas más cursos, el costo adicional por cada curso será de ${calculateWithVAT(curso.pv, plan.iva)}. Si necesitas más cursos que ese nivel, puedes hacerlo pagando ${calculateWithVAT(curso.pav, plan.iva)} por cada curso extra. Todos los precios incluyen IVA.</Text>
                    ) : (
                        <Text>Ejemplo: Si necesitas más cursos de los incluidos en el plan básico, el costo adicional por cada curso será de ${calculateWithVAT(curso.pv, plan.iva)} hasta un máximo de {curso.cvt}, después de lo cual se aplicará un costo adicional de ${calculateWithVAT(curso.pav, plan.iva)} por cada curso extra.</Text>
                    )}
                    <Text>Todos los precios incluyen IVA.</Text>
                </VariableItem>
            ))}
                {plan.video && plan.video.length > 0 && plan.video.filter(video => video.pf !== -1).map((video, index) => (
                    <VariableItem key={index}>
                        <Text><strong>Video</strong></Text>
                        <Text>Archivos de video estándar.</Text>
                        {!(video.pf === 0 && video.cf === 0) && (
                            <>
                                <Text><strong>Cantidad Fija:</strong> {video.cf}</Text>
                                <Text><strong>Precio Fijo:</strong> ${calculateWithVAT(video.pf, plan.iva)}</Text>
                            </>
                        )}
                        <Text><strong>Cantidad Variable:</strong> {video.cv}</Text>
                        <Text><strong>Precio Variable:</strong> ${calculateWithVAT(video.pv, plan.iva)}</Text>
                        <Text><strong>Cantidad Variable Total:</strong> {video.cvt}</Text>
                        <Text><strong>Precio Adicional Variable:</strong> ${calculateWithVAT(video.pav, plan.iva)}</Text>
                        {video.pf === 0 && video.cf === 0 ? (
                            <Text>Ejemplo: El plan es a demanda, si necesitas más videos, el costo adicional por cada video será de ${calculateWithVAT(video.pv, plan.iva)}. Si necesitas más videos que ese nivel, puedes hacerlo pagando ${calculateWithVAT(video.pav, plan.iva)} por cada video extra. Todos los precios incluyen IVA.</Text>
                        ) : (
                            <Text>Ejemplo: Si necesitas más videos de los incluidos en el plan básico, el costo adicional por cada video será de ${calculateWithVAT(video.pv, plan.iva)} hasta un máximo de {video.cvt}, después de lo cual se aplicará un costo adicional de ${calculateWithVAT(video.pav, plan.iva)} por cada video extra.</Text>
                        )}
                        <Text>Todos los precios incluyen IVA.</Text>
                    </VariableItem>
                ))}

                {plan.Mvideo && plan.Mvideo.length > 0 && plan.Mvideo.filter(video => video.pf !== -1).map((video, index) => (
                    <VariableItem key={index}>
                        <Text><strong>Almacenamiento de Videos</strong></Text>
                        <Text>La cantidad de espacio de almacenamiento disponible en el servidor para guardar los videos, medida en GB.</Text>
                        {!(video.pf === 0 && video.cf === 0) && (
                            <>
                                <Text><strong>Cantidad Fija:</strong> {video.cf} GB</Text>
                                <Text><strong>Precio Fijo:</strong> ${calculateWithVAT(video.pf, plan.iva)}</Text>
                            </>
                        )}
                        <Text><strong>Cantidad Variable:</strong> {video.cv} GB</Text>
                        <Text><strong>Precio Variable:</strong> ${calculateWithVAT(video.pv, plan.iva)}</Text>
                        <Text><strong>Cantidad Variable Total:</strong> {video.cvt} GB</Text>
                        <Text><strong>Precio Adicional Variable:</strong> ${calculateWithVAT(video.pav, plan.iva)}</Text>
                        {video.pf === 0 && video.cf === 0 ? (
                            <Text>Ejemplo: El plan es a demanda, si necesitas más almacenamiento para videos, el costo adicional por cada GB será de ${calculateWithVAT(video.pv, plan.iva)}. Si necesitas más almacenamiento que ese nivel, puedes hacerlo pagando ${calculateWithVAT(video.pav, plan.iva)} por cada GB extra. Todos los precios incluyen IVA.</Text>
                        ) : (
                            <Text>Ejemplo: Si necesitas más almacenamiento para videos de lo incluido en el plan básico, el costo adicional por cada GB será de ${calculateWithVAT(video.pv, plan.iva)} hasta un máximo de {video.cvt} GB, después de lo cual se aplicará un costo adicional de ${calculateWithVAT(video.pav, plan.iva)} por cada GB extra.</Text>
                        )}
                        <Text>Todos los precios incluyen IVA.</Text>
                    </VariableItem>
                ))}

                {plan.RXvideo && plan.RXvideo.length > 0 && plan.RXvideo.filter(video => video.pf !== -1).map((video, index) => (
                    <VariableItem key={index}>
                        <Text><strong>Transferencia de Datos de Videos</strong></Text>
                        <Text>La transferencia de datos por el uso de videos, medida en MB. Esto se refiere a la cantidad de datos consumidos cuando los usuarios del sitio ven videos, lo cual tiene un costo.</Text>
                        {!(video.pf === 0 && video.cf === 0) && (
                            <>
                                <Text><strong>Cantidad Fija:</strong> {video.cf} MB</Text>
                                <Text><strong>Precio Fijo:</strong> ${calculateWithVAT(video.pf, plan.iva)}</Text>
                            </>
                        )}
                        <Text><strong>Cantidad Variable:</strong> {video.cv} MB</Text>
                        <Text><strong>Precio Variable:</strong> ${calculateWithVAT(video.pv, plan.iva)}</Text>
                        <Text><strong>Cantidad Variable Total:</strong> {video.cvt} MB</Text>
                        <Text><strong>Precio Adicional Variable:</strong> ${calculateWithVAT(video.pav, plan.iva)}</Text>
                        {video.pf === 0 && video.cf === 0 ? (
                            <Text>Ejemplo: El plan es a demanda, si necesitas más transferencia de datos para videos, el costo adicional por cada MB será de ${calculateWithVAT(video.pv, plan.iva)}. Si necesitas más transferencia que ese nivel, puedes hacerlo pagando ${calculateWithVAT(video.pav, plan.iva)} por cada MB extra. Todos los precios incluyen IVA.</Text>
                        ) : (
                            <Text>Ejemplo: Si necesitas más transferencia de datos para videos de lo incluido en el plan básico, el costo adicional por cada MB será de ${calculateWithVAT(video.pv, plan.iva)} hasta un máximo de {video.cvt} MB, después de lo cual se aplicará un costo adicional de ${calculateWithVAT(video.pav, plan.iva)} por cada MB extra.</Text>
                        )}
                        <Text>Todos los precios incluyen IVA.</Text>
                    </VariableItem>
                ))}

                {plan.cuestionarios && plan.cuestionarios.length > 0 && plan.cuestionarios.filter(cuestionario => cuestionario.pf !== -1).map((cuestionario, index) => (
                    <VariableItem key={index}>
                        <Text><strong>Cuestionarios</strong></Text>
                        <Text>Cuestionarios disponibles para evaluar a los alumnos.</Text>
                        {!(cuestionario.pf === 0 && cuestionario.cf === 0) && (
                            <>
                                <Text><strong>Cantidad Fija:</strong> {cuestionario.cf}</Text>
                                <Text><strong>Precio Fijo:</strong> ${calculateWithVAT(cuestionario.pf, plan.iva)}</Text>
                            </>
                        )}
                        <Text><strong>Cantidad Variable:</strong> {cuestionario.cv}</Text>
                        <Text><strong>Precio Variable:</strong> ${calculateWithVAT(cuestionario.pv, plan.iva)}</Text>
                        <Text><strong>Cantidad Variable Total:</strong> {cuestionario.cvt}</Text>
                        <Text><strong>Precio Adicional Variable:</strong> ${calculateWithVAT(cuestionario.pav, plan.iva)}</Text>
                        {cuestionario.pf === 0 && cuestionario.cf === 0 ? (
                            <Text>Ejemplo: El plan es a demanda, si necesitas más cuestionarios, el costo adicional por cada cuestionario será de ${calculateWithVAT(cuestionario.pv, plan.iva)}. Si necesitas más cuestionarios que ese nivel, puedes hacerlo pagando ${calculateWithVAT(cuestionario.pav, plan.iva)} por cada cuestionario extra. Todos los precios incluyen IVA.</Text>
                        ) : (
                            <Text>Ejemplo: Si necesitas más cuestionarios de los incluidos en el plan básico, el costo adicional por cada cuestionario será de ${calculateWithVAT(cuestionario.pv, plan.iva)} hasta un máximo de {cuestionario.cvt}, después de lo cual se aplicará un costo adicional de ${calculateWithVAT(cuestionario.pav, plan.iva)} por cada cuestionario extra.</Text>
                        )}
                        <Text>Todos los precios incluyen IVA.</Text>
                    </VariableItem>
                ))}

                {plan.usuarios && plan.usuarios.length > 0 && plan.usuarios.filter(usuario => usuario.pf !== -1).map((usuario, index) => (
                    <VariableItem key={index}>
                        <Text><strong>Alumnos</strong></Text>
                        <Text>Los alumnos se cuentan cuando están inscritos en un curso. Por ejemplo, un usuario puede tener una sola cuenta pero estar inscrito en 3 cursos, por lo tanto, se considera que son 3 alumnos.</Text>
                        {!(usuario.pf === 0 && usuario.cf === 0) && (
                            <>
                                <Text><strong>Cantidad Fija:</strong> {usuario.cf}</Text>
                                <Text><strong>Precio Fijo:</strong> ${calculateWithVAT(usuario.pf, plan.iva)}</Text>
                            </>
                        )}
                        <Text><strong>Cantidad Variable:</strong> {usuario.cv}</Text>
                        <Text><strong>Precio Variable:</strong> ${calculateWithVAT(usuario.pv, plan.iva)}</Text>
                        <Text><strong>Cantidad Variable Total:</strong> {usuario.cvt}</Text>
                        <Text><strong>Precio Adicional Variable:</strong> ${calculateWithVAT(usuario.pav, plan.iva)}</Text>
                        {usuario.pf === 0 && usuario.cf === 0 ? (
                            <Text>Ejemplo: El plan es a demanda, si necesitas más alumnos, el costo adicional por cada alumno será de ${calculateWithVAT(usuario.pv, plan.iva)}. Si necesitas más alumnos que ese nivel, puedes hacerlo pagando ${calculateWithVAT(usuario.pav, plan.iva)} por cada alumno extra. Todos los precios incluyen IVA.</Text>
                        ) : (
                            <Text>Ejemplo: Si necesitas más alumnos de los incluidos en el plan básico, el costo adicional por cada alumno será de ${calculateWithVAT(usuario.pv, plan.iva)} hasta un máximo de {usuario.cvt}, después de lo cual se aplicará un costo adicional de ${calculateWithVAT(usuario.pav, plan.iva)} por cada alumno extra.</Text>
                        )}
                        <Text>Todos los precios incluyen IVA.</Text>
                    </VariableItem>
                ))}

                {plan.instituto && plan.instituto.length > 0 && plan.instituto.filter(inst => inst.pf !== -1).map((inst, index) => (
                    <VariableItem key={index}>
                        <Text><strong>Instituto</strong></Text>
                        <Text>Institutos educativos registrados en la plataforma.</Text>
                        {!(inst.pf === 0 && inst.cf === 0) && (
                            <>
                                <Text><strong>Cantidad Fija:</strong> {inst.cf}</Text>
                                <Text><strong>Precio Fijo:</strong> ${calculateWithVAT(inst.pf, plan.iva)}</Text>
                            </>
                        )}
                        <Text><strong>Cantidad Variable:</strong> {inst.cv}</Text>
                        <Text><strong>Precio Variable:</strong> ${calculateWithVAT(inst.pv, plan.iva)}</Text>
                        <Text><strong>Cantidad Variable Total:</strong> {inst.cvt}</Text>
                        <Text><strong>Precio Adicional Variable:</strong> ${calculateWithVAT(inst.pav, plan.iva)}</Text>
                        {inst.pf === 0 && inst.cf === 0 ? (
                            <Text>Ejemplo: El plan es a demanda, si necesitas más institutos, el costo adicional por cada instituto será de ${calculateWithVAT(inst.pv, plan.iva)}. Si necesitas más institutos que ese nivel, puedes hacerlo pagando ${calculateWithVAT(inst.pav, plan.iva)} por cada instituto extra. Todos los precios incluyen IVA.</Text>
                        ) : (
                            <Text>Ejemplo: Si necesitas más institutos de los incluidos en el plan básico, el costo adicional por cada instituto será de ${calculateWithVAT(inst.pv, plan.iva)} hasta un máximo de {inst.cvt}, después de lo cual se aplicará un costo adicional de ${calculateWithVAT(inst.pav, plan.iva)} por cada instituto extra.</Text>
                        )}
                        <Text>Todos los precios incluyen IVA.</Text>
                    </VariableItem>
                ))}

                {plan.archivos && plan.archivos.length > 0 && plan.archivos.filter(archivo => archivo.pf !== -1).map((archivo, index) => (
                    <VariableItem key={index}>
                        <Text><strong>Archivos</strong></Text>
                        <Text>Archivos disponibles en la plataforma.</Text>
                        {!(archivo.pf === 0 && archivo.cf === 0) && (
                            <>
                                <Text><strong>Cantidad Fija:</strong> {archivo.cf}</Text>
                                <Text><strong>Precio Fijo:</strong> ${calculateWithVAT(archivo.pf, plan.iva)}</Text>
                            </>
                        )}
                        <Text><strong>Cantidad Variable:</strong> {archivo.cv}</Text>
                        <Text><strong>Precio Variable:</strong> ${calculateWithVAT(archivo.pv, plan.iva)}</Text>
                        <Text><strong>Cantidad Variable Total:</strong> {archivo.cvt}</Text>
                        <Text><strong>Precio Adicional Variable:</strong> ${calculateWithVAT(archivo.pav, plan.iva)}</Text>
                        {archivo.pf === 0 && archivo.cf === 0 ? (
                            <Text>Ejemplo: El plan es a demanda, si necesitas más archivos, el costo adicional por cada archivo será de ${calculateWithVAT(archivo.pv, plan.iva)}. Si necesitas más archivos que ese nivel, puedes hacerlo pagando ${calculateWithVAT(archivo.pav, plan.iva)} por cada archivo extra. Todos los precios incluyen IVA.</Text>
                        ) : (
                            <Text>Ejemplo: Si necesitas más archivos de los incluidos en el plan básico, el costo adicional por cada archivo será de ${calculateWithVAT(archivo.pv, plan.iva)} hasta un máximo de {archivo.cvt}, después de lo cual se aplicará un costo adicional de ${calculateWithVAT(archivo.pav, plan.iva)} por cada archivo extra.</Text>
                        )}
                        <Text>Todos los precios incluyen IVA.</Text>
                    </VariableItem>
                ))}

                {plan.Marchivos && plan.Marchivos.length > 0 && plan.Marchivos.filter(archivo => archivo.pf !== -1).map((archivo, index) => (
                    <VariableItem key={index}>
                        <Text><strong>Almacenamiento de Archivos</strong></Text>
                        <Text>Cantidad de espacio del servidor destinada para almacenar archivos, medida en MB.</Text>
                        {!(archivo.pf === 0 && archivo.cf === 0) && (
                            <>
                                <Text><strong>Cantidad Fija:</strong> {archivo.cf} MB</Text>
                                <Text><strong>Precio Fijo:</strong> ${calculateWithVAT(archivo.pf, plan.iva)}</Text>
                            </>
                        )}
                        <Text><strong>Cantidad Variable:</strong> {archivo.cv} MB</Text>
                        <Text><strong>Precio Variable:</strong> ${calculateWithVAT(archivo.pv, plan.iva)}</Text>
                        <Text><strong>Cantidad Variable Total:</strong> {archivo.cvt} MB</Text>
                        <Text><strong>Precio Adicional Variable:</strong> ${calculateWithVAT(archivo.pav, plan.iva)}</Text>
                        {archivo.pf === 0 && archivo.cf === 0 ? (
                            <Text>Ejemplo: El plan es a demanda, si necesitas más almacenamiento para archivos, el costo adicional por cada MB será de ${calculateWithVAT(archivo.pv, plan.iva)}. Si necesitas más almacenamiento que ese nivel, puedes hacerlo pagando ${calculateWithVAT(archivo.pav, plan.iva)} por cada MB extra. Todos los precios incluyen IVA.</Text>
                        ) : (
                            <Text>Ejemplo: Si necesitas más almacenamiento para archivos de lo incluido en el plan básico, el costo adicional por cada MB será de ${calculateWithVAT(archivo.pv, plan.iva)} hasta un máximo de {archivo.cvt} MB, después de lo cual se aplicará un costo adicional de ${calculateWithVAT(archivo.pav, plan.iva)} por cada MB extra.</Text>
                        )}
                        <Text>Todos los precios incluyen IVA.</Text>
                    </VariableItem>
                ))}

                {plan.RXarchivos && plan.RXarchivos.length > 0 && plan.RXarchivos.filter(archivo => archivo.pf !== -1).map((archivo, index) => (
                    <VariableItem key={index}>
                        <Text><strong>Transferencia de Datos de Archivos</strong></Text>
                        <Text>Transferencia de datos por el uso de archivos, medida en MB. Esto se refiere a la cantidad de datos consumidos cuando los usuarios del sitio descargan o suben archivos, lo cual tiene un costo.</Text>
                        {!(archivo.pf === 0 && archivo.cf === 0) && (
                            <>
                                <Text><strong>Cantidad Fija:</strong> {archivo.cf} MB</Text>
                                <Text><strong>Precio Fijo:</strong> ${calculateWithVAT(archivo.pf, plan.iva)}</Text>
                            </>
                        )}
                        <Text><strong>Cantidad Variable:</strong> {archivo.cv} MB</Text>
                        <Text><strong>Precio Variable:</strong> ${calculateWithVAT(archivo.pv, plan.iva)}</Text>
                        <Text><strong>Cantidad Variable Total:</strong> {archivo.cvt} MB</Text>
                        <Text><strong>Precio Adicional Variable:</strong> ${calculateWithVAT(archivo.pav, plan.iva)}</Text>
                        {archivo.pf === 0 && archivo.cf === 0 ? (
                            <Text>Ejemplo: El plan es a demanda, si necesitas más transferencia de datos para archivos, el costo adicional por cada MB será de ${calculateWithVAT(archivo.pv, plan.iva)}. Si necesitas más transferencia que ese nivel, puedes hacerlo pagando ${calculateWithVAT(archivo.pav, plan.iva)} por cada MB extra. Todos los precios incluyen IVA.</Text>
                        ) : (
                            <Text>Ejemplo: Si necesitas más transferencia de datos para archivos de lo incluido en el plan básico, el costo adicional por cada MB será de ${calculateWithVAT(archivo.pv, plan.iva)} hasta un máximo de {archivo.cvt} MB, después de lo cual se aplicará un costo adicional de ${calculateWithVAT(archivo.pav, plan.iva)} por cada MB extra.</Text>
                        )}
                        <Text>Todos los precios incluyen IVA.</Text>
                    </VariableItem>
                ))}

                {plan.AdmIA && plan.AdmIA.length > 0 && plan.AdmIA.filter(admIA => admIA.pf !== -1).map((admIA, index) => (
                    <VariableItem key={index}>
                        <Text><strong>Administración con IA</strong></Text>
                        <Text>Servicios de administración basados en inteligencia artificial que optimizan la gestión y operación de la plataforma.</Text>
                        {!(admIA.pf === 0 && admIA.cf === 0) && (
                            <>
                                <Text><strong>Cantidad Fija:</strong> {admIA.cf}</Text>
                                <Text><strong>Precio Fijo:</strong> ${calculateWithVAT(admIA.pf, plan.iva)}</Text>
                            </>
                        )}
                        <Text><strong>Cantidad Variable:</strong> {admIA.cv}</Text>
                        <Text><strong>Precio Variable:</strong> ${calculateWithVAT(admIA.pv, plan.iva)}</Text>
                        <Text><strong>Cantidad Variable Total:</strong> {admIA.cvt}</Text>
                        <Text><strong>Precio Adicional Variable:</strong> ${calculateWithVAT(admIA.pav, plan.iva)}</Text>
                        {admIA.pf === 0 && admIA.cf === 0 ? (
                            <Text>Ejemplo: El plan es a demanda, si necesitas más servicios de administración con IA, el costo adicional por cada servicio será de ${calculateWithVAT(admIA.pv, plan.iva)}. Si necesitas más servicios que ese nivel, puedes hacerlo pagando ${calculateWithVAT(admIA.pav, plan.iva)} por cada servicio extra. Todos los precios incluyen IVA.</Text>
                        ) : (
                            <Text>Ejemplo: Si necesitas más servicios de administración con IA de los incluidos en el plan básico, el costo adicional por cada servicio será de ${calculateWithVAT(admIA.pv, plan.iva)} hasta un máximo de {admIA.cvt}, después de lo cual se aplicará un costo adicional de ${calculateWithVAT(admIA.pav, plan.iva)} por cada servicio extra.</Text>
                        )}
                        <Text>Todos los precios incluyen IVA.</Text>
                    </VariableItem>
                ))}

                {plan.mailCob && plan.mailCob.length > 0 && plan.mailCob.filter(mail => mail.pf !== -1).map((mail, index) => (
                    <VariableItem key={index}>
                        <Text><strong>Mails de Cobro</strong></Text>
                        <Text>Mails de cobro enviados a los usuarios, facilitando la gestión de pagos y la comunicación financiera con los clientes.</Text>
                        {!(mail.pf === 0 && mail.cf === 0) && (
                            <>
                                <Text><strong>Cantidad Fija:</strong> {mail.cf}</Text>
                                <Text><strong>Precio Fijo:</strong> ${calculateWithVAT(mail.pf, plan.iva)}</Text>
                            </>
                        )}
                        <Text><strong>Cantidad Variable:</strong> {mail.cv}</Text>
                        <Text><strong>Precio Variable:</strong> ${calculateWithVAT(mail.pv, plan.iva)}</Text>
                        <Text><strong>Cantidad Variable Total:</strong> {mail.cvt}</Text>
                        <Text><strong>Precio Adicional Variable:</strong> ${calculateWithVAT(mail.pav, plan.iva)}</Text>
                        {mail.pf === 0 && mail.cf === 0 ? (
                            <Text>Ejemplo: El plan es a demanda, si necesitas enviar más mails de cobro, el costo adicional por cada mail será de ${calculateWithVAT(mail.pv, plan.iva)}. Si necesitas enviar más mails que ese nivel, puedes hacerlo pagando ${calculateWithVAT(mail.pav, plan.iva)} por cada mail extra. Todos los precios incluyen IVA.</Text>
                        ) : (
                            <Text>Ejemplo: Si necesitas enviar más mails de cobro de los incluidos en el plan básico, el costo adicional por cada mail será de ${calculateWithVAT(mail.pv, plan.iva)} hasta un máximo de {mail.cvt}, después de lo cual se aplicará un costo adicional de ${calculateWithVAT(mail.pav, plan.iva)} por cada mail extra.</Text>
                        )}
                        <Text>Todos los precios incluyen IVA.</Text>
                    </VariableItem>
                ))}

            </TabContent>
            </Tab>

            <Tab>
              <Input type="checkbox" id="resumen" />
              <TabLabel htmlFor="resumen">Resumen de Tarifas fijas</TabLabel>
              <TabContent>
              <Text>En esta sección se resume el costo total mensual y anual del plan, incluyendo los descuentos aplicables:</Text>
                <Text><strong>Importante:</strong>A estas tarifas se les debe de añadir el componete variable, el cual dependera de las configuraciones especificas y la demanda del mismo.</Text>
                <Text><strong>Precio Final Mensual:</strong> ${precioFinalMensual.toFixed(2)}</Text>
                <Text><strong>Precio Final Anual:</strong> ${precioFinalAnual.toFixed(2)}</Text>
                <Text>Todos los precios incluyen IVA.</Text>
                <Text><strong>Tasas aplicadas:</strong></Text>
                {plan.bonificacionAnual !== undefined && plan.bonificacionAnual !== -1 && (
                <Text>
                    <strong>Bonificación Anual:</strong> {plan.bonificacionAnual} %<br />
                    <em>Descripción:</em> Descuento aplicado al precio anual del plan, si se realiza un pago completo por todo el año junto.
                </Text>
                )}
                {plan.bonifcacionAD !== undefined && plan.bonifcacionAD !== -1 && (
                <Text>
                    <strong>Descuento Promocional Mensual:</strong> {plan.bonifcacionAD} %<br />
                    <em>Descripción:</em> Descuento aplicado al precio a pagar mensual. Validez por tiempo limitado. 
                </Text>
                )}
                {plan.iva !== undefined && plan.iva !== -1 && (
                <Text>
                    <strong>IVA:</strong> {plan.iva} %<br />
                    <em>Descripción:</em> Porcentaje de Impuesto al Valor Agregado aplicado a los costos del plan.
                </Text>
                )}
                
              </TabContent>
            </Tab>
          </>
        )}
      </PlanContainer>
    </PageContainer>
  );
};

export default InfoPlan;
