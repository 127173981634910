import React, { useState } from 'react';

const Chat = () => {
  const [responseText, setResponseText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setResponseText('');
    setIsLoading(true);

    try {
      const response = await fetch('http://localhost:3000/api/chatai', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          // Aquí puedes enviar cualquier payload necesario para tu backend
        })
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let done = false;
      let result = '';

      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;
        result += decoder.decode(value, { stream: !done });

        const parts = result.split('\n\n');
        result = parts.pop(); // Deja la parte incompleta para la siguiente iteración
        const processedText = parts.map(part => {
          if (part.startsWith('data: ')) {
            return part.slice(6); // Elimina 'data: '
          }
          return '';
        }).join('');
        
        setResponseText((prevText) => prevText + processedText);
      }

      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching from API:', error);
      setIsLoading(false);
    }
  };

  return (
    <div className="App">
      <h1>Respuesta de OpenAI</h1>
      <button onClick={handleClick} disabled={isLoading}>
        {isLoading ? 'Cargando...' : 'Obtener Respuesta'}
      </button>
      <p>{responseText}</p>
    </div>
  );
};

export default Chat;





