import React, { useState, useEffect } from 'react';
import { editUser, getProfile } from '../api'; // Importar funciones de API
import styled from 'styled-components';
import { FaHome } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { handleRequestError } from './errorHandler';


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #1c1c1c;
  color: #ffffff;
  min-height: 100vh;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 780px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Header = styled.h1`
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 24px;
  }
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  margin-bottom: 20px;
  width: 100%;
`;

const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  margin-bottom: 20px;
  width: 100%;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #ffffff;
`;

const CheckBox = styled.input`
  margin-right: 10px;
`;

const FileInput = styled.input`
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 5px;
  width: 120px;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const HomeButton = styled(Button)`
  background-color: #6c757d;

  &:hover {
    background-color: #5a6268;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const FormFields = styled.div`
    flex: 2;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 20px;
  }
`;


const EditPerfil = () => {
  const [user, setUser] = useState(null);
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [identificationNumber, setIdentificationNumber] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [imagen, setImagen] = useState(null);
  const [imagenCargada, setImagenCargada] = useState(false);
  const [isPasswordChangeEnabled, setIsPasswordChangeEnabled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProfile();
        setUser(response.data);
        setId(response.data._id);
        setName(response.data.name);
        setEmail(response.data.email);
        setFirstName(response.data.firstName);
        setLastName(response.data.lastName);
        setBirthDate(response.data.birthDate);
        setIdentificationNumber(response.data.identificationNumber);
        setCellphone(response.data.cellphone);
        setDescripcion(response.data.descripcion);
      } catch (error) {
        handleRequestError(error, navigate); //si se da un error notifico y redirigo a inicio
        console.error('Error al recuperar datos:', error);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert('Las nuevas contraseñas no coinciden');
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('currentPassword', currentPassword);
    formData.append('newPassword', newPassword);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('birthDate', birthDate);
    formData.append('identificationNumber', identificationNumber);
    formData.append('cellphone', cellphone);
    formData.append('descripcion', descripcion);
    formData.append('imagen', imagen);

    try {
      await editUser(id, formData);
      alert('Usuario actualizado exitosamente');
      navigate('/user-home');
    } catch (error) {
      alert('Ingresaste mal tu contraseña actual? Error al actualizar el usuario intentalo de nuevo');
      console.error('Error al actualizar usuario:', error);
    }
  };

  const handleImagenChange = (e) => {
    setImagen(e.target.files[0]);
    setImagenCargada(true);
  };

  return (
    <PageContainer>
      <Header>Editar Usuario</Header>
      <FormContainer>
        <ImageContainer>
        {user && user.imagen && (
            <img src={`/imPerf/${user.imagen}`} alt="Perfil" style={{ maxHeight: '800px', maxWidth: '100%' }} />
          )}
        </ImageContainer>
        <FormFields>
          <form onSubmit={handleSubmit}>
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nombre de usuario"
              required
            />
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Correo electrónico"
              required
            />
            <CheckBoxContainer>
            <CheckBox
                type="checkbox"
                checked={isPasswordChangeEnabled}
                onChange={(e) => setIsPasswordChangeEnabled(e.target.checked)}
            /> Cambiar Contraseña
            </CheckBoxContainer>
            <Input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Nueva contraseña"
                disabled={!isPasswordChangeEnabled}
            />
            <Input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirmar nueva contraseña"
                disabled={!isPasswordChangeEnabled}
            />
            <Input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="Nombre"
              required
            />
            <Input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Apellido"
              required
            />
            <Input
              type="text"
              value={birthDate}
              onChange={(e) => setBirthDate(e.target.value)}
              placeholder="Fecha de nacimiento"
              required
            />
            <Input
              type="text"
              value={identificationNumber}
              onChange={(e) => setIdentificationNumber(e.target.value)}
              placeholder="Número de identificación"
              required
            />
            <Input
              type="text"
              value={cellphone}
              onChange={(e) => setCellphone(e.target.value)}
              placeholder="Número de celular"
              required
            />
            <TextArea
              type="text"
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
              placeholder="Descripcion breve"
              required
            />
            <FileInput
              type="file"
              onChange={handleImagenChange}
            />
            {imagenCargada && <p>Imagen cargada: {imagen.name}</p>}
            <Input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Contraseña actual para confirmar cambios"
              required
            />
            <ButtonContainer>
              <Button type="submit">Actualizar</Button>
              <HomeButton type="button" onClick={() => navigate('/user-home')}>
                <FaHome /> Inicio
              </HomeButton>
            </ButtonContainer>
          </form>
        </FormFields>
      </FormContainer>
    </PageContainer>
  );
};

export default EditPerfil;
