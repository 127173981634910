import axios from 'axios';

const API_URL = '/api';

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use(
  config => {
      const token = localStorage.getItem('token'); //obtiene el token almacenado en el localsotre del navegador del usuario que se almacena en el login
      if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
  },
  error => Promise.reject(error)
);

export const register = (userData) => {
  return api.post('/auth/register', userData);
};

export const login = (userData) => {
  return api.post('/auth/login', userData);
};

//estas lineas las incorpore yo pensando que faltan en la redireccion
export const verifyEmail = (userData) => { 
    return api.post('/auth/verifyEmail', userData);
  };
// Puedes agregar más funciones según sea necesario, las funciones de api se centran en un solo archivo

// Funciones para el manejo de usuarios en este caso para la api de control getprofile y update profile con ruta profile
export const getProfile = () => {
  return api.get('/user/profile');
};

export const updateProfile = (userData) => {
  return api.post('/user/profile', userData);
};

//funcion para editar usuario a nivel superAdmin
export const getUsers = () => {
  return api.get('/user');
};

export const updateUser = (userId, userData) => {
  return api.put(`/user/${userId}`, userData);
};

export const deleteUser = (userId) => {
  return api.delete(`/user/${userId}`);
};

//funcion que devuelve la informacion especifica de un user por su id
export const UserById = (id) => {
  return api.post('/user/userbyid', { id });
};

// Función para crear curso
export const createCourse = (courseData) => {
  return api.post('/user/courses', courseData);
};

export const getCourses = () => {
  //console.log('lamando a la api atento!!:'); // Log de depuración
  return api.get('/user/courses'); // Nueva función para obtener cursos
};

export const CoursesByInstitutoId = (institutoId) => {
  //console.log('lamando a la api atento!!:'); // Log de depuración
  return api.post(`/auth/courses/${institutoId}`); // Nueva función para obtener cursos
};

export const postCoursesWithCoupon = (institutoId) => {
  return api.post('/user/courses-with-coupon', {institutoId});
};

export const saveEmailList = (data) => {
  return api.post('/user/save', data);
};

// Generar el código de cupón
export const generateCouponCode = async ({ userId, courseId }) => {
  return api.post('/user/generate', { userId, courseId });
};

// Verificar el código de cupón
export const verifyCouponCode = async ({ userId, code, courseId }) => {
  return api.post('/user/verify', { userId, code, courseId });
};

//subir un documento a openai
//export const uploadFile = (filePath) => {
 // return api.post('/user/upload', { filePath });
//};
export const uploadFile = (formData) => {
  return api.post('/user/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

//consulta de fragmentos de un documento subido a openai
export const searchAndRespond = (query) => {
  return api.post('/user/search', { query });
};

//funcion que permite mostrar la respuesta del chat en modo strem
export const getChatResponse = (payload) => {
  return api.post('/user/chatai', payload, {
    responseType: 'stream'
  });
};

//funcion que genera o actualiza los datos de configuracion de un chat
export const createChat = (chatData) => {
  return api.post('/user/chats', chatData);
};

//funcion para obtener los chat ya generado por el usuario y poder darle opcion si quiere editar alguno
export const getUserChats = () => {
  return api.get('/user/user-chats');
};

//funcion para eliminar parametros de configuracion del chat
export const deleteChat = (chatId) => {
  return api.delete(`/user/chats/${chatId}`);
};

//funcion para actualizar parametros de configuracion del chat
export const updateChat = (chatId, chatData) => {
  return api.put(`/user/chats/${chatId}`, chatData);
};

//funcion para crear nuevo instituto propia del supadm
export const createInstituto = (data) => {
  return api.post('/user/instituto', data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

//funcion para recuperar los datos del instituto
export const getInstitutos = () => {
  return api.get('/user/instituto');
};

//asigna el instituto al usuario
export const assignAdminToInstituto = async (institutoId, adminId) => {
  return api.post(`/user/assign`, { institutoId, adminId });
};

//asigna el instituto al usuario
export const solicitudProfe = async (institutoId, profeId) => {
  return api.post(`/user/solicitudprofe`, { institutoId, profeId });
};

//permite desasignar el instituto del usuario
export const removeAdminFromInstituto = async (institutoId, adminId) => {
  return api.post(`/user/remove`, { institutoId, adminId });
};


//permite desasignar el instituto del usuario
export const getInstitutosByUser = (userId) => {
  return api.get(`/user/institutos/${userId}`);
};

//permite desasignar el instituto del usuario
export const InstitutosByUser = (institutoId) => {
  return api.get(`/auth/institutos/${institutoId}`);
};

//permite actualizar los datos del instituto por parte del usuario admin
export const updateInstituto = (institutoId, data) => {
  return api.put(`/user/institutos/${institutoId}`, data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

//carga la informacion de los institutos publicos
export const getInstituto = () => {
  return api.get('/auth/inicio');
};

//permite editar los datos del usuario
export const editUser = (Id, data) => {
  return api.put(`/user/editar/${Id}`, data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

//permite actualizar los parametros del plan de ventas
export const updatePlanVenta = (id, data) => {
  return api.put(`/user/updatePlanVentas/${id}`, data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

//permite crear un plan de ventas nuevo
export const createPlanVenta = (data) => {
  return api.post('/user/crearPlanVentas', data);
};

//carga la informacion del plan de ventas por id
export const getPlanVentaById = (id) => {
  return api.get(`/user/PlanVentasid//${id}`);
};


//carga la informacion del plan de ventas completa de la base
export const getPlanVentas = () => {
  return api.post('/user/PlanVentas');
};

export const getTrolas = () => {
  return api.get('/user/trola');
};

export const deletePlanVenta = (id) => {
  return api.delete(`/user/eliminarpv/${id}`);
};

//para mostrar los planes de venta publicos
export const postPlanVentas = () => {
  return api.post('/auth/postPlanVentas');
};

//para mostrar los planes de venta publicos seleccionado por id
export const publicPlanVentaById = (id) => {
  return api.post(`/auth/publicPlanVentaById/${id}`);
};

export const deleteServicio = (id) => {
  return api.delete(`/user/eliminarServicio/${id}`);
};

export const getServicioById = (id) => {
  return api.get(`/user/getServicio/${id}`);
};

export const updateServicio = (id, data) => {
  return api.put(`/user/updateServicio/${id}`, data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const createServicio = () => {
  return api.post('/user/crearServicio');
};

export const getAllServicios = () => {
  return api.post('/user/postServicio');
}; 


/*export const createNewCourse = () => {
  return api.post('/user/newcourse');
};*/

// Función para crear curso
export const createNewCourse = (courseData) => {
  return api.post('/user/newcourse', courseData);
};

export const cursosPorInstituto = (institutoId) => {
  return api.post(`/user/courseby/${institutoId}`);
};

export const cursoPorId = (id) => {
  return api.post(`/user/coursebyid/${id}`);
};

export const deleteCursoPorId = (id) => {
  return api.delete(`/user/coursedelete/${id}`);
};

export const updateCourse = (id, data) => {
  return api.put(`/user/upcourse/${id}`, data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

//permite enviar todos los cursos de un profe en un instituto especifico
export const CoursesByProfeAndInstitutoId = async (profeId, institutoId) => {
  return api.post(`/user/cursosprofe`, { profeId, institutoId });
};

//permite enviar todos los cursos de un profe en un instituto especifico
export const removeProfeFromCourse = async (cursoId, userId) => {
  return api.post(`/user/eliminoprofecurso`, { cursoId, userId });
}; 

//permite enviar todos los cursos de un profe en un instituto especifico
export const addProfeToCourse = async (cursoId, userId) => {
  return api.post(`/user/addprofecurso`, { cursoId, userId });
};

//permite enviar solo un curso especifico filtrado por id
export const CoursePorId = async (id) => {
  return api.post(`/auth/cursoporid`, { id });
};

//permite enviar solo ciertos datos de un user especifico, el profesor
export const UserDetailsById = async (id) => {
  return api.post(`/auth/profeid`, { id });
};

// Función para actualizar el pago de un curso
export const updatePagoCurso = (id, formData) => {
  return api.put('/user/updatepagocurso', 
    { id: id, ...formData },
    { headers: { 'Content-Type': 'application/json' } }
  );
};


// Función para actualizar el pago de un curso
export const createPagoCurso = (formData) => {
  return api.post('/user/crearpagocurso', 
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
};

//permite enviar solo ciertos datos de un user especifico, el profesor
export const PagoCursoPorId = async (id) => {
  return api.post(`/user/pagocursoinstid`, { id });
};

//permite enviar los parametros de dto aplicados a un curso especifico para su carga amnual
export const dtoByCurso = async (cursoId, institutoId) => {
  return api.post(`/user/dtoByCurso`, { cursoId, institutoId });
};

//permite enviar todos los usuarios de un instituto especifico
export const usuariosByInstituto = async (institutoId) => {
  return api.post(`/user/usersByInstituto`, { institutoId });
};

export const createConversation = (title) => {
  return api.post('/user/conversations', { title });
};

export const getConversations = () => {
  return api.get('/user/conversations');
};

export const getConversationById = (conversationId) => {
  return api.get(`/user/conversations/${conversationId}`);
};

export const sendMessage = (conversationId, content) => {
  return api.post(`/user/conversations/${conversationId}/messages`, { content });
};

export default api;

