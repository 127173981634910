import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { createGlobalStyle } from 'styled-components';


const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f0f4f8;
    color: #333;
  }
`;

ReactDOM.render(
  <React.StrictMode> {/* Añadimos React.StrictMode para detectar problemas potenciales */}
    <>
      <GlobalStyle /> {/* Mantener los estilos globales */}
      <App />
    </>
  </React.StrictMode>,
  document.getElementById('root')
);
