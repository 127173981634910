import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import Modal from './Modal';
import { getProfile, createCourse, getInstitutosByUser } from '../api';

// Estilos de los componentes
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: rgba(0, 0, 0, 0.9);
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  position: relative;
  text-align: center;
`;

const CloseIcon = styled(FaTimes)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
`;

const ModalButton = styled.button`
  background: #007bff;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  width: 60%;
  margin-top: 10px;

  &:hover {
    background: #0056b3;
  }
`;

const InputField = styled.input`
  background: #444;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  width: calc(100% - 20px);
  margin-right: 10px;

  &:focus {
    outline: none;
    background: #555;
  }
`;

const SmallInputField = styled.input`
  background: #444;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  width: calc(25% - 10px);
  margin-right: 10px;

  &:focus {
    outline: none;
    background: #555;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const TextAreaField = styled.textarea`
  background: #444;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  width: calc(100% - 20px);
  height: 200px;
  resize: none;
  box-sizing: border-box;
  overflow: auto;

  &:focus {
    outline: none;
    background: #555;
  }
`;

const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const CheckBoxLabel = styled.label`
  color: #fff;
  width: calc(25% - 10px);
  margin-bottom: 10px;
`;

const CheckBoxInput = styled.input`
  margin-right: 5px;
`;

const Form = styled.form``;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: ${spin} 2s linear infinite;
  margin: 0 auto;
`;

const SectionTitle = styled.h3`
  color: #fff;
  text-align: left;
  width: 100%;
`;

const InstituteSelector = styled.select`
  background: #444;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  width: 100%;

  &:focus {
    outline: none;
    background: #555;
  }
`;

// Componente principal
const CrearCurso = ({ isOpen, handleClose }) => {
  const [formData, setFormData] = useState({
    courseName: '',
    introduction: '',
    information: '',
    prices: {
      mensual: 0,
      bimensual: 0,
      cuatrimestral: 0,
    },
    promotion: '',
    checkpoints: {
      chat: false,
      video: false,
      quiz: false,
      pdf: false,
      links: false,
      mercado: false,
      cupon: false,
      autoRenewal: false,
      mostrar: false,
      precio: false,
    },
    creatorEmail: '',
    institutoId: '',
  });
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [institutos, setInstitutos] = useState([]);
  const [selectedInstituto, setSelectedInstituto] = useState(null);

  useEffect(() => {
    console.log('useEffect triggered');
    const fetchProfile = async () => {
      try {
        const response = await getProfile();

        const userId = response.data._id;
        const institutosResponse = await getInstitutosByUser(userId);
        setInstitutos(institutosResponse.data);

        // Si solo hay un instituto, seleccionarlo automáticamente
        if (institutosResponse.data.length === 1) {
          const instituto = institutosResponse.data[0];
          setSelectedInstituto(instituto);
          setFormData((prevData) => ({
            ...prevData,
            instituteId: instituto._id,
          }));
        }
        console.log('Profile fetched:', response.data);
        setFormData((prevData) => ({
          ...prevData,
          creatorEmail: response.data.email,
        }));
      } catch (error) {
        console.error('Error al obtener el perfil:', error);
      }
    };

    fetchProfile();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prevData) => ({
        ...prevData,
        checkpoints: {
          ...prevData.checkpoints,
          [name]: checked,
        },
      }));
    } else if (name.startsWith('prices.')) {
      const key = name.split('.')[1];
      setFormData((prevData) => ({
        ...prevData,
        prices: {
          ...prevData.prices,
          [key]: value,
        },
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const dataToSend = {
        ...formData,
        institutoId: selectedInstituto ? selectedInstituto._id : formData.institutoId,
      };
      console.log('Enviando datos:', dataToSend); // Verificar los datos antes de enviar
      const res = await createCourse(dataToSend);
      console.log('Course created:', res.data);
      setMessage(res.data.message);
      setShowModal(true);
      setIsSuccess(true);
    } catch (err) {
      console.error('Error creating course:', err.response.data.message);
      setMessage(err.response.data.message);
      setIsSuccess(false);
      setShowModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    if (isSuccess) {
      handleClose();
    }
  };

  const handleSelectChange = (e) => {
    const institutoId = e.target.value;
    const instituto = institutos.find((inst) => inst._id === institutoId);
    setSelectedInstituto(instituto);
    setFormData((prevData) => ({
      ...prevData,
      instituteId: institutoId,
    }));
  };

  if (!isOpen) return null;

  return (
    <>
      <ModalBackground>
        <ModalContent>
          <CloseIcon onClick={handleClose} />
          <Form onSubmit={handleSubmit}>
            <h2>Registro de Curso</h2>
            {institutos.length > 1 && (
              <InstituteSelector onChange={handleSelectChange}>
                <option value="">Seleccione un instituto</option>
                {institutos.map((instituto) => (
                  <option key={instituto._id} value={instituto._id}>
                    {instituto.nombre}
                  </option>
                ))}
              </InstituteSelector>
            )}
            <InputField
              type="text"
              name="courseName"
              placeholder="Nombre del Curso"
              onChange={handleChange}
              required
            />
            <InputField
              type="text"
              name="introduction"
              placeholder="Introducción"
              onChange={handleChange}
              required
            />
            <TextAreaField
              name="information"
              placeholder="Información"
              onChange={handleChange}
              required
            />
            <SectionTitle>Precios del tipo de plan</SectionTitle>
            <CheckBoxContainer>
              <SmallInputField
                type="number"
                name="prices.mensual"
                placeholder="Mensual"
                onChange={handleChange}
              />
              <SmallInputField
                type="number"
                name="prices.bimensual"
                placeholder="Bimensual"
                onChange={handleChange}
              />
              <SmallInputField
                type="number"
                name="prices.cuatrimestral"
                placeholder="Cuatrimestral"
                onChange={handleChange}
              />
            </CheckBoxContainer>
            <InputField
              type="text"
              name="promotion"
              placeholder="Promoción"
              onChange={handleChange}
            />
            <SectionTitle>Contenido</SectionTitle>
            <CheckBoxContainer>
              <CheckBoxLabel>
                <CheckBoxInput
                  type="checkbox"
                  name="chat"
                  checked={formData.checkpoints.chat}
                  onChange={handleChange}
                />
                Chat
              </CheckBoxLabel>
              <CheckBoxLabel>
                <CheckBoxInput
                  type="checkbox"
                  name="video"
                  checked={formData.checkpoints.video}
                  onChange={handleChange}
                />
                Video
              </CheckBoxLabel>
              <CheckBoxLabel>
                <CheckBoxInput
                  type="checkbox"
                  name="quiz"
                  checked={formData.checkpoints.quiz}
                  onChange={handleChange}
                />
                Cuestionario
              </CheckBoxLabel>
              <CheckBoxLabel>
                <CheckBoxInput
                  type="checkbox"
                  name="pdf"
                  checked={formData.checkpoints.pdf}
                  onChange={handleChange}
                />
                PDF
              </CheckBoxLabel>
              <CheckBoxLabel>
                <CheckBoxInput
                  type="checkbox"
                  name="links"
                  checked={formData.checkpoints.links}
                  onChange={handleChange}
                />
                Links
              </CheckBoxLabel>
            </CheckBoxContainer>
            <SectionTitle>Configuracion de Pago</SectionTitle>
            <CheckBoxContainer>
              <CheckBoxLabel>
                <CheckBoxInput
                  type="checkbox"
                  name="mercado"
                  checked={formData.checkpoints.mercado}
                  onChange={handleChange}
                />
                Mercado Pago
              </CheckBoxLabel>
              <CheckBoxLabel>
                <CheckBoxInput
                  type="checkbox"
                  name="cupon"
                  checked={formData.checkpoints.cupon}
                  onChange={handleChange}
                />
                Cupón
              </CheckBoxLabel>
              <CheckBoxLabel>
                <CheckBoxInput
                  type="checkbox"
                  name="autoRenewal"
                  checked={formData.checkpoints.autoRenewal}
                  onChange={handleChange}
                />
                Renovación Mensual
              </CheckBoxLabel>
              <CheckBoxLabel>
                <CheckBoxInput
                  type="checkbox"
                  name="mostrar"
                  checked={formData.checkpoints.mostrar}
                  onChange={handleChange}
                />
                Mostrar
              </CheckBoxLabel>
              <CheckBoxLabel style={{ opacity: formData.checkpoints.mostrar ? 1 : 0.5 }}>
                <CheckBoxInput
                  type="checkbox"
                  name="precio"
                  checked={formData.checkpoints.precio}
                  onChange={handleChange}
                  disabled={!formData.checkpoints.mostrar}
                />
                Precio
              </CheckBoxLabel>
            </CheckBoxContainer>
            <ModalButton type="submit" disabled={isLoading}>
              {isLoading ? <Loader /> : 'Registrar Curso'}
            </ModalButton>
          </Form>
        </ModalContent>
      </ModalBackground>
      {showModal && <Modal message={message} onClose={closeModal} />}
    </>
  );
};

export default CrearCurso;


