import React, { useState, useEffect } from 'react';
import { updateInstituto, getInstitutosByUser, getProfile } from '../api'; // Importar funciones de API
import styled from 'styled-components';
import { FaHome } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { handleRequestError } from './errorHandler';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #1c1c1c;
  color: #ffffff;
  min-height: 100vh;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 780px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Header = styled.h1`
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 24px;
  }
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  margin-bottom: 20px;
  width: 100%;
`;

const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  margin-bottom: 20px;
  width: 100%;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #ffffff;
`;

const CheckBox = styled.input`
  margin-right: 10px;
`;

const FileInputButton = styled.label`
  background-color: #007bff;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 20px;
  margin-left: 20px;

  &:hover {
    background-color: #0056b3;
  }
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 5px;
  width: 120px;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const HomeButton = styled(Button)`
  background-color: #6c757d;

  &:hover {
    background-color: #5a6268;
  }
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #444;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  visibility: ${props => props.show ? 'visible' : 'hidden'};
`;

const ProgressBar = styled.div`
  width: ${props => props.progress}%;
  background-color: #007bff;
  height: 20px;
  transition: width 0.5s;
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const VideoContainer = styled.video`
  width: 100%;
  max-height: 400px;
  margin-top: 20px;
`;

const FormFields = styled.div`
  flex: 2;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 20px;
  }
`;

const InstituteSelector = styled.select`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  margin-bottom: 20px;
  width: 100%;
`;

const EditInstituto = () => {
  const [institutos, setInstitutos] = useState([]);
  const [selectedInstituto, setSelectedInstituto] = useState(null);
  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [materias, setMaterias] = useState('');
  const [contacto, setContacto] = useState('');
  const [publico, setPublico] = useState(false);
  const [imagen, setImagen] = useState(null);
  const [video, setVideo] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const profileResponse = await getProfile();
        const userId = profileResponse.data._id;
        const institutosResponse = await getInstitutosByUser(userId);
        setInstitutos(institutosResponse.data);

        if (institutosResponse.data.length === 1) {
          const instituto = institutosResponse.data[0];
          setSelectedInstituto(instituto);
          setNombre(instituto.nombre);
          setDescripcion(instituto.descripcion);
          setMaterias(instituto.materias);
          setContacto(instituto.contacto);
          setPublico(instituto.publico);
        }
      } catch (error) {
        handleRequestError(error, navigate); // Si se da un error notifico y redirijo a inicio
        console.error('Error al recuperar datos:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (uploading) {
      const totalSize = (imagen ? imagen.size : 0) + (video ? video.size : 0);
      let uploaded = 0;

      const interval = setInterval(() => {
        uploaded += 2 * 1024 * 1024; // 2MB por segundo
        const progressPercentage = Math.min((uploaded / totalSize) * 100, 99);
        setUploadProgress(Math.floor(progressPercentage));

        if (progressPercentage >= 99) {
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [uploading, imagen, video]);

  const handleSelectChange = (e) => {
    const institutoId = e.target.value;
    const instituto = institutos.find(inst => inst._id === institutoId);
    setSelectedInstituto(instituto);
    setNombre(instituto.nombre);
    setDescripcion(instituto.descripcion);
    setMaterias(instituto.materias);
    setContacto(instituto.contacto);
    setPublico(instituto.publico);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedInstituto) {
      alert('Seleccione un instituto.');
      return;
    }

    const formData = new FormData();
    formData.append('nombre', nombre);
    formData.append('descripcion', descripcion);
    formData.append('materias', materias);
    formData.append('contacto', contacto);
    formData.append('publico', publico);
    if (imagen) formData.append('imagen', imagen);
    if (video) formData.append('video', video);

    setUploading(true);

    try {
      await updateInstituto(selectedInstituto._id, formData);
      setUploadProgress(100);
      alert('Instituto actualizado exitosamente');
      navigate('/admin-home');
    } catch (error) {
      console.error('Error al actualizar instituto:', error);
      setUploading(false);
    }
  };

  const handleImagenChange = (e) => {
    setImagen(e.target.files[0]);
  };

  const handleVideoChange = (e) => {
    setVideo(e.target.files[0]);
  };

  return (
    <PageContainer>
      <Header>Editar Instituto</Header>
      <FormContainer>
        <ImageContainer>
          {selectedInstituto && selectedInstituto.imagen && (
            <img src={`/imInst/${selectedInstituto.imagen}`} alt="Instituto" style={{ maxHeight: '800px', maxWidth: '100%' }} />
          )}
          {selectedInstituto && selectedInstituto.video && (
            <VideoContainer controls>
              <source src={`/videosPromo/${selectedInstituto.video}`} type="video/mp4" />
              Your browser does not support the video tag.
            </VideoContainer>
          )}
        </ImageContainer>
        <FormFields>
          {institutos.length > 1 && (
            <InstituteSelector onChange={handleSelectChange}>
              <option value="">Seleccione un instituto</option>
              {institutos.map((instituto) => (
                <option key={instituto._id} value={instituto._id}>
                  {instituto.nombre}
                </option>
              ))}
            </InstituteSelector>
          )}
          <form onSubmit={handleSubmit}>
            <Input
              type="text"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              placeholder="Nombre"
              required
            />
            <TextArea
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
              placeholder="Descripción"
              required
            />
            <TextArea
              value={materias}
              onChange={(e) => setMaterias(e.target.value)}
              placeholder="Materias"
              required
            />
            <Input
              type="text"
              value={contacto}
              onChange={(e) => setContacto(e.target.value)}
              placeholder="Número de contacto"
            />
            <CheckBoxContainer>
              <CheckBox
                type="checkbox"
                checked={publico}
                onChange={(e) => setPublico(e.target.checked)}
              /> Público
            </CheckBoxContainer>
            <FileInputButton htmlFor="file-input-imagen">
              Cargar Imagen
            </FileInputButton>
            <HiddenFileInput
              id="file-input-imagen"
              type="file"
              onChange={handleImagenChange}
            />
            {imagen && <p>Imagen cargada: {imagen.name}</p>}
            <FileInputButton htmlFor="file-input-video">
              Cargar Video
            </FileInputButton>
            <HiddenFileInput
              id="file-input-video"
              type="file"
              onChange={handleVideoChange}
            />
            {video && <p>Video cargado: {video.name}</p>}
            <ProgressBarContainer show={uploading}>
              <ProgressBar progress={uploadProgress} />
            </ProgressBarContainer>
            <ButtonContainer>
              <Button type="submit">Actualizar</Button>
              <HomeButton type="button" onClick={() => navigate('/admin-home')}>
                <FaHome /> Inicio
              </HomeButton>
            </ButtonContainer>
          </form>
        </FormFields>
      </FormContainer>
    </PageContainer>
  );
};

export default EditInstituto;


