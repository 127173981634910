import React, { useState, useEffect } from 'react';
import { postCoursesWithCoupon, saveEmailList, getProfile, getInstitutosByUser } from '../api';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';

// Estilos del modal
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: rgba(0, 0, 0, 0.9);
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  position: relative;
  text-align: center;
`;

const CloseIcon = styled(FaTimes)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
`;

const ModalButton = styled.button`
  background: #007bff;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  width: 60%;
  margin-top: 10px;

  &:hover {
    background: #0056b3;
  }
`;

const InputField = styled.input`
  background: #444;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    background: #555;
  }
`;

const TextArea = styled.textarea`
  background: #444;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    background: #555;
  }
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InstituteSelector = styled.select`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  margin-bottom: 20px;
  width: 30%;
`;

const EmailListForm = ({ isOpen, handleClose }) => { // Agregar isOpen y handleClose como props
  const [emails, setEmails] = useState('');
  const [name, setName] = useState('');
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedInstituto, setSelectedInstituto] = useState('');
  const [institutos, setInstitutos] = useState([]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getProfile();
        const userId = response.data._id;
        const institutosResponse = await getInstitutosByUser(userId);
        setInstitutos(institutosResponse.data);

        if (institutosResponse.data.length === 1) {
          const instituto = institutosResponse.data[0];
          setSelectedInstituto(instituto);
        }

      } catch (error) {
        console.error('Error al obtener el perfil:', error);
      }
    };

    fetchProfile();
  }, []);
 
  useEffect(() => {
    const fetchCourses = async () => {
      if(selectedInstituto) {
        const institutoId = selectedInstituto._id;
      try {
        const response = await postCoursesWithCoupon(institutoId);
        setCourses(response.data);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    }
    };
    fetchCourses();
  }, [selectedInstituto]);

  const handleInputChange = (e) => {
    setEmails(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleCourseChange = (e) => {
    const value = e.target.value;
    setSelectedCourses(prevSelectedCourses =>
      prevSelectedCourses.includes(value)
        ? prevSelectedCourses.filter(course => course !== value)
        : [...prevSelectedCourses, value]
    );
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const emailArray = emails.split(/[\s,;,\n]+/).map(email => email.trim()).filter(email => email);
    const validEmailArray = emailArray.filter(email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
    
    const data = { name, emails: validEmailArray, courseIds: selectedCourses };
    //console.log('Datos a enviar:', data);
    
    try {
      const response = await saveEmailList(data);
      console.log('Respuesta del servidor:', response);
      alert('Lista de correos guardada exitosamente');
      handleClose(); // Cerrar el modal después de guardar la lista de correos exitosamente
    } catch (error) {
      console.error('Error guardando la lista de correos:', error);
      alert('Error al guardar la lista de correos');
    }
  }; 

  const handleSelectChange = (e) => {
    const institutoId = e.target.value;
    const instituto = institutos.find(inst => inst._id === institutoId);
    setSelectedInstituto(instituto);
  };

  // Verificar si isOpen es falso y retornar null para no renderizar el modal
  if (!isOpen) return null;

  return (
    <ModalBackground>
      <ModalContent>
      {institutos.length > 1 && (
            <InstituteSelector onChange={handleSelectChange}>
            <option value="">Seleccione un instituto</option>
            {institutos.map((instituto) => (
              <option key={instituto._id} value={instituto._id}>
                {instituto.nombre}
              </option>
            ))}
          </InstituteSelector>
        )}
        <CloseIcon onClick={handleClose} /> {/* Usar la función handleClose para cerrar el modal */}
        <Form onSubmit={handleFormSubmit}>
          <h2>Formulario de Lista de Correos</h2>
          <InputField 
            type="text" 
            value={name} 
            onChange={handleNameChange} 
            placeholder="Nombre de la lista" 
            required 
          />
          <TextArea 
            value={emails} 
            onChange={handleInputChange} 
            placeholder="Pega tus correos aquí, separados por espacios, comas, puntos y comas o nuevas líneas"
            rows="10"
          />
          <div>
            <label>Selecciona cursos:</label>
            {courses.map(course => (
              <div key={course._id}>
                <Checkbox 
                  type="checkbox" 
                  value={course._id} 
                  checked={selectedCourses.includes(course._id)} 
                  onChange={handleCourseChange} 
                />
                <label>{course.courseName}</label>
              </div>
            ))}
          </div>
          <ModalButton type="submit">Enviar</ModalButton>
        </Form>
      </ModalContent>
    </ModalBackground>
  );
};

export default EmailListForm;

