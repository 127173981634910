import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import CoursesPublic from './pages/CursosPublic';
import VerifyEmailPage from './pages/VerifyEmailPage';
import Register from './components/Auth/Register';
import Login from './components/Auth/Login';
import TokenExpirado from './pages/TokenExpirado';
import TokenInvalido from './pages/TokenInvalido';
import UsuarioNoExiste from './pages/UsuarioNoExiste';
import UsuarioVerificado from './pages/UsuarioVerificado';
import UserPage1 from './components/UserPage1';
import AdminHome from './components/AdminHome';
import UserHome from './components/UserHome';
import CrearCurso from './components/CrearCurso';
import AdminPage2 from './components/AdminPage2';
import SuperAdmin from './components/SuperAdmin';
import PrivateRoute from './components/PrivateRoute';
import BuscarArchivo from './components/BuscarArchivo';
import Buscar from './components/BuscarGPT';
import Chat from './components/Chat';
import OpenChat from './components/OpenChat';
import ChatOn from './components/ChatOn';
import CrearChat from './components/CrearChat';
import NewInstituto from './components/NewInstituto';
import EditInstituto from './components/EditInstituto';
import InstitutoHome from './components/InstitutoHome';
import EditPerfil from './components/EditPerfil';
import PlanVenta from './components/PlanVenta';
import InfoPlan from './components/InfoPlan';
import AllPlanes from './components/AllPlanes';
import PlanesComerciales from './components/PlanesComerciales';
import DescripcionComercial from './components/DescripcionComercial';
import CrearServicio from './components/CrearServicio';
import Bienvenida from './components/Bienvenida';
import Servicios from './pages/Servicios';
import Institutos from './pages/Institutos';
import PerfilInstituto from './pages/PerfilInstituto';
import EditorCursos from './components/EditorCursos';
import ProfeAdmin from './components/ProfeAdmin';
import DataCurso from './pages/DataCurso';
import PagoCursoEditor from './components/PagoCursoEditor';
import MatricularAlumnos from './components/MatricularAlumnos';
import CalendarWithoutIcal from './pages/CalendarWithoutIcal';
import ChatPage from './components/ChatPage';


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/inicio" element={<HomePage />} />
        <Route path="/cursos" element={<CoursesPublic />} /> 
        <Route path="/chat" element={<Chat />} /> 
        <Route path="/verify-email" element={<VerifyEmailPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/token-expired" element={<TokenExpirado/>} />
        <Route path="/invalid-token" element={<TokenInvalido />} />
        <Route path="/user-not-found" element={<UsuarioNoExiste />} />
        <Route path="/already-verified" element={<UsuarioVerificado />} />
        <Route path="/info-plan" element={<InfoPlan />} />  
        <Route path="/info-plan/:id" element={<InfoPlan />} /> 
        <Route path="/planes-comerciales" element={<PlanesComerciales />} /> 
        <Route path="/descripcion-comercial" element={<DescripcionComercial />} />
        <Route path="/descripcion-comercial/:id" element={<DescripcionComercial />} />
        <Route path="/institutos" element={<Institutos />} />
        <Route path="/servicio" element={<Servicios />} />
        <Route path="/perfil-instituto/:institutoId" element={<PerfilInstituto />} />
        <Route path="/info-curso/:id" element={<DataCurso />} />
        <Route path="/calendario" element={<CalendarWithoutIcal />} />
        <Route path="/user-page1" element={<PrivateRoute roles={['user', 'profe', 'admin', 'supadm']}><UserPage1 /></PrivateRoute>} /> 
        <Route path="/admin-home" element={<PrivateRoute roles={['admin', 'profe', 'supadm']}><AdminHome /></PrivateRoute>} />
        <Route path="/user-home" element={<PrivateRoute roles={['user', 'profe', 'admin', 'supadm']}><UserHome /></PrivateRoute>} />
        <Route path="/buscar" element={<PrivateRoute roles={['user', 'profe', 'admin', 'supadm']}><Buscar /></PrivateRoute>} />
        <Route path="/open-chat" element={<PrivateRoute roles={['user', 'profe', 'admin', 'supadm']}><OpenChat /></PrivateRoute>} />
        <Route path="/chat-on" element={<PrivateRoute roles={['user', 'profe', 'admin', 'supadm']}><ChatOn /></PrivateRoute>} />
        <Route path="/bienvenida" element={<PrivateRoute roles={['user', 'profe', 'admin', 'supadm']}><Bienvenida /></PrivateRoute>} />
        <Route path="/instituto-home" element={<PrivateRoute roles={['user', 'profe', 'admin', 'supadm']}><InstitutoHome /></PrivateRoute>} />
        <Route path="/editar-perfil" element={<PrivateRoute roles={['user', 'profe', 'admin', 'supadm']}><EditPerfil /></PrivateRoute>} />
        <Route path="/subir-archivo" element={<PrivateRoute roles={['profe', 'admin', 'supadm']}><BuscarArchivo /></PrivateRoute>} />

        <Route path="/chatpage" element={<PrivateRoute roles={['admin', 'supadm']}><ChatPage /></PrivateRoute>} />
        <Route path="/matricular-alumnos" element={<PrivateRoute roles={['admin', 'supadm']}><MatricularAlumnos /></PrivateRoute>} />
        <Route path="/editar-pagos-cursos" element={<PrivateRoute roles={['admin', 'supadm']}><PagoCursoEditor /></PrivateRoute>} />
        <Route path="/administrar-profesores" element={<PrivateRoute roles={['admin', 'supadm']}><ProfeAdmin /></PrivateRoute>} />
        <Route path="/Editor-curso" element={<PrivateRoute roles={['admin', 'supadm']}><EditorCursos /></PrivateRoute>} />
        <Route path="/crear-curso" element={<PrivateRoute roles={['admin', 'supadm']}><CrearCurso /></PrivateRoute>} />
        <Route path="/admin-page2" element={<PrivateRoute roles={['admin', 'supadm']}><AdminPage2 /></PrivateRoute>} />
        <Route path="/crear-chat" element={<PrivateRoute roles={['profe', 'admin', 'supadm']}><CrearChat /></PrivateRoute>} />
        <Route path="/edit-instituto" element={<PrivateRoute roles={['admin', 'supadm']}><EditInstituto /></PrivateRoute>} />
        <Route path="/super-admin" element={<PrivateRoute roles={['supadm']}><SuperAdmin /></PrivateRoute>} />
        <Route path="/new-instituto" element={<PrivateRoute roles={['supadm']}><NewInstituto /></PrivateRoute>} />
        <Route path="/plan-venta" element={<PrivateRoute roles={['supadm']}><PlanVenta /></PrivateRoute>} />
        <Route path="/all-planes" element={<PrivateRoute roles={['supadm']}><AllPlanes /></PrivateRoute>} /> 
        <Route path="/crear-servicio" element={<PrivateRoute roles={['supadm']}><CrearServicio /></PrivateRoute>} /> 
      </Routes>
    </Router>
  );
};


export default App;


