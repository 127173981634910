import React, { useState } from 'react';
import styled from 'styled-components';
import ConversationList from './ConversationList';
import ChatWindow from './ChatWindow';

const PageContainer = styled.div`
    display: flex;
    height: 100vh;

    @media (max-width: 768px) {
        flex-direction: column;
        height: auto;
    }
`;

const NoConversation = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background-color: #1c1c1c;
    font-size: 1.2em;
`;

const ChatPage = () => {
    const [selectedConversation, setSelectedConversation] = useState(null);

    const handleSelectConversation = (conversation) => {
        setSelectedConversation(conversation);
    };

    return (
        <PageContainer>
            <ConversationList onSelect={handleSelectConversation} />
            {selectedConversation ? (
                <ChatWindow conversationId={selectedConversation._id} />
            ) : (
                <NoConversation>Selecciona una conversación o crea una nueva.</NoConversation>
            )}
        </PageContainer>
    );
};

export default ChatPage;
