import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';

// Estilos
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: rgba(0, 0, 0, 0.9);
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  position: relative;
  text-align: center;
`;

const CloseIcon = styled(FaTimes)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
`;

const ModalButton = styled.button`
  background: #007bff;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  width: 60%;
  margin-top: 10px;

  &:hover {
    background: #0056b3;
  }
`;

const InputField = styled.input`
  background: #444;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    background: #555;
  }
`;

const Form = styled.form``;

const Countdown = styled.div`
  color: #fff;
  margin-top: 10px;
`;

const ResetPassword = ({ isOpen, handleClose }) => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [countdown, setCountdown] = useState(120); // 2 minutos en segundos

  useEffect(() => {
    let timer;
    if (step === 2 && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      handleClose();
    }
    return () => clearInterval(timer);
  }, [step, countdown]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') setEmail(value);
    if (name === 'code') setCode(value);
    if (name === 'newPassword') setNewPassword(value);
    if (name === 'confirmPassword') setConfirmPassword(value);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('/api/auth/sendResetPasswordCode', { email });
      setMessage(res.data.message);
      setShowMessage(true);
      setStep(2);
    } catch (err) {
      setMessage(err.response.data.message);
      setShowMessage(true);
    }
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage('Las contraseñas no coinciden');
      setShowMessage(true);
      return;
    }
    try {
      const res = await axios.post('/api/auth/verifyResetPasswordCode', { email, code, newPassword });
      setMessage(res.data.message);
      setShowMessage(true);
      setStep(1);
      handleClose();
    } catch (err) {
      setMessage(err.response.data.message);
      setShowMessage(true);
    }
  };

  const closeModal = () => {
    setShowMessage(false);
    handleClose();
  };

  if (!isOpen) return null;

  return (
    <ModalBackground>
      <ModalContent>
        <CloseIcon onClick={closeModal} />
        <h2>Restablecer Contraseña</h2>
        {step === 1 && (
          <Form onSubmit={handleEmailSubmit}>
            <InputField
              type="email"
              name="email"
              placeholder="Correo Electrónico"
              onChange={handleChange}
              required
            />
            <ModalButton type="submit">Enviar Código</ModalButton>
          </Form>
        )}
        {step === 2 && (
          <Form onSubmit={handleCodeSubmit}>
            <InputField
              type="text"
              name="code"
              placeholder="Código de Verificación"
              onChange={handleChange}
              required
            />
            <InputField
              type="password"
              name="newPassword"
              placeholder="Nueva Contraseña"
              onChange={handleChange}
              required
            />
            <InputField
              type="password"
              name="confirmPassword"
              placeholder="Confirmar Contraseña"
              onChange={handleChange}
              required
            />
            <ModalButton type="submit">Restablecer Contraseña</ModalButton>
            <Countdown>Tiempo restante: {Math.floor(countdown / 60)}:{countdown % 60 < 10 ? `0${countdown % 60}` : countdown % 60}</Countdown>
          </Form>
        )}
        {showMessage && <p>{message}</p>}
      </ModalContent>
    </ModalBackground>
  );
};

export default ResetPassword;
