import React from 'react';
import styled from 'styled-components';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: rgba(0, 0, 0, 0.9);
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  position: relative;
  text-align: center;
`;

const CloseButton = styled.button`
  background: #007bff;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  width: 60%;
  margin-top: 20px;

  &:hover {
    background: #0056b3;
  }
`;

const Modal = ({ message, onClose }) => {

  return (

    <ModalBackground>
      <ModalContent>
        <p>{message}</p>
        <CloseButton onClick={onClose}>Aceptar</CloseButton>
      </ModalContent>
    </ModalBackground>

  );
};

export default Modal;











