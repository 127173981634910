import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';
import { FaBars, FaTwitter, FaInstagram, FaLinkedin, FaWhatsapp, FaGraduationCap, FaRegListAlt, FaServicestack, FaInfoCircle, FaEnvelope, FaSignInAlt } from 'react-icons/fa';
//import { handleRequestError } from './errorHandler';
import axios from 'axios';
import { getProfile } from '../api';


// Estilos y componentes existentes
const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1c1c1c;
  color: #ffffff;
  overflow-x: hidden;
`;

const Header = styled.header`
  width: 100%;
  padding: 10px 20px;
  background-color: #1c1c1c;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ffffff;
  background-color: #1c1c1c;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  height: 40px;
`;

const Title = styled.h1`
  font-family: Arial, sans-serif;
  font-size: 24px;
  color: #ffffff;
  margin-left: 10px;
`;

const NavMenu = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const MenuButton = styled(FaBars)`
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 20px;
`;

const DropdownMenu = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  top: 0; /* Ajustar para mover el menú hacia arriba */
  right: 0; /* Alinear a la derecha */
  background-color: #1c1c1c;
  border: 1px solid #ffffff;
  border-radius: 5px;
  z-index: 1;
`;

const MenuItem = styled(Link)`
  display: block;
  padding: 10px 20px;
  color: #ffffff;
  text-decoration: none;

  &:hover {
    background-color: #333333;
        color: #007bff; /* Cambia el color del texto y del icono */

    svg { /* Aplica el color azul también a los iconos */
      color: #007bff;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 0 30px; /* Ajustar padding para pantallas pequeñas */

    @media (min-width: 768px) {
    padding: 0; /* Eliminar padding adicional en pantallas más grandes */
  }
`;

const Footer = styled.footer`
  background-color: #1c1c1c;
  padding: 20px;
  text-align: center;
  color: #ffffff;
  width: 100%;
`;

const SocialIcons = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const SocialIcon = styled.a`
  color: #ffffff;
  font-size: 24px;
  text-decoration: none;
  &:hover {
    color: #007bff;
  }
`;

const InstitutoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px; /* Limitar el ancho máximo de las tarjetas */
  margin: 20px; /* Agregar margen para separar las etiquetas */
  margin-bottom: 20px; /* Espacio entre las filas */
`;

const InstitutoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  padding: 20px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const InstitutoTitle = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
`;

const InstitutoImage = styled.img`
  max-width: 100%;
  max-height: 200px; /* Limitar la altura máxima de la imagen */
  object-fit: cover; /* Asegurar que la imagen se recorte adecuadamente */
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 10px;
`;

const InstitutoDescription = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
  text-align: center;
`;

const ContactoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const ContactoLink = styled.a`
  display: flex;
  align-items: center;
  color: #25d366;
  text-decoration: none;
  font-size: 16px;
  margin-left: 5px;
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-left: 5px;
`;

const Spinner = styled.div`
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-top: 8px solid #007bff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
  margin: auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Institutos = () => {
  const [institutos, setInstitutos] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [perfil, setPerfil] = useState([null]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getProfile();
        setPerfil(response.data);
        if(response.data !== null) {
            navigate('/instituto-home'); //redirecciono a instituto home si estoy logueado
        }
      } catch (error) {
        setPerfil(null);
        console.log('Error al obtener el perfil:', error);
      }
    };

    fetchProfile();
}, []);


  useEffect(() => {
    const fetchInstitutos = async () => {
      try {
        const InstitutoResponse = await axios.post('/api/auth/institutos'); // Usamos getProfile para obtener los datos
        setInstitutos(InstitutoResponse.data);
      } catch (error) {
        //handleRequestError(error, navigate); //si se da un error notifico y redirecciono a inicio
        console.error('Error al obtener los datos del instituto:', error);
      }
    };

    fetchInstitutos();
  }, []);

  
  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setMenuOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setMenuOpen(false);
    }, 200); // Mantener el menú visible durante 200 ms después de alejar el puntero
  };

  const handleContactoClick = (e) => {
    e.stopPropagation(); // Evita que el clic en el enlace de contacto active el enlace principal
  };

  if (perfil !== null) { //para que no se muestre dos veces la redireccion muestra esto en caso de demora
    return (
        <HomeContainer>
            <Header>
                <LogoContainer>
                <Logo src="/images/logo.svg" alt="Logo" />
                <Title>estudiochat</Title>
                </LogoContainer>
                <NavMenu
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                >
                <DropdownMenu isOpen={menuOpen}>
                <DropdownHeader>
                <Logo src="/images/logo.svg" alt="Logo" height="20px" />
                <Title style={{ fontSize: '16px', margin: '0 0 0 10px' }}>Menu</Title>
                    </DropdownHeader>
                    <MenuItem to="/institutos"><FaGraduationCap style={{ marginRight: '8px' }} />Institutos</MenuItem>
                    <MenuItem to="/planes-comerciales"><FaRegListAlt style={{ marginRight: '8px' }} />Nuestros Planes</MenuItem>
                    <MenuItem to="/servicio"><FaServicestack style={{ marginRight: '8px' }} />Servicios</MenuItem>
                    <MenuItem to="/quienes-somos"><FaInfoCircle style={{ marginRight: '8px' }} />Quienes Somos</MenuItem>
                    <MenuItem to="/contacto"><FaEnvelope style={{ marginRight: '8px' }} />Contacto</MenuItem>
                    <MenuItem to="/"><FaSignInAlt style={{ marginRight: '8px' }} />Inicio</MenuItem>
                </DropdownMenu>
                <MenuButton onClick={() => setMenuOpen(!menuOpen)} />
                </NavMenu>
            </Header>
            <InstitutoDescription>
                <Spinner /> {/* Spinner de carga */}
            </InstitutoDescription>
            <Footer>
            <p>&copy; 2024 Todos los derechos reservados a <a href="https://www.labordt.com">Labor D&T</a></p>
            <SocialIcons>
            <SocialIcon href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></SocialIcon>
            <SocialIcon href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></SocialIcon>
            <SocialIcon href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></SocialIcon>
            </SocialIcons>
        </Footer>
        </HomeContainer>);
  }

  if (!institutos) {
    return <p>Cargando...</p>;
  }


  return (
    <HomeContainer>
      <Header>
        <LogoContainer>
          <Logo src="/images/logo.svg" alt="Logo" />
          <Title>estudiochat</Title>
        </LogoContainer>
        <NavMenu
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        >
          <DropdownMenu isOpen={menuOpen}>
          <DropdownHeader>
          <Logo src="/images/logo.svg" alt="Logo" height="20px" />
          <Title style={{ fontSize: '16px', margin: '0 0 0 10px' }}>Menu</Title>
            </DropdownHeader>
            <MenuItem to="/institutos"><FaGraduationCap style={{ marginRight: '8px' }} />Institutos</MenuItem>
            <MenuItem to="/planes-comerciales"><FaRegListAlt style={{ marginRight: '8px' }} />Nuestros Planes</MenuItem>
            <MenuItem to="/servicio"><FaServicestack style={{ marginRight: '8px' }} />Servicios</MenuItem>
            <MenuItem to="/quienes-somos"><FaInfoCircle style={{ marginRight: '8px' }} />Quienes Somos</MenuItem>
            <MenuItem to="/contacto"><FaEnvelope style={{ marginRight: '8px' }} />Contacto</MenuItem>
            <MenuItem to="/"><FaSignInAlt style={{ marginRight: '8px' }} />Inicio</MenuItem>
          </DropdownMenu>
          <MenuButton onClick={() => setMenuOpen(!menuOpen)} />
        </NavMenu>
      </Header>
      <MainContent>
      <InstitutoGrid>
      {institutos.map(instituto => (
        <Link to={`/perfil-instituto/${instituto._id}`} key={instituto.id} style={{ textDecoration: 'none', color: 'inherit' }}>
          <InstitutoContainer>
            <InstitutoTitle>{instituto.nombre}</InstitutoTitle>
            <InstitutoImage
              src={`/imInst/${instituto.imagen}`}
              alt={instituto.nombre}
            />
            <InstitutoDescription>{instituto.descripcion}</InstitutoDescription>
            {instituto.contacto && (
              <ContactoContainer onClick={handleContactoClick}>
                <FaWhatsapp size={24} color="#25D366" />
                <ContactoLink href={`https://wa.me/${instituto.contacto}`} target="_blank" rel="noopener noreferrer">
                  {instituto.contacto}
                </ContactoLink>
              </ContactoContainer>
            )}
          </InstitutoContainer>
        </Link>
      ))}
    </InstitutoGrid>
      </MainContent>
      <Footer>
        <p>&copy; 2024 Todos los derechos reservados a <a href="https://www.labordt.com">Labor D&T</a></p>
        <SocialIcons>
          <SocialIcon href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></SocialIcon>
          <SocialIcon href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></SocialIcon>
          <SocialIcon href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></SocialIcon>
        </SocialIcons>
      </Footer>
    </HomeContainer>
  );
};

export default Institutos;
