import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Cambia 'jwt_decode' por 'jwtDecode'

const PrivateRoute = ({ children, roles }) => {
    const token = localStorage.getItem('token');
    if (!token) {
        console.log('Token no encontrado en localStorage');
        return <Navigate to="/" />;
    }

    let decodedToken;
    try {
        decodedToken = jwtDecode(token);
        console.log('Token decodificado:', decodedToken); // Consola de depuración
    } catch (error) {
        console.log('Error al decodificar el token');
        return <Navigate to="/" />;
    }

    if (roles && roles.indexOf(decodedToken.role) === -1) {
        console.log('Rol no autorizado');
        return <Navigate to="/" />;
    }

    //const decodedToken = jwtDecode(token);
    //if (roles && roles.indexOf(decodedToken.role) === -1) {
       // return <Navigate to="/" />;
    //}

    return children;
};

export default PrivateRoute;

