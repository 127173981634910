import React, { useState } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import Modal from '../Modal';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: rgba(0, 0, 0, 0.9);
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  position: relative;
  text-align: center;
`;

const CloseIcon = styled(FaTimes)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
`;

const ModalButton = styled.button`
  background: #007bff;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  width: 60%;
  margin-top: 10px;

  &:hover {
    background: #0056b3;
  }
`;

const InputField = styled.input`
  background: #444;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    background: #555;
  }
`;


const Form = styled.form`

`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: ${spin} 2s linear infinite;
  margin: 0 auto;
`;

const Register = ({ isOpenn, handleClosee }) => {
  const [formData, setFormData] = useState({ name: '', email: '', password: '' });
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false); //variable que es para hacer que solo cuando sea exitoso el registro se cierre el modal de registro
  const [isLoading, setIsLoading] = useState(false); //variable que es para manejar la animacion de espera al momento de cargar

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await axios.post('/api/auth/register', formData);
      setMessage(res.data.message);
      setShowModal(true);
      setIsSuccess(true); //en caso de ser exitoso el registro al cerrar el el modal de notificacion cierra el de registro tambien
    } catch (err) {
      setMessage(err.response.data.message);
      setIsSuccess(false); //en caso de no ser exitoso el registro mantengo abierto el modal registro
      setShowModal(true);
    } finally {
      setIsLoading(false); //reiniciamos la variale para poder seguir usandola en futuros registros
    }
  };

  const closeModal = () => { //es para el modal de notificaciones 
    setShowModal(false);
    if (isSuccess) { //de esta forma solo lo cerramos si sabemos que fue exitoso el registro
      handleClosee(); // Llama a la función para cerrar el modal de registro solo si es exitoso
    }
  };

  if (!isOpenn) return null; //es para que cuando oprima la cruz en el modal de registro se cierre al devolver null

  return (
    <>
      <ModalBackground>
        <ModalContent>
        <CloseIcon onClick={handleClosee}/>
          <Form onSubmit={handleSubmit}>
            <h2>Registro de Cuenta</h2>
            <InputField type="text" name="name" placeholder="Usuario" onChange={handleChange} required />
            <InputField type="email" name="email" placeholder="Correo Electrónico" onChange={handleChange} required />
            <InputField type="password" name="password" placeholder="Contraseña" onChange={handleChange} required />
            <ModalButton type="submit" disabled={isLoading}>
              {isLoading ? <Loader /> : 'Registrarse'}
            </ModalButton>
          </Form>
        </ModalContent>
      </ModalBackground>
      {showModal && <Modal message={message} onClose={closeModal} />}
    </>
  );
};

export default Register;
