import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { postCoursesWithCoupon, saveEmailList, getProfile, getInstitutosByUser, dtoByCurso, usuariosByInstituto } from '../api';
import styled from 'styled-components';
import { FaTwitter, FaInstagram, FaLinkedin, FaArrowLeft } from 'react-icons/fa';

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1c1c1c;
  color: #ffffff;
  overflow-x: hidden;
`;

const Header = styled.header`
  width: 100%;
  padding: 10px 20px;
  background-color: #1c1c1c;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  font-family: Arial, sans-serif;
  font-size: 24px;
  color: #ffffff;
  margin-left: 10px;
  margin-right: 40px;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
`;

const ContentSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const SearchInput = styled.input`
  background: #444;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    background: #555;
  }
`;

const CourseList = styled.ul`
  list-style: none;
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  border: 1px solid #444;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const CourseItem = styled.li`
  background: #2c2c2c;
  color: #fff;
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  
  &:hover {
    background: #444;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputField = styled.input`
  background: #444;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    background: #555;
  }
`;

const TextArea = styled.textarea`
  background: #444;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    background: #555;
  }
`;

const DiscountSelector = styled.select`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  margin-bottom: 10px;
  width: 100%;
`;

const ModalButton = styled.button`
  background: #007bff;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  width: 60%;
  margin-top: 10px;

  &:hover {
    background: #0056b3;
  }
`;

const Footer = styled.footer`
  background-color: #1c1c1c;
  padding: 20px;
  text-align: center;
  color: #ffffff;
`;

const SocialIcons = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const LinkText = styled(Link)`
  color: #007bff;
  text-decoration: none;

`;

const SocialIcon = styled.a`
  color: #ffffff;
  font-size: 24px;
  text-decoration: none;
  &:hover {
    color: #007bff;
  }
`;

const InstituteSelector = styled.select`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  margin-bottom: 20px;
  width: 30%;
`;



const ToggleButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const ToggleButton = styled.button`
  background: ${({ active }) => (active ? '#0056b3' : '#007bff')};
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  margin: 0 10px;

  &:hover {
    background: #0056b3;
  }
`;

const StudentsList = styled.ul`
  list-style: none;
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  border: 1px solid #444;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const StudentItem = styled.li`
  background: #2c2c2c;
  color: #fff;
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  
  &:hover {
    background: #444;
  }
`;
const Checkbox = styled.input`
  margin-right: 10px;
`;

const MatricularAlumnos = () => {
  const [emails, setEmails] = useState('');
  const [name, setName] = useState('');
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedInstituto, setSelectedInstituto] = useState('');
  const [institutos, setInstitutos] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [dtos, setDtos] = useState([]);
  const [selectedDto, setSelectedDto] = useState('');
  const [customDtoValue, setCustomDtoValue] = useState(''); 
  const [gratisEstate, setGratisEstate] = useState(''); 
  const [showEmailForm, setShowEmailForm] = useState(true);
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [studentSearchTerm, setStudentSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getProfile();
        const userId = response.data._id;
        const institutosResponse = await getInstitutosByUser(userId);
        setInstitutos(institutosResponse.data);

        if (institutosResponse.data.length === 1) {
          const instituto = institutosResponse.data[0];
          setSelectedInstituto(instituto);
        }
      } catch (error) {
        console.error('Error al obtener el perfil:', error);
      }
    };

    fetchProfile();
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      if (selectedInstituto) {
        const institutoId = selectedInstituto._id;
        try {
          const response = await postCoursesWithCoupon(institutoId);
          setCourses(response.data);
        } catch (error) {
          console.error('Error fetching courses:', error);
        }
      }
    };
    fetchCourses();
  }, [selectedInstituto]);

  useEffect(() => {
    const fetchDtos = async () => {
      if (selectedInstituto && selectedCourse) {
        try {
          const response = await dtoByCurso(selectedCourse, selectedInstituto._id);
          setDtos(response.data[0]);
        } catch (error) {
          console.error('Error fetching dtos:', error);
        }
      }
    };
    fetchDtos();
  }, [selectedCourse, selectedInstituto]);

  const fetchStudents = async () => {
    if (selectedInstituto) {
      try {
        const response = await usuariosByInstituto(selectedInstituto._id); // aca se tiene que generar la api para levantar los user 
        setStudents(response.data);
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    }
  };

  const handleInputChange = (e) => {
    setEmails(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleStudentSearchChange = (e) => {
    setStudentSearchTerm(e.target.value);
  };

  const handleCourseClick = (courseId) => {
    setSelectedCourse(courseId);
    setShowEmailForm(true); // Reset to email form when course is selected
  };

  const handleDtoChange = (e) => {
    setSelectedDto(e.target.value);
  };

  const handleCustomDtoChange = (e) => {
    setCustomDtoValue(e.target.value);
  };

  const handleGratisChange = (e) => {
    setGratisEstate(e.target.value);
  };

  const handleStudentClick = (studentId) => {
    setSelectedStudents((prevSelectedStudents) =>
      prevSelectedStudents.includes(studentId)
        ? prevSelectedStudents.filter((id) => id !== studentId)
        : [...prevSelectedStudents, studentId]
    );
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const especifico = selectedDto === 'Específico' ? customDtoValue : -1;
    const descuento = selectedDto === 'Específico' ? -1 : selectedDto;
    

    if (showEmailForm) {
      const emailArray = emails.split(/[\s,;,\n]+/).map((email) => email.trim()).filter((email) => email);
      const validEmailArray = emailArray.filter((email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));

      const data = {
        emails: validEmailArray,
        courseId: [selectedCourse],
        especifico,
        descuento,
        gratis: gratisEstate,
      };

      try {
        const response = await saveEmailList(data); //api que llama para añadir dato o crear usuario
        console.log('Respuesta del servidor:', response);
        alert('Lista de correos guardada exitosamente');
      } catch (error) {
        console.error('Error guardando la lista de correos:', error);
        alert('Error al guardar la lista de correos');
      }
    } else {
      const data = {
        userIds: selectedStudents,
        courseId: [selectedCourse],
        especifico,
        descuento,
        gratis: gratisEstate,
      };

      try {
        const response = await saveEmailList(data);
        console.log('Respuesta del servidor:', response);
        alert('Lista de alumnos guardada exitosamente');
      } catch (error) {
        console.error('Error guardando la lista de alumnos:', error);
        alert('Error al guardar la lista de alumnos');
      }
    }
  };

  const handleSelectChange = (e) => {
    const institutoId = e.target.value;
    const instituto = institutos.find((inst) => inst._id === institutoId);
    setSelectedInstituto(instituto);
  };

  const filteredCourses = courses.filter((course) => course.courseName?.toLowerCase().includes(searchTerm.toLowerCase()));

  const filteredStudents = students.filter(
    (student) =>
      student.name?.toLowerCase().includes(studentSearchTerm.toLowerCase()) ||
      student.cedula?.toLowerCase().includes(studentSearchTerm.toLowerCase())
  );

  return (
    <HomeContainer>
      <Header>
        <BackButton onClick={() => navigate(-1)}>
          <FaArrowLeft />
        </BackButton>
        {institutos.length > 1 && (
          <InstituteSelector onChange={handleSelectChange}>
            <option value="">Seleccione un instituto</option>
            {institutos.map((instituto) => (
              <option key={instituto._id} value={instituto._id}>
                {instituto.nombre}
              </option>
            ))}
          </InstituteSelector>
        )}
      </Header>
      <MainContent>
        <ContentSection>
          <SearchInput
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Buscar cursos por nombre"
          />
          <CourseList>
            {filteredCourses.map((course) => (
              <CourseItem
                key={course._id}
                onClick={() => handleCourseClick(course._id)}
                style={{
                  backgroundColor: selectedCourse === course._id ? '#007bff' : '#2c2c2c',
                }}
              >
                {course.courseName}
              </CourseItem>
            ))}
          </CourseList>
          {selectedCourse && (
            <>
              <ToggleButtons>
                <ToggleButton active={showEmailForm} onClick={() => setShowEmailForm(true)}>
                  Cargar Emails
                </ToggleButton>
                <ToggleButton active={!showEmailForm} onClick={() => { setShowEmailForm(false); fetchStudents(); }}>
                  Seleccionar Alumnos
                </ToggleButton>
              </ToggleButtons>
              {showEmailForm ? (
                <Form onSubmit={handleFormSubmit}>
                  <h2>Formulario de Lista de Correos</h2>
                  <InputField
                    type="text"
                    value={name}
                    onChange={handleNameChange}
                    placeholder="Nombre de la lista"
                    required
                  />
                  <TextArea
                    value={emails}
                    onChange={handleInputChange}
                    placeholder="Pega tus correos aquí, separados por espacios, comas, puntos y comas o nuevas líneas"
                    rows="10"
                  />
                  {dtos.elejible === true && (
                    <div>
                      <DiscountSelector value={selectedDto} onChange={handleDtoChange}>
                        <option value="">Seleccione un descuento</option>
                        {dtos.n1 > 0 && <option value={dtos.n1}>{dtos.n1} %</option>}
                        {dtos.n2 > 0 && <option value={dtos.n2}>{dtos.n2} %</option>}
                        {dtos.n3 > 0 && <option value={dtos.n3}>{dtos.n3} %</option>}
                        {dtos.n4 > 0 && <option value={dtos.n4}>{dtos.n4} %</option>}
                        {dtos.n5 > 0 && <option value={dtos.n5}>{dtos.n5} %</option>}
                        {dtos.especifico === true && <option value="Específico">Específico</option>}
                      </DiscountSelector>
                      {selectedDto === 'Específico' && (
                        <InputField
                          type="text"
                          value={customDtoValue}
                          onChange={handleCustomDtoChange}
                          placeholder="Ingresa el valor específico"
                        />
                      )}
                    </div>
                  )}
                  {dtos.gratis === true && (
                    <div>
                      <Checkbox 
                        type="checkbox" 
                        checked={true} 
                        onChange={handleGratisChange} 
                      />
                      <label>Gratis</label>
                    </div>
                )}
                  <ModalButton type="submit">Enviar</ModalButton>
                </Form>
              ) : (
                <Form onSubmit={handleFormSubmit}>
                  <SearchInput
                    type="text"
                    value={studentSearchTerm}
                    onChange={handleStudentSearchChange}
                    placeholder="Buscar alumnos por nombre o cédula"
                  />
                  <StudentsList>
                    {filteredStudents.map((student) => (
                      <StudentItem
                        key={student._id}
                        onClick={() => handleStudentClick(student._id)}
                        style={{
                          backgroundColor: selectedStudents.includes(student._id) ? '#007bff' : '#2c2c2c',
                        }}
                      >
                        {student.name} - {student.cedula}
                      </StudentItem>
                    ))}
                  </StudentsList>
                  {dtos.elejible === true && (
                    <div>
                      <DiscountSelector value={selectedDto} onChange={handleDtoChange}>
                        <option value="">Seleccione un descuento</option>
                        {dtos.n1 > 0 && <option value={dtos.n1}>{dtos.n1} %</option>}
                        {dtos.n2 > 0 && <option value={dtos.n2}>{dtos.n2} %</option>}
                        {dtos.n3 > 0 && <option value={dtos.n3}>{dtos.n3} %</option>}
                        {dtos.n4 > 0 && <option value={dtos.n4}>{dtos.n4} %</option>}
                        {dtos.n5 > 0 && <option value={dtos.n5}>{dtos.n5} %</option>}
                        {dtos.especifico === true && <option value="Específico">Específico</option>}
                      </DiscountSelector>
                      {selectedDto === 'Específico' && (
                        <InputField
                          type="text"
                          value={customDtoValue}
                          onChange={handleCustomDtoChange}
                          placeholder="Ingresa el valor específico"
                        />
                      )}
                    </div>
                  )}

                  <ModalButton type="submit">Enviar</ModalButton>
                </Form>
              )}
            </>
          )}
        </ContentSection>
      </MainContent>
      <Footer>
        <p>&copy; 2024 Todos los derechos reservados a <LinkText to="https://www.labordt.com">Labor D&T</LinkText></p>
        <SocialIcons>
          <SocialIcon href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </SocialIcon>
          <SocialIcon href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </SocialIcon>
          <SocialIcon href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            <FaLinkedin />
          </SocialIcon>
        </SocialIcons>
      </Footer>
    </HomeContainer>
  );
};

export default MatricularAlumnos;