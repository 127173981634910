import { useNavigate, Link } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { FaBars, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { getProfile, getInstitutosByUser } from '../api';
import CrearCurso from './CrearCurso';
import EmailListForm from './EmailListForm';
import { handleRequestError } from './errorHandler';

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1c1c1c;
  color: #ffffff;
  overflow-x: hidden;
`;

const Header = styled.header`
  width: 100%;
  padding: 10px 20px;
  background-color: #1c1c1c;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  height: 40px;
`;

const Title = styled.h1`
  font-family: Arial, sans-serif;
  font-size: 24px;
  color: #ffffff;
  margin-left: 10px;
`;

const NavMenu = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const MenuButton = styled(FaBars)`
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 20px;
`;

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ffffff;
  background-color: #1c1c1c;
`;

const DropdownMenu = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  top: 0; /* Ajustar para mover el menú hacia arriba */
  right: 0; /* Alinear a la derecha */
  background-color: #1c1c1c;
  border: 1px solid #ffffff;
  border-radius: 5px;
  z-index: 1;
`;

const MenuItem = styled(Link)`
  display: block;
  padding: 10px 20px;
  color: #ffffff;
  text-decoration: none;
  &:hover {
    background-color: #333333;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
`;

const LogoSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const LargeLogo = styled.img`
  max-width: 70%;
  height: auto;
`;

const ContentSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const MainText = styled.h1`
  font-family: Arial, sans-serif;
  font-size: 40px;
  right: 40px;
  margin-bottom: 80px;
  margin-top: -10px;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Button = styled(Link)`
  background: #007bff;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  width: 50%;
  margin-bottom: 10px;
`;

const TermsText = styled.p`
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
  max-width: 50%;
  line-height: 1.2;
`;

const LinkText = styled(Link)`
  color: #007bff;
  text-decoration: none;
`;

const QuestionText = styled.p`
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
`;

const Footer = styled.footer`
  background-color: #1c1c1c;
  padding: 20px;
  text-align: center;
  color: #ffffff;
`;

const SocialIcons = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const SocialIcon = styled.a`
  color: #ffffff;
  font-size: 24px;
  text-decoration: none;
  &:hover {
    color: #007bff;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-left: 5px;
`;

const InstituteSelector = styled.select`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  margin-bottom: 20px;
  width: 30%;
`;

const VideoWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: auto 20px auto; /* Centra el video horizontalmente y agrega un margen inferior */
  padding: 15px; /* Padding para asegurar que el video tenga espacio en los lados */
  margin-right: 10px;
  margin-left: 10px;
`;

const Video = styled.video`
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const AdminHome = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const timeoutRef = useRef(null);
  const [profile, setProfile] = useState({});
  const navigate = useNavigate();
  
  // Inicializar estados de modales como falsos para asegurar que no se abran al cargar la página
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal1Open, setIsModalOpen1] = useState(false);
  const [institutos, setInstitutos] = useState([]);
  const [selectedInstituto, setSelectedInstituto] = useState(null);
  const [nombre, setNombre] = useState('');

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setMenuOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setMenuOpen(false);
    }, 200);
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getProfile();
        setProfile(response.data);
        const userId = response.data._id;
        const institutosResponse = await getInstitutosByUser(userId);
        setInstitutos(institutosResponse.data);

        // Si solo hay un instituto, seleccionarlo automáticamente
        if (institutosResponse.data.length === 1) {
            const instituto = institutosResponse.data[0];
            setSelectedInstituto(instituto);
            setNombre(instituto.nombre);
            }
      } catch (error) {
        console.log('Error al obtener el perfil:', error);
        handleRequestError(error, navigate);
        //navigate('/'); //si el server no devuelve nada vuelve al inicio (seguramente se inicio sesion en otra pc)
      }
    };

    fetchProfile();
  }, []);

  const handleSelectChange = (e) => {
    const institutoId = e.target.value;
    const instituto = institutos.find(inst => inst._id === institutoId);
    setSelectedInstituto(instituto);
    setNombre(instituto.nombre);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setTimeout(() => { // Se agrega un pequeño delay para asegurar que se elimina el token antes de redirigir
      navigate('/'); // Redirigir a la página de inicio
    }, 100); 
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleClose1 = () => {
    setIsModalOpen1(false);
  };

  return (
    <HomeContainer>
      <Header>
        <LogoContainer>
          <Logo src="/images/logo.svg" alt="Logo" />
          <Title>estudiochat</Title>
        </LogoContainer>
        {institutos.length > 1 && (
            <InstituteSelector onChange={handleSelectChange}>
              <option value="">Seleccione un instituto</option>
              {institutos.map((instituto) => (
                <option key={instituto._id} value={instituto._id}>
                  {instituto.nombre}
                </option>
              ))}
            </InstituteSelector>
          )}
        <NavMenu
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <MenuButton />
          <DropdownMenu isOpen={menuOpen}>
          <DropdownHeader>
          <ImageContainer>
            {selectedInstituto && selectedInstituto.imagen && (
                <img src={`/imInst/${selectedInstituto.imagen}`} alt="Instituto" style={{ maxHeight: '70px', maxWidth: '100%' }} />
              )}
            </ImageContainer>
              <Title style={{ fontSize: '16px', margin: '0 0 0 10px' }}>{nombre}</Title>
          </DropdownHeader>
            <MenuItem to="/servicio">Servicio</MenuItem>
            <MenuItem to="/cursos">Cursos</MenuItem>
            <MenuItem to="/precio">Precio</MenuItem>
            <MenuItem to="/quienes-somos">Quienes Somos</MenuItem>
            <MenuItem to="/contacto">Contacto</MenuItem>
            <MenuItem to="/buscar">Buscar</MenuItem>
            <MenuItem to="/open-chat">Moog</MenuItem>
            <MenuItem to="/chat-on">Chat</MenuItem>
            <MenuItem to="/subir-archivo">Archivo</MenuItem>
            <MenuItem to="/user-home">user Home</MenuItem>
            <MenuItem to="/editor-curso">Editor Curso</MenuItem>
            <MenuItem to="/administrar-profesores">Admin Profesor</MenuItem>
            <MenuItem to="/editar-pagos-cursos">Configurar Pagos</MenuItem>
            <MenuItem to="/matricular-alumnos">Matricular</MenuItem>
            <MenuItem to="/calendario">Calendario</MenuItem>
            <MenuItem to="/chatpage">Chat Memory</MenuItem>
            {profile.role === 'supadm' && <MenuItem to="/super-admin">Gestion Usuarios</MenuItem>}
            {profile.role === 'supadm' && <MenuItem to="/new-instituto">Nuevo Instituto</MenuItem>}
            {profile.role === 'supadm' && <MenuItem to="/plan-venta">Plan Venta</MenuItem>}
            {profile.role === 'supadm' && <MenuItem to="/all-planes">Info Plan</MenuItem>}
            {profile.role === 'supadm' && <MenuItem to="/crear-servicio">Crear Servicio</MenuItem>}
            <MenuItem to="/edit-instituto">Editar Instituto</MenuItem>
            <MenuItem to="/crear-chat">Configurar Chat</MenuItem>
            <MenuItem onClick={() => setIsModalOpen(true)}>Nuevo Curso</MenuItem>
            <MenuItem onClick={() => setIsModalOpen1(true)}>Habilitar Cupon</MenuItem>
            <MenuItem onClick={handleLogout}>Salir</MenuItem>
          </DropdownMenu>
        </NavMenu>
      </Header>
      <MainContent>
        <LogoSection>
          <LargeLogo src="/images/logo.svg" alt="Logo" />
        </LogoSection>
        <ContentSection>
          <MainText>La mejor forma de aprender chateando</MainText>
          <VideoWrapper>
            <Video controls autoPlay muted controlsList="nodownload">
              <source src="/videos/123.mp4" type="video/mp4" />
              Tu navegador no soporta la reproducción de videos.
            </Video>
          </VideoWrapper>
          <ButtonContainer>
            {/* Aquí puedes añadir botones si es necesario */}
          </ButtonContainer>
          <CrearCurso isOpen={isModalOpen} handleClose={handleClose} />
          <EmailListForm isOpen={isModal1Open} handleClose={handleClose1} />
        </ContentSection>
      </MainContent>
      <Footer>
        <p>&copy; 2024 Todos los derechos reservados a <LinkText to="https://www.labordt.com">Labor D&T</LinkText></p>
        <SocialIcons>
          <SocialIcon href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></SocialIcon>
          <SocialIcon href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></SocialIcon>
          <SocialIcon href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></SocialIcon>
        </SocialIcons>
      </Footer>
    </HomeContainer>
  );
};

export default AdminHome;
