import React, { useState } from 'react';
import { uploadFile } from '../api'; // Importar la función de carga de archivos desde api.js

// Función para seleccionar archivo utilizando una ventana emergente
const selectFile = async () => {
  return new Promise((resolve) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = () => {
      resolve(input.files[0]);
    };
    input.click();
  });
};

const BuscarArchivo = () => {
  // Estados para gestionar el archivo, su ID y el progreso de la carga
  const [file, setFile] = useState(null);
  const [fileId, setFileId] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);

  // Maneja la selección del archivo
  const handleSelectFile = async () => {
    try {
      const selectedFile = await selectFile();
      setFile(selectedFile);
    } catch (error) {
      console.error('Error al seleccionar el archivo:', error);
    }
  };

  // Maneja la carga del archivo
  const handleUploadFile = async () => {
    try {
      if (!file) {
        alert('Seleccione un archivo primero.');
        return;
      }

      const formData = new FormData();
      formData.append('filePath', file);

      // Configurar la carga con seguimiento de progreso
      const response = await uploadFile(formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });

      setFileId(response.data.fileId);
    } catch (error) {
      console.error('Error al cargar el archivo:', error);
    }
  };

  return (
    <div>
      <h1>Subidor de Archivos</h1>

      {/* Input para ingresar el fileId manualmente */}
      <input
        type="text"
        placeholder="ID del Archivo"
        value={fileId}
        onChange={(e) => setFileId(e.target.value)}
      />
      <br />

      {/* Botón para seleccionar el archivo */}
      <button onClick={handleSelectFile}>Seleccionar Archivo</button>
      <br />

      {/* Botón para cargar el archivo */}
      <button onClick={handleUploadFile}>Cargar Archivo</button>
      <br />

      {/* Mostrar la ruta del archivo seleccionado */}
      {file && <p>Archivo seleccionado: {file.name}</p>}
      <br />

      {/* Barra de progreso de carga */}
      {uploadProgress > 0 && (
        <div>
          <progress value={uploadProgress} max="100">{uploadProgress}%</progress>
          <p>{uploadProgress}%</p>
        </div>
      )}
    </div>
  );
};

export default BuscarArchivo;


