import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { updatePlanVenta, getPlanVentaById, createPlanVenta, getPlanVentas, deletePlanVenta } from '../api'; // actualizamos el método importado
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { FaTrash, FaPlus, FaHome } from 'react-icons/fa';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #1c1c1c;
  color: #ffffff;
  min-height: 100vh;
`;

const Header = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 780px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  margin-bottom: 20px;
  width: 13%;
`;

const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  margin-bottom: 20px;
  width: 100%;
`;

const FileInput = styled.input`
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  width: 120px;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #ffffff;
`;

const CheckBox = styled.input`
  margin-right: 10px;
`;

const Selector = styled.select`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
`;

const InputsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 780px;
  margin-bottom: 30px;
`;

const InputLong = styled.input`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
  margin-bottom: 20px;
  width: 100%;
`;

const ButtonDelete = styled.button`
  background-color: #ff0000;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #cc0000;
  }
`;

const ModalHeader = styled.h2`
  margin-bottom: 20px;
`;

const ModalText = styled.p`
  margin-bottom: 20px;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const ModalButton = styled.button`
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const CancelButton = styled(ModalButton)`
  background-color: #ff0000;

  &:hover {
    background-color: #cc0000;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;  // Ajusta este margen según sea necesario
`;

const CreateButton = styled.button`
  background-color: #28a745;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  width: 150px; // Ajusta el ancho según sea necesario
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #218838;
  }
`;

const HomeButton = styled.button`
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  width: 150px; // Ajusta el ancho según sea necesario
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 0; 
    margin-bottom: 20px;
  }
`;

// styled components...

const PlanVenta = () => {
  const [especifico, setEspecifico] = useState(false);
  const [planVenta, setPlanVenta] = useState(null);
  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [promocion, setPromocion] = useState('');
  const [chats, setChats] = useState([{ cf: 10, pf: 20, cv: 30, pv: 40, cvt: 50, pav: 60 }]);
  const [ENchats, setENchats] = useState([]);
  const [gpt3_5turbo, setGpt3_5turbo] = useState([]);
  const [gpt3_5turbo16k, setGpt3_5turbo16k] = useState([]);
  const [gpt4_0turbo, setGpt4_0turbo] = useState([]);
  const [gpt4_0o, setGpt4_0o] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [video, setVideo] = useState([]);
  const [Mvideo, setMvideo] = useState([]);
  const [RXvideo, setRXvideo] = useState([]);
  const [cuestionarios, setCuestionarios] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [instituto, setInstituto] = useState([]);
  const [archivos, setArchivos] = useState([]);
  const [Marchivos, setMarchivos] = useState([]);
  const [RXarchivos, setRXarchivos] = useState([]);
  const [AdmIA, setAdmIA] = useState([]);
  const [mailCob, setMailCob] = useState([]);
  const [privado, setPrivado] = useState('');
  const [mercadoP, setMercadoP] = useState('');
  const [Cmanual, setCmanual] = useState('');
  const [Gmanual, setGmanual] = useState('');
  const [AutoGest, setAutoGest] = useState('');
  const [evaluacionD, setEvaluacionD] = useState('');
  const [evaluacionA, setEvaluacionA] = useState('');
  const [evaluacionI, setEvaluacionI] = useState('');
  const [plancurso, setPlancurso] = useState('');
  const [bonifcacionAD, setBonifcacionAD] = useState('');
  const [bonificacionAnual, setBonificacionAnual] = useState('');
  const [iva, setIva] = useState('');
  const [imagen, setImagen] = useState(null);
  const [imagenCargada, setImagenCargada] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [videoCargado, setVideoCargado] = useState(false);

  const [planVentas, setPlanVentas] = useState([]);
  const [selectedPlanVenta, setSelectedPlanVenta] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responsePlan = await getPlanVentas();
        //const responsePlan = await getTrolas();
        setPlanVentas(responsePlan.data);
      } catch (error) {
        console.error('Error al recuperar datos:', error);
        navigate('/'); //si el server no devuelve nada vuelve al inicio (seguramente se inicio sesion en otra pc)
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedPlanVenta) {
      const fetchData = async () => {
        try {
          const response = await getPlanVentaById(selectedPlanVenta._id);
          setEspecifico(response.data.especifico);
          setNombre(response.data.nombre);
          setDescripcion(response.data.descripcion);
          setPromocion(response.data.promocion);
          setChats(response.data.chats);
          setENchats(response.data.ENchats);
          setGpt3_5turbo(response.data.gpt3_5turbo);
          setGpt3_5turbo(response.data.gpt3_5turbo);
        setGpt3_5turbo16k(response.data.gpt3_5turbo16k);
        setGpt4_0turbo(response.data.gpt4_0turbo);
        setGpt4_0o(response.data.gpt4_0o);
        setCursos(response.data.cursos);
        setVideo(response.data.video);
        setMvideo(response.data.Mvideo);
        setRXvideo(response.data.RXvideo);
        setCuestionarios(response.data.cuestionarios);
        setUsuarios(response.data.usuarios);
        setInstituto(response.data.instituto);
        setArchivos(response.data.archivos);
        setMarchivos(response.data.Marchivos);
        setRXarchivos(response.data.RXarchivos);
        setAdmIA(response.data.AdmIA);
        setMailCob(response.data.mailCob);
        setPrivado(response.data.privado);
        setMercadoP(response.data.mercadoP);
        setCmanual(response.data.Cmanual);
        setGmanual(response.data.Gmanual);
        setAutoGest(response.data.AutoGest);
        setEvaluacionD(response.data.evaluacionD);
        setEvaluacionA(response.data.evaluacionA);
        setEvaluacionI(response.data.evaluacionI);
        setPlancurso(response.data.plancurso);
          setBonifcacionAD(response.data.bonifcacionAD);
          setBonificacionAnual(response.data.bonificacionAnual);
          setIva(response.data.iva);
        } catch (error) {
          console.error('Error al recuperar datos:', error);
        }
      };
      fetchData();
    }
  }, [selectedPlanVenta]);

  const handleSelectChange = (e) => {
    const planid = e.target.value;
    const plan = planVentas.find((inst) => inst._id === planid);
    setSelectedPlanVenta(plan);
  };

  const handleCreateNew = async () => {
    try {
      const responseNew = await createPlanVenta();
      setPlanVenta(responseNew.data._id);
      alert('Nuevo Plan de Ventas creado exitosamente, con nombre Nuevo Plan');
      window.location.reload(); // Recargar la página
    } catch (error) {
      alert('Error al crear el plan de ventas, intentalo de nuevo');
      console.error('Error al crear nueva base:', error);
    }
  };

  const handleEditSelected = () => {
    if (selectedPlanVenta) {
      setPlanVenta(selectedPlanVenta);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('especifico', especifico);
    formData.append('nombre', nombre);
    formData.append('descripcion', descripcion);
    formData.append('promocion', promocion);
    formData.append('chats', JSON.stringify(chats));
    formData.append('ENchats', JSON.stringify(ENchats));
    formData.append('gpt3_5turbo', JSON.stringify(gpt3_5turbo));
    formData.append('gpt3_5turbo16k', JSON.stringify(gpt3_5turbo16k));
    formData.append('gpt4_0turbo', JSON.stringify(gpt4_0turbo));
    formData.append('gpt4_0o', JSON.stringify(gpt4_0o));
    formData.append('cursos', JSON.stringify(cursos));
    formData.append('video', JSON.stringify(video));
    formData.append('Mvideo', JSON.stringify(Mvideo));
    formData.append('RXvideo', JSON.stringify(RXvideo));
    formData.append('cuestionarios', JSON.stringify(cuestionarios));
    formData.append('usuarios', JSON.stringify(usuarios));
    formData.append('instituto', JSON.stringify(instituto));
    formData.append('archivos', JSON.stringify(archivos));
    formData.append('Marchivos', JSON.stringify(Marchivos));
    formData.append('RXarchivos', JSON.stringify(RXarchivos));
    formData.append('AdmIA', JSON.stringify(AdmIA));
    formData.append('mailCob', JSON.stringify(mailCob));
    formData.append('privado', privado);
    formData.append('mercadoP', mercadoP);
    formData.append('Cmanual', Cmanual);
    formData.append('Gmanual', Gmanual);
    formData.append('AutoGest', AutoGest);
    formData.append('evaluacionD', evaluacionD);
    formData.append('evaluacionA', evaluacionA);
    formData.append('evaluacionI', evaluacionI);
    formData.append('plancurso', plancurso);
    formData.append('bonifcacionAD', bonifcacionAD);
    formData.append('bonificacionAnual', bonificacionAnual);
    formData.append('iva', iva);
    if (imagen) formData.append('imagen', imagen);
    //if (videoFile) formData.append('videoFile', videoFile);

    try {
      await updatePlanVenta(selectedPlanVenta._id, formData);
      alert('Plan de Venta actualizado exitosamente');
      window.location.reload(); // Recargar la página
    } catch (error) {
      alert('Error al actualizar plan de ventas. Inténtalo de nuevo');
      console.error('Error al actualizar plan de venta:', error);
    }
  };

  const handleImagenChange = (e) => {
    setImagen(e.target.files[0]);
    setImagenCargada(true);
  }; 

  const handleVideoChange = (e) => {
    setVideoFile(e.target.files[0]);
    setVideoCargado(true);
  };

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      await deletePlanVenta(selectedPlanVenta._id);
      alert('Plan de Venta eliminado exitosamente');
      setIsDeleteModalOpen(false);
      window.location.reload(); // Recargar la página
    } catch (error) {
      alert('Error al eliminar plan de ventas. Inténtalo de nuevo');
      console.error('Error al eliminar plan de venta:', error);
    }
  };

  return (
    <PageContainer>
      <Header>Seleccionar o Crear Plan de Venta</Header>
      <Selector onChange={handleSelectChange}>
        <option value="">Seleccione un plan de venta</option>
        {planVentas.map((plannVenta) => (
          <option key={plannVenta._id} value={plannVenta._id}>
            {plannVenta.nombre}
          </option>
        ))}
      </Selector>
      <ButtonRow>
      <CreateButton onClick={handleCreateNew}>
      <FaPlus style={{ marginRight: '8px' }} />
          Nuevo Plan
      </CreateButton>
      <ButtonDelete onClick={openDeleteModal}>
        <FaTrash style={{ marginRight: '10px' }} />
          Eliminar
        </ButtonDelete>
        <HomeButton onClick={() => navigate('/admin-home')}>
          <FaHome style={{ marginRight: '8px' }} />
            Inicio
        </HomeButton>
      </ButtonRow>
        <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        contentLabel="Confirmación de Eliminación"
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          },
          content: {
            padding: '20px',
            backgroundColor: '#2c2c2c',
            borderRadius: '10px',
            width: '400px',          // Ajusta esta línea para definir el ancho del modal
            maxWidth: '90%',         // Ajusta esta línea para definir el ancho máximo del modal
            color: '#ffffff',
            textAlign: 'center',
            margin: 'auto',
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto'
          }
        }}
      >
        <ModalHeader>Confirmación de Eliminación</ModalHeader>
        <ModalText>¿Estás seguro de que deseas eliminar el plan de venta: {selectedPlanVenta && selectedPlanVenta.nombre}?</ModalText>
        <ModalButtonContainer>
          <ModalButton onClick={handleDelete}>Sí, eliminar</ModalButton>
          <CancelButton onClick={closeDeleteModal}>Cancelar</CancelButton>
        </ModalButtonContainer>
      </Modal>
      <CheckBoxContainer>
        <CheckBox
          type="checkbox"
          checked={especifico}
          onChange={(e) => setEspecifico(e.target.checked)}
        />{' '}
        Específico para clientes full custom
      </CheckBoxContainer>
      <Header>Editar Plan de Venta</Header>
      <FormContainer onSubmit={handleSubmit}>
        <InputLong
          type="text"
          value={nombre}
          onChange={(e) => setNombre(e.target.value)}
          placeholder="Nombre"
          title="Nombre"
          required
        />
        <TextArea
          value={descripcion}
          onChange={(e) => setDescripcion(e.target.value)}
          placeholder="Descripción"
          title="Descripcion"
          required
        />
        <InputLong
          type="text"
          value={promocion}
          onChange={(e) => setPromocion(e.target.value)}
          placeholder="Promoción"
          title="Promocion"
        />
        {/* Añadir inputs para todos los demás campos de la base de datos aquí */}
        <Header>Chats</Header>
        <InputsRow>
        {chats.map((chat, index) => (
          <div key={index}>
            <Input
              type="number"
              value={chat.cf}
              onChange={(e) => {
                const newChats = [...chats];
                newChats[index].cf = e.target.value;
                setChats(newChats);
              }}
              placeholder="CF"
              title="Cantidad Fija"
            />
            <Input
              type="number"
              value={chat.pf}
              onChange={(e) => {
                const newChats = [...chats];
                newChats[index].pf = e.target.value;
                setChats(newChats);
              }}
              placeholder="PF"
              title="Precio Fijo (De la cantidad fija)"
            />
            <Input
              type="number"
              value={chat.cv}
              onChange={(e) => {
                const newChats = [...chats];
                newChats[index].cv = e.target.value;
                setChats(newChats);
              }}
              placeholder="CV"
              title="Cantidad Variable" 
            />
            <Input
              type="number"
              value={chat.pv}
              onChange={(e) => {
                const newChats = [...chats];
                newChats[index].pv = e.target.value;
                setChats(newChats);
              }}
              placeholder="PV"
              title="Precio Variable (se aplica sobre la cantidad definida)"
            />
            <Input
              type="number"
              value={chat.cvt}
              onChange={(e) => {
                const newChats = [...chats];
                newChats[index].cvt = e.target.value;
                setChats(newChats);
              }}
              placeholder="CVT"
              title="Cantidad Variable Total (cota maxima antes de cobrar tarifa especial)"
            />
            <Input
              type="number"
              value={chat.pav}
              onChange={(e) => {
                const newChats = [...chats];
                newChats[index].pav = e.target.value;
                setChats(newChats);
              }}
              placeholder="PAV"
              title="Precio Adicional Variable (se cobra sobre la cantidad consumida que exede la maxima)"
            />
          </div>
        ))}
        </InputsRow>
        <Header>ENchats</Header>
        <InputsRow>
        {ENchats.map((chat, index) => (
          <div key={index}>
            <Input
              type="number"
              value={chat.cf}
              onChange={(e) => {
                const newChats = [...ENchats];
                newChats[index].cf = e.target.value;
                setENchats(newChats);
              }}
              placeholder="CF"
              title="CF"
            />
            <Input
              type="number"
              value={chat.pf}
              onChange={(e) => {
                const newChats = [...ENchats];
                newChats[index].pf = e.target.value;
                setENchats(newChats);
              }}
              placeholder="PF"
              title="PF"
            />
            <Input
              type="number"
              value={chat.cv}
              onChange={(e) => {
                const newChats = [...ENchats];
                newChats[index].cv = e.target.value;
                setENchats(newChats);
              }}
              placeholder="CV"
              title="CV" 
            />
            <Input
              type="number"
              value={chat.pv}
              onChange={(e) => {
                const newChats = [...ENchats];
                newChats[index].pv = e.target.value;
                setENchats(newChats);
              }}
              placeholder="PV"
              title="PV"
            />
            <Input
              type="number"
              value={chat.cvt}
              onChange={(e) => {
                const newChats = [...ENchats];
                newChats[index].cvt = e.target.value;
                setENchats(newChats);
              }}
              placeholder="CVT"
              title="CVT"
            />
            <Input
              type="number"
              value={chat.pav}
              onChange={(e) => {
                const newChats = [...ENchats];
                newChats[index].pav = e.target.value;
                setENchats(newChats);
              }}
              placeholder="PAV"
              title="PAV"
            />
          </div>
        ))}
        </InputsRow>
        {/* Repetir para gpt3_5turbo, gpt3_5turbo16k, gpt4_0turbo, gpt4_0o, video, Mvideo, RXvideo, cuestionarios, usuarios, instituto, archivos, Marchivos, RXarchivos, AdmIA, mailCob */}
        <Header>GPT-3.5 Turbo</Header>
        <InputsRow>
        {gpt3_5turbo.map((item, index) => (
        <div key={index}>
            <Input
            type="number"
            value={item.cf}
            onChange={(e) => {
                const newItems = [...gpt3_5turbo];
                newItems[index].cf = e.target.value;
                setGpt3_5turbo(newItems);
            }}
            placeholder="CF"
            title="CF"
            />
            <Input
            type="number"
            value={item.pf}
            onChange={(e) => {
                const newItems = [...gpt3_5turbo];
                newItems[index].pf = e.target.value;
                setGpt3_5turbo(newItems);
            }}
            placeholder="PF"
            title="PF"
            />
            <Input
            type="number"
            value={item.cv}
            onChange={(e) => {
                const newItems = [...gpt3_5turbo];
                newItems[index].cv = e.target.value;
                setGpt3_5turbo(newItems);
            }}
            placeholder="CV"
            title="CV" 
            />
            <Input
            type="number"
            value={item.pv}
            onChange={(e) => {
                const newItems = [...gpt3_5turbo];
                newItems[index].pv = e.target.value;
                setGpt3_5turbo(newItems);
            }}
            placeholder="PV"
            title="PV"
            />
            <Input
            type="number"
            value={item.cvt}
            onChange={(e) => {
                const newItems = [...gpt3_5turbo];
                newItems[index].cvt = e.target.value;
                setGpt3_5turbo(newItems);
            }}
            placeholder="CVT"
            title="CVT"
            />
            <Input
            type="number"
            value={item.pav}
            onChange={(e) => {
                const newItems = [...gpt3_5turbo];
                newItems[index].pav = e.target.value;
                setGpt3_5turbo(newItems);
            }}
            placeholder="PAV"
            title="PAV"
            />
        </div>
        ))}
        </InputsRow>
        <Header>GPT-3.5 Turbo 16K</Header>
        <InputsRow>
        {gpt3_5turbo16k.map((item, index) => (
        <div key={index}>
            <Input
            type="number"
            value={item.cf}
            onChange={(e) => {
                const newItems = [...gpt3_5turbo16k];
                newItems[index].cf = e.target.value;
                setGpt3_5turbo16k(newItems);
            }}
            placeholder="CF"
            title="CF"
            />
            <Input
            type="number"
            value={item.pf}
            onChange={(e) => {
                const newItems = [...gpt3_5turbo16k];
                newItems[index].pf = e.target.value;
                setGpt3_5turbo16k(newItems);
            }}
            placeholder="PF"
            title="PF"
            />
            <Input
            type="number"
            value={item.cv}
            onChange={(e) => {
                const newItems = [...gpt3_5turbo16k];
                newItems[index].cv = e.target.value;
                setGpt3_5turbo16k(newItems);
            }}
            placeholder="CV"
            title="CV" 
            />
            <Input
            type="number"
            value={item.pv}
            onChange={(e) => {
                const newItems = [...gpt3_5turbo16k];
                newItems[index].pv = e.target.value;
                setGpt3_5turbo16k(newItems);
            }}
            placeholder="PV"
            title="PV"
            />
            <Input
            type="number"
            value={item.cvt}
            onChange={(e) => {
                const newItems = [...gpt3_5turbo16k];
                newItems[index].cvt = e.target.value;
                setGpt3_5turbo16k(newItems);
            }}
            placeholder="CVT"
            title="CVT"
            />
            <Input
            type="number"
            value={item.pav}
            onChange={(e) => {
                const newItems = [...gpt3_5turbo16k];
                newItems[index].pav = e.target.value;
                setGpt3_5turbo16k(newItems);
            }}
            placeholder="PAV"
            title="PAV"
            />
        </div>
        ))}
        </InputsRow>
        <Header>GPT-4.0 Turbo</Header>
        <InputsRow>
        {gpt4_0turbo.map((item, index) => (
        <div key={index}>
            <Input
            type="number"
            value={item.cf}
            onChange={(e) => {
                const newItems = [...gpt4_0turbo];
                newItems[index].cf = e.target.value;
                setGpt4_0turbo(newItems);
            }}
            placeholder="CF"
            title="CF"
            />
            <Input
            type="number"
            value={item.pf}
            onChange={(e) => {
                const newItems = [...gpt4_0turbo];
                newItems[index].pf = e.target.value;
                setGpt4_0turbo(newItems);
            }}
            placeholder="PF"
            title="PF"
            />
            <Input
            type="number"
            value={item.cv}
            onChange={(e) => {
                const newItems = [...gpt4_0turbo];
                newItems[index].cv = e.target.value;
                setGpt4_0turbo(newItems);
            }}
            placeholder="CV"
            title="CV" 
            />
            <Input
            type="number"
            value={item.pv}
            onChange={(e) => {
                const newItems = [...gpt4_0turbo];
                newItems[index].pv = e.target.value;
                setGpt4_0turbo(newItems);
            }}
            placeholder="PV"
            title="PV"
            />
            <Input
            type="number"
            value={item.cvt}
            onChange={(e) => {
                const newItems = [...gpt4_0turbo];
                newItems[index].cvt = e.target.value;
                setGpt4_0turbo(newItems);
            }}
            placeholder="CVT"
            title="CVT"
            />
            <Input
            type="number"
            value={item.pav}
            onChange={(e) => {
                const newItems = [...gpt4_0turbo];
                newItems[index].pav = e.target.value;
                setGpt4_0turbo(newItems);
            }}
            placeholder="PAV"
            title="PAV"
            />
        </div>
        ))}
        </InputsRow>
        <Header>GPT-4.0 Original</Header>
        <InputsRow>
        {gpt4_0o.map((item, index) => (
        <div key={index}>
            <Input
            type="number"
            value={item.cf}
            onChange={(e) => {
                const newItems = [...gpt4_0o];
                newItems[index].cf = e.target.value;
                setGpt4_0o(newItems);
            }}
            placeholder="CF"
            title="CF"
            />
            <Input
            type="number"
            value={item.pf}
            onChange={(e) => {
                const newItems = [...gpt4_0o];
                newItems[index].pf = e.target.value;
                setGpt4_0o(newItems);
            }}
            placeholder="PF"
            title="PF"
            />
            <Input
            type="number"
            value={item.cv}
            onChange={(e) => {
                const newItems = [...gpt4_0o];
                newItems[index].cv = e.target.value;
                setGpt4_0o(newItems);
            }}
            placeholder="CV"
            title="CV" 
            />
            <Input
            type="number"
            value={item.pv}
            onChange={(e) => {
                const newItems = [...gpt4_0o];
                newItems[index].pv = e.target.value;
                setGpt4_0o(newItems);
            }}
            placeholder="PV"
            title="PV"
            />
            <Input
            type="number"
            value={item.cvt}
            onChange={(e) => {
                const newItems = [...gpt4_0o];
                newItems[index].cvt = e.target.value;
                setGpt4_0o(newItems);
            }}
            placeholder="CVT"
            title="CVT"
            />
            <Input
            type="number"
            value={item.pav}
            onChange={(e) => {
                const newItems = [...gpt4_0o];
                newItems[index].pav = e.target.value;
                setGpt4_0o(newItems);
            }}
            placeholder="PAV"
            title="PAV"
            />
        </div>
        ))}
        </InputsRow>
        <Header>Cursos</Header>
        <InputsRow>
        {cursos.map((item, index) => (
        <div key={index}>
            <Input
            type="number"
            value={item.cf}
            onChange={(e) => {
                const newItems = [...cursos];
                newItems[index].cf = e.target.value;
                setCursos(newItems);
            }}
            placeholder="CF"
            title="CF"
            />
            <Input
            type="number"
            value={item.pf}
            onChange={(e) => {
                const newItems = [...cursos];
                newItems[index].pf = e.target.value;
                setCursos(newItems);
            }}
            placeholder="PF"
            title="PF"
            />
            <Input
            type="number"
            value={item.cv}
            onChange={(e) => {
                const newItems = [...cursos];
                newItems[index].cv = e.target.value;
                setCursos(newItems);
            }}
            placeholder="CV"
            title="CV" 
            />
            <Input
            type="number"
            value={item.pv}
            onChange={(e) => {
                const newItems = [...cursos];
                newItems[index].pv = e.target.value;
                setCursos(newItems);
            }}
            placeholder="PV"
            title="PV"
            />
            <Input
            type="number"
            value={item.cvt}
            onChange={(e) => {
                const newItems = [...cursos];
                newItems[index].cvt = e.target.value;
                setCursos(newItems);
            }}
            placeholder="CVT"
            title="CVT"
            />
            <Input
            type="number"
            value={item.pav}
            onChange={(e) => {
                const newItems = [...cursos];
                newItems[index].pav = e.target.value;
                setCursos(newItems);
            }}
            placeholder="PAV"
            title="PAV"
            />
        </div>
        ))}
        </InputsRow>
        <Header>Videos</Header>
        <InputsRow>
        {video.map((item, index) => (
        <div key={index}>
            <Input
            type="number"
            value={item.cf}
            onChange={(e) => {
                const newItems = [...video];
                newItems[index].cf = e.target.value;
                setVideo(newItems);
            }}
            placeholder="CF"
            title="CF"
            />
            <Input
            type="number"
            value={item.pf}
            onChange={(e) => {
                const newItems = [...video];
                newItems[index].pf = e.target.value;
                setVideo(newItems);
            }}
            placeholder="PF"
            title="PF"
            />
            <Input
            type="number"
            value={item.cv}
            onChange={(e) => {
                const newItems = [...video];
                newItems[index].cv = e.target.value;
                setVideo(newItems);
            }}
            placeholder="CV"
            title="CV" 
            />
            <Input
            type="number"
            value={item.pv}
            onChange={(e) => {
                const newItems = [...video];
                newItems[index].pv = e.target.value;
                setVideo(newItems);
            }}
            placeholder="PV"
            title="PV"
            />
            <Input
            type="number"
            value={item.cvt}
            onChange={(e) => {
                const newItems = [...video];
                newItems[index].cvt = e.target.value;
                setVideo(newItems);
            }}
            placeholder="CVT"
            title="CVT"
            />
            <Input
            type="number"
            value={item.pav}
            onChange={(e) => {
                const newItems = [...video];
                newItems[index].pav = e.target.value;
                setVideo(newItems);
            }}
            placeholder="PAV"
            title="PAV"
            />
        </div>
        ))}
        </InputsRow>
        <Header>Mvideos</Header>
        <InputsRow>
        {Mvideo.map((item, index) => (
        <div key={index}>
        <Input
      type="number"
      value={item.cf}
      onChange={(e) => {
        const newItems = [...Mvideo];
        newItems[index].cf = e.target.value;
        setMvideo(newItems);
      }}
      placeholder="CF"
      title="CF"
    />
    <Input
      type="number"
      value={item.pf}
      onChange={(e) => {
        const newItems = [...Mvideo];
        newItems[index].pf = e.target.value;
        setMvideo(newItems);
      }}
      placeholder="PF"
      title="PF"
    />
    <Input
      type="number"
      value={item.cv}
      onChange={(e) => {
        const newItems = [...Mvideo];
        newItems[index].cv = e.target.value;
        setMvideo(newItems);
      }}
      placeholder="CV"
      title="CV" 
    />
    <Input
      type="number"
      value={item.pv}
      onChange={(e) => {
        const newItems = [...Mvideo];
        newItems[index].pv = e.target.value;
        setMvideo(newItems);
      }}
      placeholder="PV"
      title="PV"
    />
    <Input
      type="number"
      value={item.cvt}
      onChange={(e) => {
        const newItems = [...Mvideo];
        newItems[index].cvt = e.target.value;
        setMvideo(newItems);
      }}
      placeholder="CVT"
      title="CVT"
    />
    <Input
      type="number"
      value={item.pav}
      onChange={(e) => {
        const newItems = [...Mvideo];
        newItems[index].pav = e.target.value;
        setMvideo(newItems);
      }}
      placeholder="PAV"
      title="PAV"
    />
  </div>
))}
</InputsRow>
<Header>RXvideos</Header>
<InputsRow>
{RXvideo.map((item, index) => (
  <div key={index}>
    <Input
      type="number"
      value={item.cf}
      onChange={(e) => {
        const newItems = [...RXvideo];
        newItems[index].cf = e.target.value;
        setRXvideo(newItems);
      }}
      placeholder="CF"
      title="CF"
    />
    <Input
      type="number"
      value={item.pf}
      onChange={(e) => {
        const newItems = [...RXvideo];
        newItems[index].pf = e.target.value;
        setRXvideo(newItems);
      }}
      placeholder="PF"
      title="PF"
    />
    <Input
      type="number"
      value={item.cv}
      onChange={(e) => {
        const newItems = [...RXvideo];
        newItems[index].cv = e.target.value;
        setRXvideo(newItems);
      }}
      placeholder="CV"
      title="CV" 
    />
    <Input
      type="number"
      value={item.pv}
      onChange={(e) => {
        const newItems = [...RXvideo];
        newItems[index].pv = e.target.value;
        setRXvideo(newItems);
      }}
      placeholder="PV"
      title="PV"
    />
    <Input
      type="number"
      value={item.cvt}
      onChange={(e) => {
        const newItems = [...RXvideo];
        newItems[index].cvt = e.target.value;
        setRXvideo(newItems);
      }}
      placeholder="CVT"
      title="CVT"
    />
    <Input
      type="number"
      value={item.pav}
      onChange={(e) => {
        const newItems = [...RXvideo];
        newItems[index].pav = e.target.value;
        setRXvideo(newItems);
      }}
      placeholder="PAV"
      title="PAV"
    />
  </div>
))}
</InputsRow>
<Header>Cuestionarios</Header>
<InputsRow>
{cuestionarios.map((item, index) => (
  <div key={index}>
    <Input
      type="number"
      value={item.cf}
      onChange={(e) => {
        const newItems = [...cuestionarios];
        newItems[index].cf = e.target.value;
        setCuestionarios(newItems);
      }}
      placeholder="CF"
      title="CF"
    />
    <Input
      type="number"
      value={item.pf}
      onChange={(e) => {
        const newItems = [...cuestionarios];
        newItems[index].pf = e.target.value;
        setCuestionarios(newItems);
      }}
      placeholder="PF"
      title="PF"
    />
    <Input
      type="number"
      value={item.cv}
      onChange={(e) => {
        const newItems = [...cuestionarios];
        newItems[index].cv = e.target.value;
        setCuestionarios(newItems);
      }}
      placeholder="CV"
      title="CV" 
    />
    <Input
      type="number"
      value={item.pv}
      onChange={(e) => {
        const newItems = [...cuestionarios];
        newItems[index].pv = e.target.value;
        setCuestionarios(newItems);
      }}
      placeholder="PV"
      title="PV"
    />
    <Input
      type="number"
      value={item.cvt}
      onChange={(e) => {
        const newItems = [...cuestionarios];
        newItems[index].cvt = e.target.value;
        setCuestionarios(newItems);
      }}
      placeholder="CVT"
      title="CVT"
    />
    <Input
      type="number"
      value={item.pav}
      onChange={(e) => {
        const newItems = [...cuestionarios];
        newItems[index].pav = e.target.value;
        setCuestionarios(newItems);
      }}
      placeholder="PAV"
      title="PAV"
    />
  </div>
))}
</InputsRow>
<Header>Usuarios</Header>
<InputsRow>
    {usuarios.map((item, index) => (
    <div key={index}>
        <Input
        type="number"
        value={item.cf}
        onChange={(e) => {
            const newItems = [...usuarios];
            newItems[index].cf = e.target.value;
            setUsuarios(newItems);
        }}
        placeholder="CF"
        title="CF"
        />
        <Input
        type="number"
        value={item.pf}
        onChange={(e) => {
            const newItems = [...usuarios];
            newItems[index].pf = e.target.value;
            setUsuarios(newItems);
        }}
        placeholder="PF"
        title="PF"
        />
        <Input
        type="number"
        value={item.cv}
        onChange={(e) => {
            const newItems = [...usuarios];
            newItems[index].cv = e.target.value;
            setUsuarios(newItems);
        }}
        placeholder="CV"
        title="CV" 
        />
        <Input
        type="number"
        value={item.pv}
        onChange={(e) => {
            const newItems = [...usuarios];
            newItems[index].pv = e.target.value;
            setUsuarios(newItems);
        }}
        placeholder="PV"
        title="PV"
        />
        <Input
        type="number"
        value={item.cvt}
        onChange={(e) => {
            const newItems = [...usuarios];
            newItems[index].cvt = e.target.value;
            setUsuarios(newItems);
        }}
        placeholder="CVT"
        title="CVT"
        />
        <Input
        type="number"
        value={item.pav}
        onChange={(e) => {
            const newItems = [...usuarios];
            newItems[index].pav = e.target.value;
            setUsuarios(newItems);
        }}
        placeholder="PAV"
        title="PAV"
        />
    </div>
    ))}
    </InputsRow>
    <Header>Instituto</Header>
    <InputsRow>
    {instituto.map((item, index) => (
    <div key={index}>
        <Input
        type="number"
        value={item.cf}
        onChange={(e) => {
            const newItems = [...instituto];
            newItems[index].cf = e.target.value;
            setInstituto(newItems);
        }}
        placeholder="CF"
        title="CF"
        />
        <Input
        type="number"
        value={item.pf}
        onChange={(e) => {
            const newItems = [...instituto];
            newItems[index].pf = e.target.value;
            setInstituto(newItems);
        }}
        placeholder="PF"
        title="PF"
        />
        <Input
        type="number"
        value={item.cv}
        onChange={(e) => {
            const newItems = [...instituto];
            newItems[index].cv = e.target.value;
            setInstituto(newItems);
        }}
        placeholder="CV"
        title="CV" 
        />
        <Input
        type="number"
        value={item.pv}
        onChange={(e) => {
            const newItems = [...instituto];
            newItems[index].pv = e.target.value;
            setInstituto(newItems);
        }}
        placeholder="PV"
        title="PV"
        />
        <Input
        type="number"
        value={item.cvt}
        onChange={(e) => {
            const newItems = [...instituto];
            newItems[index].cvt = e.target.value;
            setInstituto(newItems);
        }}
        placeholder="CVT"
        title="CVT"
        />
        <Input
        type="number"
        value={item.pav}
        onChange={(e) => {
            const newItems = [...instituto];
            newItems[index].pav = e.target.value;
            setInstituto(newItems);
        }}
        placeholder="PAV"
        title="PAV"
        />
    </div>
    ))}
</InputsRow>
<Header>Archivos</Header>
<InputsRow>
    {archivos.map((item, index) => (
    <div key={index}>
        <Input
        type="number"
        value={item.cf}
        onChange={(e) => {
            const newItems = [...archivos];
            newItems[index].cf = e.target.value;
            setArchivos(newItems);
        }}
        placeholder="CF"
        title="CF"
        />
        <Input
        type="number"
        value={item.pf}
        onChange={(e) => {
            const newItems = [...archivos];
            newItems[index].pf = e.target.value;
            setArchivos(newItems);
        }}
        placeholder="PF"
        title="PF"
        />
        <Input
        type="number"
        value={item.cv}
        onChange={(e) => {
            const newItems = [...archivos];
            newItems[index].cv = e.target.value;
            setArchivos(newItems);
        }}
        placeholder="CV"
        title="CV" 
        />
        <Input
        type="number"
        value={item.pv}
        onChange={(e) => {
            const newItems = [...archivos];
            newItems[index].pv = e.target.value;
            setArchivos(newItems);
        }}
        placeholder="PV"
        title="PV"
        />
        <Input
        type="number"
        value={item.cvt}
        onChange={(e) => {
            const newItems = [...archivos];
            newItems[index].cvt = e.target.value;
            setArchivos(newItems);
        }}
        placeholder="CVT"
        title="CVT"
        />
        <Input
        type="number"
        value={item.pav}
        onChange={(e) => {
            const newItems = [...archivos];
            newItems[index].pav = e.target.value;
            setArchivos(newItems);
        }}
        placeholder="PAV"
        title="PAV"
        />
    </div>
    ))}
</InputsRow>
<Header>Marchivos</Header>
<InputsRow>
    {Marchivos.map((item, index) => (
    <div key={index}>
        <Input
        type="number"
        value={item.cf}
        onChange={(e) => {
            const newItems = [...Marchivos];
            newItems[index].cf = e.target.value;
            setMarchivos(newItems);
        }}
        placeholder="CF"
        title="CF"
        />
        <Input
        type="number"
        value={item.pf}
        onChange={(e) => {
            const newItems = [...Marchivos];
            newItems[index].pf = e.target.value;
            setMarchivos(newItems);
        }}
        placeholder="PF"
        title="PF"
        />
        <Input
        type="number"
        value={item.cv}
        onChange={(e) => {
            const newItems = [...Marchivos];
            newItems[index].cv = e.target.value;
            setMarchivos(newItems);
        }}
        placeholder="CV"
        title="CV" 
        />
        <Input
        type="number"
        value={item.pv}
        onChange={(e) => {
            const newItems = [...Marchivos];
            newItems[index].pv = e.target.value;
            setMarchivos(newItems);
        }}
        placeholder="PV"
        title="PV"
        />
        <Input
        type="number"
        value={item.cvt}
        onChange={(e) => {
            const newItems = [...Marchivos];
            newItems[index].cvt = e.target.value;
            setMarchivos(newItems);
        }}
        placeholder="CVT"
        title="CVT"
        />
        <Input
        type="number"
        value={item.pav}
        onChange={(e) => {
            const newItems = [...Marchivos];
            newItems[index].pav = e.target.value;
            setMarchivos(newItems);
        }}
        placeholder="PAV"
        title="PAV"
        />
    </div>
    ))}
</InputsRow>
<Header>RXarchivos</Header>
<InputsRow>
    {RXarchivos.map((item, index) => (
    <div key={index}>
        <Input
        type="number"
        value={item.cf}
        onChange={(e) => {
            const newItems = [...RXarchivos];
            newItems[index].cf = e.target.value;
            setRXarchivos(newItems);
        }}
        placeholder="CF"
        title="CF"
        />
        <Input
        type="number"
        value={item.pf}
        onChange={(e) => {
            const newItems = [...RXarchivos];
            newItems[index].pf = e.target.value;
            setRXarchivos(newItems);
        }}
        placeholder="PF"
        title="PF"
        />
        <Input
        type="number"
        value={item.cv}
        onChange={(e) => {
            const newItems = [...RXarchivos];
            newItems[index].cv = e.target.value;
            setRXarchivos(newItems);
        }}
        placeholder="CV"
        title="CV" 
        />
        <Input
        type="number"
        value={item.pv}
        onChange={(e) => {
            const newItems = [...RXarchivos];
            newItems[index].pv = e.target.value;
            setRXarchivos(newItems);
        }}
        placeholder="PV"
        title="PV"
        />
        <Input
        type="number"
        value={item.cvt}
        onChange={(e) => {
            const newItems = [...RXarchivos];
            newItems[index].cvt = e.target.value;
            setRXarchivos(newItems);
        }}
        placeholder="CVT"
        title="CVT"
        />
        <Input
        type="number"
        value={item.pav}
        onChange={(e) => {
            const newItems = [...RXarchivos];
            newItems[index].pav = e.target.value;
            setRXarchivos(newItems);
        }}
        placeholder="PAV"
        title="PAV"
        />
    </div>
    ))}
</InputsRow>
<Header>AdmIA</Header>
<InputsRow>
    {AdmIA.map((item, index) => (
    <div key={index}>
        <Input
        type="number"
        value={item.cf}
        onChange={(e) => {
            const newItems = [...AdmIA];
            newItems[index].cf = e.target.value;
            setAdmIA(newItems);
        }}
        placeholder="CF"
        title="CF"
        />
        <Input
        type="number"
        value={item.pf}
        onChange={(e) => {
            const newItems = [...AdmIA];
            newItems[index].pf = e.target.value;
            setAdmIA(newItems);
        }}
        placeholder="PF"
        title="PF"
        />
        <Input
        type="number"
        value={item.cv}
        onChange={(e) => {
            const newItems = [...AdmIA];
            newItems[index].cv = e.target.value;
            setAdmIA(newItems);
        }}
        placeholder="CV"
        title="CV" 
        />
        <Input
        type="number"
        value={item.pv}
        onChange={(e) => {
            const newItems = [...AdmIA];
            newItems[index].pv = e.target.value;
            setAdmIA(newItems);
        }}
        placeholder="PV"
        title="PV"
        />
        <Input
        type="number"
        value={item.cvt}
        onChange={(e) => {
            const newItems = [...AdmIA];
            newItems[index].cvt = e.target.value;
            setAdmIA(newItems);
        }}
        placeholder="CVT"
        title="CVT"
        />
        <Input
        type="number"
        value={item.pav}
        onChange={(e) => {
            const newItems = [...AdmIA];
            newItems[index].pav = e.target.value;
            setAdmIA(newItems);
        }}
        placeholder="PAV"
        title="PAV"
        />
    </div>
    ))}
</InputsRow>
<Header>Mail Cob</Header>
<InputsRow>
    {mailCob.map((item, index) => (
    <div key={index}>
        <Input
        type="number"
        value={item.cf}
        onChange={(e) => {
            const newItems = [...mailCob];
            newItems[index].cf = e.target.value;
            setMailCob(newItems);
        }}
        placeholder="CF"
        title="CF"
        />
        <Input
        type="number"
        value={item.pf}
        onChange={(e) => {
            const newItems = [...mailCob];
            newItems[index].pf = e.target.value;
            setMailCob(newItems);
        }}
        placeholder="PF"
        title="PF"
        />
        <Input
        type="number"
        value={item.cv}
        onChange={(e) => {
            const newItems = [...mailCob];
            newItems[index].cv = e.target.value;
            setMailCob(newItems);
        }}
        placeholder="CV"
        title="CV" 
        />
        <Input
        type="number"
        value={item.pv}
        onChange={(e) => {
            const newItems = [...mailCob];
            newItems[index].pv = e.target.value;
            setMailCob(newItems);
        }}
        placeholder="PV"
        title="PV"
        />
        <Input
        type="number"
        value={item.cvt}
        onChange={(e) => {
            const newItems = [...mailCob];
            newItems[index].cvt = e.target.value;
            setMailCob(newItems);
        }}
        placeholder="CVT"
        title="CVT"
        />
        <Input
        type="number"
        value={item.pav}
        onChange={(e) => {
            const newItems = [...mailCob];
            newItems[index].pav = e.target.value;
            setMailCob(newItems);
        }}
        placeholder="PAV"
        title="PAV"
        />
    </div>
    ))}
</InputsRow>
<InputsRow>
        {/* Repetir para los demás subesquemas de números */}
        <Input
          type="number"
          value={privado}
          onChange={(e) => setPrivado(e.target.value)}
          placeholder="Privado"
          title="Instituto Privado"
          required
        />
        <Input
          type="number"
          value={mercadoP}
          onChange={(e) => setMercadoP(e.target.value)}
          placeholder="MercadoP"
          title="Mercado Pago"
          required
        />
        <Input
          type="number"
          value={Cmanual}
          onChange={(e) => setCmanual(e.target.value)}
          placeholder="Cmanual"
          title="Cobro de gestion Manual"
          required
        />
        <Input
          type="number"
          value={Gmanual}
          onChange={(e) => setGmanual(e.target.value)}
          placeholder="Gmanual"
          title="Gestion manual administrativa"
          required
        />
        <Input
          type="number"
          value={AutoGest}
          onChange={(e) => setAutoGest(e.target.value)}
          placeholder="AutoGest"
          title="auto Gestion del usuario"
          required
        />
        <Input
          type="number"
          value={evaluacionD}
          onChange={(e) => setEvaluacionD(e.target.value)}
          placeholder="EvaluacionD"
          title="evaluacion Docente"
          required
        />
        <Input
          type="number"
          value={evaluacionA}
          onChange={(e) => setEvaluacionA(e.target.value)}
          placeholder="EvaluacionA"
          title="Evalucaion administrativa"
          required
        />
        <Input
          type="number"
          value={evaluacionI}
          onChange={(e) => setEvaluacionI(e.target.value)}
          placeholder="EvaluacionI"
          title="Evaluacion Instituto"
          required
        />
        </InputsRow>
        <InputsRow>
        <Input
          type="number"
          value={plancurso}
          onChange={(e) => setPlancurso(e.target.value)}
          placeholder="Plancurso"
          title="Planificacion del curso"
          required
        />
        <Input
          type="number"
          value={bonifcacionAD}
          onChange={(e) => setBonifcacionAD(e.target.value)}
          placeholder="BonificacionAD"
          title="Bonificacion Adicional (por promociones)"
          required
        />
        <Input
          type="number"
          value={bonificacionAnual}
          onChange={(e) => setBonificacionAnual(e.target.value)}
          placeholder="BonificacionAnual"
          title="Bonificacion por pago Anual"
          required
        />
        <Input
          type="number"
          value={iva}
          onChange={(e) => setIva(e.target.value)}
          placeholder="IVA"
          title="Tasa del iva"
          required
        />
        </InputsRow>
        <FileInput
              type="file"
              onChange={handleImagenChange}
            />
            {imagenCargada && <p>Imagen cargada: {imagen.name}</p>}
        <ButtonContainer>
          <Button type="submit">Guardar</Button>
        </ButtonContainer>
      </FormContainer>
      <ImageContainer>
        {selectedPlanVenta && selectedPlanVenta.imagen && (
            <img src={`/arPV/${selectedPlanVenta.imagen}`} alt="Plan" style={{ maxHeight: '800px', maxWidth: '100%' }} />
          )}
        </ImageContainer>
    </PageContainer>
  );
};

export default PlanVenta;
