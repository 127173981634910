export const handleRequestError = (error, navigate) => {
    console.error('Error al recuperar datos:', error);
   /* if (error.response && (error.response.status === 401 || error.response.data.error === 'Acceso simultáneo detectado.')) {
      navigate('/');
    } else {
      // Manejo de otros tipos de errores si es necesario
    }*/
     // Extraer el mensaje de error del servidor si está disponible
  const errorMessage = error.response?.data?.message || 'Error desconocido';

  if (error.response) {
    const status = error.response.status;
    if (status === 400 || status === 401 || status === 403) {
      // Mostrar mensaje de alerta
      alert(`Error ${status}: ${errorMessage}`);

      // Redirigir al inicio para ciertos códigos de error
      if (status === 401 || status === 403) {
        navigate('/');
      }
    } else {
      // Manejo de otros tipos de errores si es necesario
      alert(`Error: ${errorMessage}`);
    }
  } else {
    alert('Error de red: No se pudo conectar con el servidor.');
  }
};